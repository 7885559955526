import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { getJSONFromUrlParams } from 'react-components-linaia';

import {
  login,
  recoverPassword,
  RECOVER_PASSWORD_SUCCESS,
  LOGOUT_SUCCESS
} from '../../store/modules/auth';
import { setMessage } from '../../store/modules/globals';
import Header from '../../components/shared/Header';

import LoginModalForm from '../../components/shared/LoginModalForm';
import LoginForm from '../../components/login/LoginForm';
import PasswordRecoverForm from '../../components/login/PasswordRecoverForm';

const Login = ({ history, doLogin, doRecoverPassword, setMsg, modal }) => {
  const [forgottenPassword, setForgottenPassword] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitLogin = values => {
    if (
      values.extranet_code &&
      values.extranet_code &&
      values.extranet_code.value &&
      !isEmpty(values.extranet_code.value)
    ) {
      // on se connect sur dendreo
      return doLogin(null, null, values.extranet_code).then(res => {
        const params = getJSONFromUrlParams();
        let routeNext = '/';
        return history.push(
          res.type === LOGOUT_SUCCESS
            ? '/login'
            : params.next
            ? routeNext
            : modal
            ? window.location.pathname
            : routeNext
        );
      });
    } else {
      setError({ extranet_code: 'Champ obligatoire' });
    }
  };

  const submitRecoverPassword = values =>
    doRecoverPassword(values.email).then(action => {
      if (action.type === RECOVER_PASSWORD_SUCCESS) {
        setForgottenPassword(false);
        setMsg(
          'Un lien de réinitialisation du mot de passe vous a été envoyé par email.',
          'info',
          5000
        );
      }
    });

  return modal ? (
    <LoginModalForm onSubmit={v => submitLogin(v)} />
  ) : (
    <main className="login">
      <Header />
      <div className="login-overflow">
        {!forgottenPassword ? (
          <LoginForm
            onSubmit={v => {
              setLoading(true);
              return Promise.resolve()
                .then(() => submitLogin(v))
                .then(() => setLoading(false))
                .catch(() => {
                  setError({ extranet_code: 'Code incorrect' });
                  setLoading(false);
                });
            }}
            errorProps={error}
            onClickRecoverPassword={() => setForgottenPassword(true)}
            loading={loading}
          />
        ) : (
          <PasswordRecoverForm
            onSubmit={v => submitRecoverPassword(v)}
            onClickCancel={() => setForgottenPassword(false)}
          />
        )}
      </div>
    </main>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,

  doLogin: PropTypes.func.isRequired,
  doRecoverPassword: PropTypes.func.isRequired,
  setMsg: PropTypes.func.isRequired
};

export default connect(null, {
  doLogin: login,
  doRecoverPassword: recoverPassword,
  setMsg: setMessage
})(withRouter(Login));
