import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BaseLayout from '../shared/BaseLayout';
import NotificationEditForm from '../../components/notifications/NotificationEditForm';

const NotificationsAdminContainer = ({
  match: { url },
  history: { push },
  uploadContents,
  ...props
}) => {
  return (
    <BaseLayout title="NotificationsAdminContainer">
      <div className="admin-header">
        <div className="admin-header-title">
          <h1>Notifications</h1>
        </div>
      </div>
      <div className="box">
        <NotificationEditForm />
      </div>
    </BaseLayout>
  );
};
NotificationsAdminContainer.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(state => ({}), {})(withRouter(NotificationsAdminContainer));
