import React, { useState } from 'react';

import { connect } from 'react-redux';
import { createOrUpdateFormation } from '../../store/modules/formations';

import { withRouter, useHistory, NavLink, useLocation } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import ClipLoader from 'react-spinners/ClipLoader';

import Select from 'react-select';

import { map, omit, isNull, filter } from 'lodash';
import { TagInput } from 'reactjs-tag-input';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

import BaseLayout from '../shared/BaseLayout';

import defaultFormulaire from '../../constants/formulaireEvaluation.json';

const CreateOrEditFormulaireEvalContainer = ({ formations, createOrUpdateFormation }) => {
  const [loading, setLoading] = useState(false);

  let location = useLocation();
  const { mode, formation } = location.state;

  const navigation = useHistory();
  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = data => {
    const selectedFormation =
      mode && mode === 'creation' ? formations[data.idFormationWebservice] : formation;

    selectedFormation.formulaire_evaluation = omit(data, ['idFormationWebservice']);

    setLoading(true);

    createOrUpdateFormation(selectedFormation)
      .then(() => {
        navigation.push('/admin/formulaires-evaluations');
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };

  const formationsOptions = map(
    filter(formations, f => isNull(f.formulaire_evaluation)),
    (item, key) => {
      return {
        value: item.idFormationWebservice,
        label: item.titre
      };
    }
  );

  return (
    <BaseLayout>
      <div className="admin-header">
        <div className="admin-header-title">
          <NavLink
            className="btn"
            to={{
              pathname: `/admin/formulaires-evaluations`,
              foldersProps: {
                name: 'Back'
              }
            }}
          >
            <i className="far fa-chevron-left"></i>
          </NavLink>
          <h1>
            {(mode && mode === 'edition' && 'Modification de formulaire') ||
              "Ajout d'un formulaire d'évaluation"}
          </h1>
        </div>
      </div>

      <div className="modal-body">
        <Form
          mutators={{ ...arrayMutators }}
          initialValues={
            (mode && mode === 'creation' && defaultFormulaire) ||
            (formation && formation.formulaire_evaluation)
          }
          onSubmit={handleSubmit}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop }
            },
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              onKeyPress={e => {
                e.key === 'Enter' && e.preventDefault();
              }}
            >
              <div className="grid">
                <div className="col-12">
                  <div className="grid">
                    <div className="col-12">
                      {mode && mode === 'creation' && (
                        <Field name="idFormationWebservice" validate={required} component="select">
                          {({ input, meta }) => {
                            return (
                              <div className="field">
                                {meta.error && meta.touched && (
                                  <div className="field-error">{meta.error}</div>
                                )}
                                <label>Formation *</label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="select"
                                  options={formationsOptions}
                                  placeholder="Sélectionner une formation"
                                  onChange={option => {
                                    input.onChange(option.value);
                                  }}
                                />
                              </div>
                            );
                          }}
                        </Field>
                      )}
                    </div>
                    <div className="col-12">
                      <div className="bloc-question">
                        <h3>1: Cette formation a-t-elle répondu à vos attentes ?</h3>
                        <FieldArray name="question_1">
                          {({ fields }) =>
                            fields.map((name, row) => {
                              return (
                                <div key={row} className="bloc-question-form">
                                  <Field
                                    validate={required}
                                    name={`${name}.titre`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <label>Question *</label>

                                        <input {...input} type="text" value={input.value} />
                                      </div>
                                    )}
                                  </Field>

                                  <Field
                                    validate={required}
                                    validate={required}
                                    name={`${name}.choix`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        <label>Réponses *</label>
                                        <ReactTags
                                          tags={map(input.value, (t, i) => {
                                            return {
                                              id: String(i),
                                              text: t
                                            };
                                          })}
                                          autofocus={false}
                                          placeholder=""
                                          delimiters={delimiters}
                                          handleDelete={indexTag =>
                                            input.onChange(
                                              filter(input.value, (t, index) => index !== indexTag)
                                            )
                                          }
                                          handleAddition={tag => {
                                            input.onChange([...input.value, tag.text]);
                                          }}
                                          handleDrag={(tag, currPos, newPos) => {
                                            const newTags = input.value.slice();

                                            newTags.splice(currPos, 1);
                                            newTags.splice(newPos, 0, tag.text);

                                            input.onChange(newTags);
                                          }}
                                          inputFieldPosition="inline"
                                          editable
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                      </div>

                      <div className="bloc-question">
                        <h3>
                          2: Pour Chacun de ces thèmes, la formation a-t-elle été satisfaisante ?
                        </h3>
                        <FieldArray name="question_2">
                          {({ fields }) =>
                            fields.map((name, row) => {
                              return (
                                <div key={row} className="bloc-question-form">
                                  <Field
                                    validate={required}
                                    name={`${name}.titre`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <label>Question *</label>

                                        <input {...input} type="text" value={input.value} />
                                      </div>
                                    )}
                                  </Field>

                                  <Field
                                    validate={required}
                                    name={`${name}.choix`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        <label>Réponses *</label>
                                        <ReactTags
                                          tags={map(input.value, (t, i) => {
                                            return {
                                              id: String(i),
                                              text: t
                                            };
                                          })}
                                          autofocus={false}
                                          placeholder=""
                                          delimiters={delimiters}
                                          handleDelete={indexTag =>
                                            input.onChange(
                                              filter(input.value, (t, index) => index !== indexTag)
                                            )
                                          }
                                          handleAddition={tag => {
                                            input.onChange([...input.value, tag.text]);
                                          }}
                                          handleDrag={(tag, currPos, newPos) => {
                                            const newTags = input.value.slice();

                                            newTags.splice(currPos, 1);
                                            newTags.splice(newPos, 0, tag.text);

                                            input.onChange(newTags);
                                          }}
                                          inputFieldPosition="inline"
                                          editable
                                        />
                                      </div>
                                    )}
                                  </Field>
                                  <div className="flexbox justifyEnd">
                                    {row !== 0 && (
                                      <div
                                        onClick={() => fields.remove(row)}
                                        className="btn btn-close"
                                      >
                                        <i className="fas fa-trash-alt" />
                                        <span>Supprimer</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                        <div className="flexbox justifyCenter">
                          <button
                            className="btn"
                            onClick={e => {
                              e.preventDefault();

                              const numberTheme = values.question_2.length + 1;
                              push('question_2', {
                                question_type: 'checkbox',
                                titre: `Sur le thème « nom thème ${numberTheme} » la formation a-t-elle été satisfaisante`,
                                choix: ['Pas du tout', 'Partiellement', 'Plutôt bien', 'Totalement']
                              });
                            }}
                          >
                            <i className="far fa-plus-circle" />
                            <span>Ajouter une question</span>
                          </button>
                        </div>
                      </div>

                      <div className="bloc-question">
                        <h3>3: Quels sont les développements qui vous ont le plus intéressé ?</h3>
                        <FieldArray name="question_3">
                          {({ fields }) =>
                            fields.map((name, row) => {
                              return (
                                <div key={row} className="bloc-question-form">
                                  <Field
                                    validate={required}
                                    name={`${name}.titre`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <label>Question *</label>

                                        <input {...input} type="text" value={input.value} />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                      </div>

                      <div className="bloc-question">
                        <h3>4: Pouvez-vous formuler un avis sur :</h3>
                        <FieldArray name="question_4">
                          {({ fields }) =>
                            fields.map((name, row) => {
                              return (
                                <div key={row} className="bloc-question-form">
                                  <Field
                                    validate={required}
                                    name={`${name}.titre`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <label>Question *</label>

                                        <input {...input} type="text" value={input.value} />
                                      </div>
                                    )}
                                  </Field>

                                  <Field
                                    validate={required}
                                    name={`${name}.choix`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        <label>Réponses *</label>

                                        <ReactTags
                                          tags={map(input.value, (t, i) => {
                                            return {
                                              id: String(i),
                                              text: t
                                            };
                                          })}
                                          autofocus={false}
                                          placeholder=""
                                          delimiters={delimiters}
                                          handleDelete={indexTag =>
                                            input.onChange(
                                              filter(input.value, (t, index) => index !== indexTag)
                                            )
                                          }
                                          handleAddition={tag => {
                                            input.onChange([...input.value, tag.text]);
                                          }}
                                          handleDrag={(tag, currPos, newPos) => {
                                            const newTags = input.value.slice();

                                            newTags.splice(currPos, 1);
                                            newTags.splice(newPos, 0, tag.text);

                                            input.onChange(newTags);
                                          }}
                                          inputFieldPosition="inline"
                                          editable
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                      </div>
                      <div className="bloc-question">
                        <h3>5: Faites-nous part de vos autres remarques sur cette formation</h3>
                        <FieldArray name="question_5">
                          {({ fields }) =>
                            fields.map((name, row) => {
                              return (
                                <div key={row} className="bloc-question-form">
                                  <Field
                                    validate={required}
                                    name={`${name}.titre`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <label>Question *</label>

                                        <input {...input} type="text" value={input.value} />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                      </div>
                      <div className="bloc-question">
                        <h3>
                          6: Quels seraient les thèmes susceptibles de vous intéresser dans le cadre
                          d'autres formations ?
                        </h3>
                        <FieldArray name="question_6">
                          {({ fields }) =>
                            fields.map((name, row) => {
                              return (
                                <div key={row} className="bloc-question-form">
                                  <Field
                                    validate={required}
                                    validate={required}
                                    name={`${name}.choix`}
                                    component="input"
                                    style={{ display: 'none', visibility: 'hidden' }}
                                    className="field"
                                  ></Field>
                                  <Field
                                    validate={required}
                                    name={`${name}.titre`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <label>Question *</label>

                                        <input {...input} type="text" value={input.value} />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                      </div>
                      <div className="bloc-question">
                        <h3>7: Avez-vous d’autres idées de formation ?</h3>
                        <FieldArray name="question_7">
                          {({ fields }) =>
                            fields.map((name, row) => {
                              return (
                                <div key={row} className="bloc-question-form">
                                  <Field
                                    validate={required}
                                    name={`${name}.titre`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <label>Question *</label>

                                        <input {...input} type="text" value={input.value} />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                      </div>
                      <div className="bloc-question">
                        <h3>
                          8: Comment évaluez-vous votre acquisition de nouvelles compétences ?
                        </h3>
                        <FieldArray name="question_8">
                          {({ fields }) =>
                            fields.map((name, row) => {
                              return (
                                <div key={row} className="bloc-question-form">
                                  <Field
                                    validate={required}
                                    name={`${name}.titre`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <label>Question *</label>

                                        <input {...input} type="text" value={input.value} />
                                      </div>
                                    )}
                                  </Field>

                                  <Field
                                    validate={required}
                                    name={`${name}.choix`}
                                    component="input"
                                    className="field"
                                  >
                                    {({ input, meta }) => (
                                      <div className="field">
                                        <label>Réponses *</label>
                                        <ReactTags
                                          tags={map(input.value, (t, i) => {
                                            return {
                                              id: String(i),
                                              text: t
                                            };
                                          })}
                                          autofocus={false}
                                          placeholder=""
                                          delimiters={delimiters}
                                          handleDelete={indexTag =>
                                            input.onChange(
                                              filter(input.value, (t, index) => index !== indexTag)
                                            )
                                          }
                                          handleAddition={tag => {
                                            input.onChange([...input.value, tag.text]);
                                          }}
                                          handleDrag={(tag, currPos, newPos) => {
                                            const newTags = input.value.slice();

                                            newTags.splice(currPos, 1);
                                            newTags.splice(newPos, 0, tag.text);

                                            input.onChange(newTags);
                                          }}
                                          inputFieldPosition="inline"
                                          editable
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {error && <div className="error-message">{error}</div>}
              <div className="modal-footer">
                <div className="btn-group">
                  <NavLink className="btn btn-secondary" to="/admin/formulaires-evaluations">
                    <span>Annuler</span>
                  </NavLink>

                  <button className="btn btn-primary" type="submit" disabled={loading}>
                    {loading ? (
                      <ClipLoader size={15} color={'white'} loading={loading} />
                    ) : (
                      <span>{(mode && mode === 'creation' && 'Enregistrer') || 'Modifier'}</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </BaseLayout>
  );
};

export default connect(
  (state, props) => ({
    formations: state.data.entities.formations
  }),
  { createOrUpdateFormation }
)(withRouter(CreateOrEditFormulaireEvalContainer));
