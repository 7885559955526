import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getJSONFromUrlParams } from 'react-components-linaia';

import {
  login,
  recoverPassword,
  RECOVER_PASSWORD_SUCCESS,
  LOGOUT_SUCCESS
} from '../../store/modules/auth';
import { setMessage } from '../../store/modules/globals';
import Header from '../../components/shared/Header';

import LoginModalForm from '../../components/shared/LoginModalForm';
import LoginForm from '../../components/login/LoginForm';
import PasswordRecoverForm from '../../components/login/PasswordRecoverForm';

const Login = ({ history, doLogin, doRecoverPassword, setMsg, modal }) => {
  const [forgottenPassword, setForgottenPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitLogin = values => {
    // on fait comme avant
    setIsLoading(true);
    return doLogin(values.email, values.password)
      .then(res => {
        const params = getJSONFromUrlParams();

        let routeNext = '/';
        if (res.response.roles.includes('ADMIN')) {
          routeNext = '/admin';
        }
        setIsLoading(false);
        return history.push(
          res.type === LOGOUT_SUCCESS
            ? '/login'
            : params.next
            ? routeNext
            : modal
            ? window.location.pathname
            : routeNext
        );
      })
      .catch(() => {
        setError('Erreur lors de la connexion');
        setIsLoading(false);
      });
  };

  const submitRecoverPassword = values =>
    doRecoverPassword(values.email).then(action => {
      if (action.type === RECOVER_PASSWORD_SUCCESS) {
        setForgottenPassword(false);
        setMsg(
          'Un lien de réinitialisation du mot de passe vous a été envoyé par email.',
          'info',
          5000
        );
      }
    });

  return modal ? (
    <LoginModalForm onSubmit={v => submitLogin(v)} />
  ) : (
    <main className="login">
      <Header />
      <div className="login-overflow">
        {!forgottenPassword ? (
          <LoginForm
            isAdmin
            loading={isLoading}
            errorProps={error}
            onSubmit={v => submitLogin(v)}
            onClickRecoverPassword={() => setForgottenPassword(true)}
          />
        ) : (
          <PasswordRecoverForm
            onSubmit={v => submitRecoverPassword(v)}
            onClickCancel={() => setForgottenPassword(false)}
          />
        )}
      </div>
    </main>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,

  doLogin: PropTypes.func.isRequired,
  doRecoverPassword: PropTypes.func.isRequired,
  setMsg: PropTypes.func.isRequired
};

export default connect(null, {
  doLogin: login,
  doRecoverPassword: recoverPassword,
  setMsg: setMessage
})(withRouter(Login));
