import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Field, InputField } from 'react-components-linaia';

const LoginModalForm = ({ handleSubmit, isOpen, closeModal }) => {
  return (
    <div className={`${(!isOpen && 'hidden') || 'modal-overlay modal-restricted-content'} `}>
      <div className="box">
        <a className="btn-close" onClick={() => closeModal()}>
          <i className="far fa-times" />
        </a>
        <i className="fal fa-lock-alt lock" />
        <p>
          Contenu réservé aux adhérents.
          <br />
          Merci de vous identifier
        </p>

        <form onSubmit={handleSubmit} className="login-form">
          <Field
            name="email"
            component={InputField}
            type="text"
            placeholder="Identifiant"
            fontIcon="far fa-user"
          />
          <Field
            name="password"
            component={InputField}
            type="password"
            placeholder="Mot de passe"
            fontIcon="far fa-lock"
          />
          <button className="btn btn-full" type="submit">
            Connexion
          </button>
        </form>
      </div>
      <div className="modal-background" onClick={() => closeModal()} />
    </div>
  );
};

LoginModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'LoginModalForm',
  validate: (values = {}) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Champ obligatoire';
    }
    return errors;
  }
})(LoginModalForm);
