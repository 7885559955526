import { accountsActionsHandlers } from './accounts';
import { optionsActionsHandlers } from './options';
import { uploadContentsActionsHandlers } from './uploadContents';
import { postsActionsHandlers } from './posts';
import { foldersActionsHandlers } from './folders';
import { formationsActionsHandlers } from './formations';
import { actualitiesActionsHandlers } from './actualities';
import { articlesActionsHandlers } from './articles';
import { favoritesActionsHandlers } from './favorites';
import { questionsActionsHandlers } from './questions';
import { editosActionsHandlers } from './editos';
import { uploadsActionsHandlers } from './uploads';
import { socketActionsHandlers } from './socket';
import { globalsActionsHandlers } from './globals';
import { sessionsActionsHandlers } from './sessions';
import { formateursActionsHandlers } from './formateurs';
import { coordonneesActionsHandlers } from './coordonnees';
import { evaluationsActionsHandlers } from './evaluations';
import { inscriptionsActionsHandlers } from './inscriptions';
import { documentsActionsHandlers } from './documents';
import { notificationsActionsHandlers } from './notifications';

import initialState from '../initialState';
import '../../index';

const actionsHandlers = {
  ...optionsActionsHandlers,
  ...accountsActionsHandlers,
  ...uploadsActionsHandlers,
  ...documentsActionsHandlers,
  ...uploadContentsActionsHandlers,
  ...postsActionsHandlers,
  ...socketActionsHandlers,
  ...globalsActionsHandlers,
  ...foldersActionsHandlers,
  ...formationsActionsHandlers,
  ...sessionsActionsHandlers,
  ...evaluationsActionsHandlers,
  ...formateursActionsHandlers,
  ...coordonneesActionsHandlers,
  ...actualitiesActionsHandlers,
  ...articlesActionsHandlers,
  ...favoritesActionsHandlers,
  ...editosActionsHandlers,
  ...questionsActionsHandlers,
  ...inscriptionsActionsHandlers,
  ...notificationsActionsHandlers
};

export default function reducer(state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
