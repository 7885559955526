import React, { Fragment } from 'react';
import { isEmpty, map } from 'lodash';

const DetailsTags = ({ onClickTag, tagSelected, data, filterValue }) => {
  return !isEmpty(tagSelected) ? (
    <div className="search-tag-wrapper">
      <div className="search-tag-title">Résultat du tag: </div>
      <a className="tag" onClick={() => onClickTag({})}>
        {tagSelected} <i className="fas fa-times-circle"></i>
      </a>
    </div>
  ) : (
    <Fragment>
      {!isEmpty(data) && filterValue && filterValue.length >= 3 && (
        <div className="search-tag-wrapper">
          <div className="search-tag-title">Tags :</div>
          {map(data, (tag, index) => {
            return (
              <button className="btn" key={index} onClick={() => onClickTag(tag)}>
                {tag}
              </button>
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

export default DetailsTags;
