import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import { createOrUpdateFavorite } from '../../store/modules/favorites';

import { map, includes, isEmpty } from 'lodash';

import { isUser } from '../../utils/RolesUtils';
import moment from 'moment';
import { API_URL } from '../../constants/Config';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

const FoldersListView = ({ folders, account, createOrUpdateFavorite, favorites, isHome }) => {
  const [sortFolders, setSortFolders] = useState(folders);
  useEffect(() => {
    const sortedArray = folders.sort((f1, f2) => moment(f1.date_debut).diff(moment(f2.date_debut)));
    return setSortFolders(sortedArray.reverse());
  });
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      freeMode={true}
      navigation
      modules={[FreeMode, Navigation]}
      className="mySwiper"
      breakpoints={{
        576: {
          width: 576,
          slidesPerView: 1
        },
        1024: {
          width: 1024,
          slidesPerView: 3
        }
      }}
    >
      {map(
        sortFolders,
        (folder, folderId) =>
          (!isHome || folder.visibility) && (
            <SwiperSlide key={folderId}>
              <NavLink
                to={`/folder/${folder.folder_id}/details`}
                className="box box-news box-news-big box-folder"
              >
                {isHome && (
                  <span className="box-news-type">Dossier{folderId === 0 && ' du mois'}</span>
                )}
                <div className="box-news-picture">
                  <img src={API_URL + '/uploads/' + folder.photoUrl} />
                </div>
                <div className="box-content">
                  {isUser(account) && (
                    <div className="box-news-actions">
                      <div
                        className="btn btn-secondary rounded"
                        onClick={() => {
                          !favorites.folders_id
                            ? (favorites.folders_id = [folder.folder_id])
                            : favorites.folders_id.includes(folder.folder_id)
                            ? favorites.folders_id.pop(folder.folder_id)
                            : favorites.folders_id.push(folder.folder_id);
                          return createOrUpdateFavorite(favorites, account.account_id);
                        }}
                      >
                        {!favorites ||
                          (!includes(favorites.folders_id, folder.folder_id) && (
                            <i className="far fa-heart"></i>
                          ))}
                        {favorites && includes(favorites.folders_id, folder.folder_id) && (
                          <i className="fas fa-heart"></i>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="box-news-date">
                    {moment(folder.dateDebut).format('MMM')}/
                    {moment(folder.dateFin).format('MMM YYYY')}
                  </div>
                  <div className="flexColumn alignStart" to={`/folder/${folder.folder_id}/details`}>
                    <div className="box-news-title">{folder.title}</div>
                    <div className="box-news-resume">{folder.summary}</div>
                  </div>
                  {!isEmpty(folder.tags) ? (
                    <div className="news-tags-list">
                      {map(folder.tags, (tag, key) => (
                        <span key={key} className="news-tags" to={`/search/${tag}`}>
                          {tag}
                        </span>
                      ))}
                    </div>
                  ) : null}
                </div>
              </NavLink>
            </SwiperSlide>
          )
      )}
    </Swiper>
  );
};

// FoldersListView.propTypes = {
//   folders: PropTypes.arrayOf(
//     PropTypes.shape({
//       folder_id: PropTypes.number.isRequired,
//       date: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       summary: PropTypes.string.isRequired,
//       type: PropTypes.string,
//       photoUrl: PropTypes.string,
//       tags: PropTypes.arrayOf(PropTypes.string)
//     })
//   )
// };

// export default FoldersListView;
export default connect(
  state => ({
    account: state.auth.user,
    favorites: state.auth.user && state.data.entities.favorites[state.auth.user.account_id]
  }),
  { createOrUpdateFavorite }
)(FoldersListView);
