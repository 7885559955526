import React, { useState } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { API_URL } from '../../constants/Config';

import {
  filter,
  find,
  map,
  upperFirst,
  includes,
  findIndex,
  values,
  isEmpty,
  get,
  last,
  split
} from 'lodash';
import bbCodeParser from 'js-bbcode-parser';

import moment from 'moment';
moment.locale('fr');

import TimeRangeCalculator from '../../utils/TimeRangeCalculator';
import ModalPreInscription from '../../components/modals/ModalPreInscription';
import ModalSuccessPreInscription from '../../components/modals/ModalSuccessPreInscription';
import Accordion from '../../components/shared/Accordion';

import LoginModalForm from '../../components/shared/LoginModalForm';
import { login } from '../../store/modules/auth';
import { transformStringToHtml } from '../../utils/Methods';
import { BBCodeHTML } from '../../utils/BBCodeToHtml';
const SessionDetails = ({
  login,
  session,
  formateurs,
  documents,
  account,
  // inscriptions, plus utilisé dans la version dendreo
  formationsComplementaires
}) => {
  const [openModalPreinscription, setOpenModalPreinscription] = useState(false);
  const [openModalSuccessPreInscription, setOpenModalSuccessPreInscription] = useState(false);
  const [itemAccordionActive, setItemAccordionActive] = useState('objectifs');
  const [isLoginRequired, setIsLoginRequired] = useState(false);
  const formateursSession = filter(formateurs, f => includes(session.formateurs, f.idTiers));
  const [modalLogin, setModalLogin] = useState(false);
  const navigation = useHistory();
  const confirmPreInscription = () => {
    setOpenModalPreinscription(false);
    setOpenModalSuccessPreInscription(true);
  };
  let cordonnees = null;
  if (session) {
    cordonnees = session.salles[0];
  }
  const openInscription = () => {
    if (account) {
      setOpenModalPreinscription(true);
    } else {
      setModalLogin(true);
    }
  };

  const submitLogin = values => {
    login(values.email, values.password).then(() => {
      setModalLogin(false);
      setOpenModalPreinscription(true);
    });
  };
  const isFinishSession =
    (session && session.date_fin && moment().isAfter(session.date_fin)) || false;
  var html = new BBCodeHTML();
  const isRegistered =
    account &&
    !isEmpty(find(session.participants, i => i.id_participant == account.id_participant));

  const isDiplayPreInscription = account
    ? moment(session && session.date_debut)
        .startOf('day')
        .isAfter(moment(), 'minutes')
    : moment(session && session.date_debut)
        .startOf('day')
        .isAfter(moment(), 'minutes');
  return (
    <div className="news news-article post">
      <LoginModalForm
        onSubmit={v => submitLogin(v)}
        isOpen={modalLogin}
        closeModal={() => setModalLogin(false)}
      />

      <div className="news-header">
        <div className="news-header-wrapper">
          <div className="news-nav">
            <div
              className="btn btn-secondary rounded btn-back"
              style={{ cursor: 'pointer' }}
              onClick={() => navigation.goBack()}
            >
              <i className="far fa-long-arrow-left" />
            </div>
          </div>
        </div>
        <div className="news-picture-wrapper">
          <div className="news-picture-mask" />
          {session && session.modules && session.modules[0] && session.modules[0].visuel_url && (
            <img src={session.modules[0].visuel_url} />
          )}
        </div>
      </div>
      <div className="news-body">
        <div className="news-wrapper">
          <div className="post-infos">
            {session && session.date_debut && (
              <div className="post-date">
                {session && session.duree === 1
                  ? upperFirst(moment(session.date_debut).format('ddd DD MMMM'))
                  : `du ${upperFirst(moment(session.date_debut).format('ddd DD MMMM'))} 
                au ${upperFirst(moment(session.date_fin).format('ddd DD MMMM'))}`}
              </div>
            )}
            <h1 className="news-title">{session && session.intitule}</h1>
          </div>

          {session && session.duree && (
            <div className="post-data">
              <i className="fas fa-hourglass-half" />
              <span>
                {session && session.duree}
                {` ${(session && session.duree > 1 && 'jours') || 'jour'}`}
              </span>
            </div>
          )}
          {cordonnees && (
            <div className="post-data post-data-adress">
              <i className="fas fa-map-marker-alt" />
              <div>
                <span>{cordonnees.adresse || ''}</span>
                <span>
                  {cordonnees.code_postal || ''} {cordonnees.ville || ''}
                </span>
              </div>
            </div>
          )}
          <div
            className="news-abstract"
            dangerouslySetInnerHTML={{
              __html:
                (session &&
                  session.modules &&
                  session.modules[0] &&
                  session.modules[0].points_forts) ||
                ''
            }}
          />

          {/*  {isFinishSession && formationsComplementaires && formationsComplementaires.length > 0 && (
            <div className="accordion" id="hash-link-to-formationscomplementaires">
              <div className="accordion-header">
                <div className="accordion-title">
                  <i className="fas fa-file-certificate" /> Formations complémentaires
                </div>
              </div>
              <div className="accordion-content">
                {map(formationsComplementaires, (f, i) => (
                  <a href={f.url} target="_blank" className="post-attachment" key={i}>
                    {f.titre}
                  </a>
                ))}
              </div>
            </div>
          )} */}
          {!isEmpty(session && session.documents) &&
          TimeRangeCalculator(session.date_debut, session.date_fin, 5, 7) &&
          isRegistered ? (
            <div className="accordion" id="hash-link-to-documents">
              <div className="accordion-header">
                <div className="accordion-title">
                  <i className="fas fa-folder" /> Documents
                </div>
              </div>
              <div className="accordion-content">
                {map(session.documents, (d, i) => {
                  return (
                    <a
                      href={d.public_url}
                      // download={`${d.titre}.${fileType}`}
                      target="_blank"
                      className={`post-attachment post-attachment-${last(split(d.mime_type, '/'))}`}
                      key={i}
                    >
                      <i className="fas" />
                      {d.name}
                    </a>
                  );
                })}
              </div>
            </div>
          ) : null}

          <Accordion
            itemName="objectifs"
            itemAccordionActive={itemAccordionActive}
            title="Objectifs"
            icon="fas fa-bullseye-arrow"
            content={
              (session &&
                session.modules[0] &&
                bbCodeParser.parse(html.bbcodeToHtml(session.modules[0].objectif_pedagogique))) ||
              ''
            }
            onChangeItemActive={itemName => setItemAccordionActive(itemName)}
          />
          <Accordion
            itemName="programme"
            itemAccordionActive={itemAccordionActive}
            title="Programme"
            icon="fas fa-graduation-cap"
            content={
              (session &&
                session.modules[0] &&
                session.modules[0].description &&
                bbCodeParser.parse(html.bbcodeToHtml(session.modules[0].description))) ||
              ''
            }
            onChangeItemActive={itemName => setItemAccordionActive(itemName)}
          />
          <Accordion
            itemName="methode"
            itemAccordionActive={itemAccordionActive}
            title="Méthode"
            icon="fas fa-clipboard-list-check"
            content={
              (session &&
                session.modules[0] &&
                session.modules[0].modalites_pedagogique &&
                bbCodeParser.parse(html.bbcodeToHtml(session.modules[0].modalites_pedagogique))) ||
              ''
            }
            onChangeItemActive={itemName => setItemAccordionActive(itemName)}
          />
          <div className="accordion accordion-formateur">
            <div className="accordion-header">
              <div className="accordion-title">
                <i className="fas fa-chalkboard-teacher" /> Formateur(s)
              </div>
            </div>
            <div className="accordion-content">
              {map(session.formateurs, (formateur, i) => (
                <div key={i}>
                  {formateur.prenom} {formateur.nom}
                </div>
              ))}
            </div>
          </div>
          {session.modules[0] && session.modules[0].doc_type_catalogue_url ? (
            <div className="btn-group center">
              <a className="link" href={session.modules[0].doc_type_catalogue_url} target="_blank">
                Télécharger la fiche en PDF
              </a>
            </div>
          ) : null}

          {isDiplayPreInscription && (
            <button
              className="btn btn-primary"
              onClick={() => {
                if (account && account.catalogue_public_signature) {
                  return login(null, null, {
                    value: account.extranet_code.replace(/-/g, ''),
                    formattedValue: account.extranet_code
                  }).then(res => {
                    window.open(
                      `https://aric.asso.fr/nos-formations/formation/${session.modules[0].id_module}/?${res.response.catalogue_public_signature}`,
                      '_blank'
                    );
                  });
                } else {
                  window.open(
                    `https://aric.asso.fr/nos-formations/formation/${session.modules[0].id_module}/`,
                    '_blank'
                  );
                }
              }}
            >
              Se pré-inscrire
            </button>
          )}
          <ModalPreInscription
            session={session}
            confirmPreInscription={() => confirmPreInscription()}
            openModal={openModalPreinscription}
            closeModal={() => setOpenModalPreinscription(false)}
          />

          <ModalSuccessPreInscription
            openModal={openModalSuccessPreInscription}
            closeModal={() => setOpenModalSuccessPreInscription(false)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const session = state.data.entities.sessions && state.data.entities.sessions[props.sessionId];
  const allCoordonnees = state.data.entities.coordonnees && values(state.data.entities.coordonnees);

  const formationsComplementairesIds =
    (session && session.formation && session.formation.formationComplementaire) || [];

  const formationsComplementaires = filter(state.data.entities.formations, f =>
    includes(formationsComplementairesIds, f.idFormation)
  );

  return {
    formationsComplementaires: formationsComplementaires || [],
    cordonnees:
      allCoordonnees &&
      session &&
      session.idAdresse &&
      allCoordonnees[findIndex(allCoordonnees, c => c.idAdresse === session.idAdresse)],
    formateurs: state.data.entities.formateurs && state.data.entities.formateurs,
    documents: filter(
      state.data.entities.documents,
      d => d.idSessionWebservice === Number(props.sessionId)
    ),
    sessions: get(state, 'data.entities.sessions', null),
    session: session,
    account: state.auth.user
  };
};

export default connect(mapStateToProps, { login })(SessionDetails);
