import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { createLogger } from 'redux-logger';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './modules/reducer';
import api from './middleware/api';

const logger = createLogger();

const enhancers = [];

const persistConfig = {
  key: 'aric',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

if (!(process.env.NODE_ENV === 'production') && !(process.env.NODE_ENV === 'staging')) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(thunk, api),
  applyMiddleware(logger),
  ...enhancers
);

export default () => {
  const store = createStore(persistedReducer, {}, composedEnhancers); // {} = initialState
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./modules/reducer', () => {
      const nextRootReducer = require('./modules/reducer'); // eslint-disable-line
      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor: persistStore(store) };
};
