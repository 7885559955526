import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { inscriptionSession } from '../../store/modules/sessions';

import Modal from 'react-modal';
import moment from 'moment';

const ModalPreInscription = ({
  confirmPreInscription,
  account,
  inscriptionSession,
  openModal,
  closeModal,
  session
}) => {
  const [participeRepas, setParticipeRepas] = useState(false);

  const submitInscription = () => {
    const inscription = {
      IdSession: String(session.idSessionWebservice),
      DateInscription: moment().format('YYYY-MM-DD'),
      Demande: true,
      Present: true,
      ParticipationRepas: participeRepas,
      FraisRepas: null,
      Etat: 'demande-elu',
      TiersParticipant: [String(account.username)],
      TiersInscrivant: [String(account.username)]
    };
    return inscriptionSession(inscription).then(() => {
      setParticipeRepas(false);
      confirmPreInscription();
    });
  };

  return (
    <Modal
      className="modal-content small-modal client"
      overlayClassName="modal-overlay modal-restricted-content"
      id="modal-preinscription"
      isOpen={openModal}
    >
      <div className="box">
        <i className="fal fa-lock-alt lock" />
        <p>Confirmez-vous votre pré-inscription à cette session de formation ?</p>
        <div className="field field-checkbox justifyCenter">
          <input
            type="checkbox"
            id="participe_repas"
            onChange={() => setParticipeRepas(!participeRepas)}
            name="participe_repas"
            checked={participeRepas}
          />
          <label htmlFor="participe_repas">Je participe au repas</label>
        </div>

        <div className="btn-group column">
          <button className="btn btn-primary" onClick={() => submitInscription()}>
            <span>Confirmer</span>
          </button>
          <button
            onClick={() => {
              closeModal();
            }}
            className="btn btn-secondary"
          >
            <span>Annuler</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalPreInscription.propTypes = {
  confirmPreInscription: PropTypes.func.isRequired,
  inscriptionSession: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    account: state.auth.user
  }),
  { inscriptionSession }
)(ModalPreInscription);
