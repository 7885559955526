import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadSessions } from '../../store/modules/sessions';
import { loadFormations } from '../../store/modules/formations';
import { loadFolders } from '../../store/modules/folders';
import { loadEditos } from '../../store/modules/editos';
import { loadArticles } from '../../store/modules/articles';
import { loadAccounts } from '../../store/modules/accounts';

import BaseLayout from '../shared/BaseLayout';
import { isAdmin } from '../../utils/RolesUtils';

const AdminContainer = ({
  loadSessions,
  loadFormations,
  loadFolders,
  loadEditos,
  loadArticles,
  loadAccounts,
  account
}) => {
  useEffect(() => {
    loadSessions();
    loadFormations();
    loadFolders();
    loadEditos();
    loadArticles();
    loadAccounts();
  }, []);

  return (
    <BaseLayout title="AdminContainer">
      <h1>Bienvenue du coté {isAdmin(account) && 'admin'}</h1>
    </BaseLayout>
  );
};

AdminContainer.propTypes = {
  loadSessions: PropTypes.func.isRequired,
  loadFormations: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  loadEditos: PropTypes.func.isRequired,
  loadArticles: PropTypes.func.isRequired,
  loadAccounts: PropTypes.func.isRequired
};

export default connect(
  state => ({
    account: state.auth.user
  }),
  { loadSessions, loadFormations, loadFolders, loadEditos, loadArticles, loadAccounts }
)(AdminContainer);
