import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { NavLink, withRouter } from 'react-router-dom';
import { reduxForm, formValueSelector } from 'redux-form';

import { UPLOADS_URL } from '../../constants/Config';

import {
  Field,
  InputField,
  TextareaField,
  WysiwygTextarea,
  FileField
} from 'react-components-linaia';
import BaseLayout from '../../containers/shared/BaseLayout';

import { uploadFiles, removeFile } from '../../store/modules/uploads';

let DetailArticleAdminViewFolder = ({
  handleSubmit,
  match: { url },
  upFiles,
  change,
  textContent,
  rmFile,
  folder
}) => {
  return (
    <BaseLayout title="Dossier">
      <div className="admin-header">
        <div className="admin-header-title">
          <NavLink
            className="btn"
            to={{
              pathname: `/admin/folders`,
              foldersProps: {
                name: 'Back'
              }
            }}
          >
            <i className="far fa-chevron-left"></i>
          </NavLink>
          <h1>
            Dossier
            <span>{folder && folder.title}</span>
          </h1>
        </div>
      </div>
      <div className="admin-submenu-wrapper">
        {url.includes('/details') ? (
          <h2>Modification de l'article</h2>
        ) : (
          <h2>Création de l'article</h2>
        )}
      </div>

      <form className="" onSubmit={handleSubmit} name="DetailArticleAdminViewFolder">
        <div className="grid">
          <div className="col">
            <div className="grid">
              <Field name="title" component={InputField} label="Titre" className="col-12" />
              <Field
                name="summary"
                component={TextareaField}
                label="Chapô"
                className="col-12"
                fieldClassName="textarea"
              />
              <Field
                name="content"
                component={WysiwygTextarea}
                className="col-12"
                text={textContent}
                onBlurProps={e => {
                  return change('content', e);
                }}
                label="Contenu"
                options={[
                  'inline',
                  'blockType',
                  'fontSize',
                  'list',
                  'textAlign',
                  'link',
                  'history',
                  'embedded'
                ]}
                toolbarOptions={{
                  inline: {
                    options: ['bold', 'italic', 'underline']
                  }
                }}
              />
            </div>
          </div>
          <div className="col-3_md-5_lg-4_xl-3_xxl-3">
            <div className="box">
              <div className="grid">
                <Field
                  name="photoUrl"
                  component={FileField}
                  buttonLabel="Choisir une Image"
                  uploadFiles={upFiles}
                  getUrl={item => `${UPLOADS_URL}/${item}`}
                  removeFile={rmFile}
                  className="col-12"
                  label="Photo à la une"
                />
                <Field
                  name="sign"
                  component={TextareaField}
                  label="Signature"
                  className="col-12"
                  fieldClassName="textarea small"
                />
                <Field
                  name="authorName"
                  component={InputField}
                  label="Nom de l'élu.e"
                  className="col-12"
                />
                <Field
                  name="authorFonction"
                  component={TextareaField}
                  label="Fonction de l'élu.e"
                  className="col-12"
                  fieldClassName="textarea small"
                />
                <Field
                  name="authorPhoto"
                  component={FileField}
                  uploadFiles={upFiles}
                  getUrl={item => `${UPLOADS_URL}/${item}`}
                  removeFile={rmFile}
                  buttonLabel="Choisir une Image"
                  className="col-12"
                  label="Portrait de l'élu.e"
                />
                <Field
                  name="creditPhoto"
                  component={TextareaField}
                  label="Crédit photo"
                  className="col-12"
                  fieldClassName="textarea small"
                />
                <div className="col-12">
                  <h3>Information de contact</h3>
                </div>
                <Field name="lieuContact" component={InputField} label="Nom" className="col-12" />
                <Field
                  name="adresseContact"
                  component={TextareaField}
                  label="Adresse"
                  className="col-12"
                  fieldClassName="textarea small"
                />
                <div className="col-12">
                  <div className="grid-noBottom">
                    <Field
                      name="cpContact"
                      component={InputField}
                      label="Code postal"
                      className="col-5"
                    />
                    <Field
                      name="villeContact"
                      component={InputField}
                      label="Ville"
                      className="col-7"
                    />
                  </div>
                </div>
                <Field
                  name="telContact"
                  component={InputField}
                  label="Téléphone"
                  className="col-12"
                />
                <Field
                  name="EmailContact"
                  component={InputField}
                  label="Email"
                  className="col-12"
                />
                <Field
                  name="siteContact"
                  component={InputField}
                  label="Site web"
                  className="col-12"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="btn-group right">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </BaseLayout>
  );
};

DetailArticleAdminViewFolder.propTypes = {
  onSubmit: PropTypes.func.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  }).isRequired,
  folders: PropTypes.objectOf(PropTypes.shape()).isRequired
};

const selector = formValueSelector('DetailArticleReduxForm');

DetailArticleAdminViewFolder = reduxForm({
  form: 'DetailArticleReduxForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.title) errors.title = "Merci de renseigner le titre de contact de l'article";
    if (!values.summary) errors.summary = "Merci de renseigner le Résumé de l'article";
    if (!values.sign) errors.sign = 'Merci de renseigner la signature';
    if (!values.photoUrl) errors.photoUrl = "Merci de renseigner la photo à la une de l'article";
    if (!values.content) errors.content = "Merci de renseigner le contenu de l'article";

    return errors;
  }
})(DetailArticleAdminViewFolder);

DetailArticleAdminViewFolder = connect(
  (state, props) => {
    return {
      textContent:
        selector(state, 'content') || (props.initialValues && props.initialValues.content) || '',
      initialValues:
        state.data.entities.articles[props.article_id] ||
        (state.data.entities.folders && {
          folder_id: state.data.entities.folders[props.folder_id].folder_id
        }) ||
        {},
      folder: state.data.entities.folders[props.folder_id]
    };
  },
  {
    upFiles: uploadFiles,
    rmFile: removeFile
  }
)(DetailArticleAdminViewFolder);

export default withRouter(DetailArticleAdminViewFolder);
