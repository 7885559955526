import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import moment from 'moment';
import { API_URL, publicVapidKey } from './constants/Config';
// import { Chromestore } from "react-components-linaia";

import App from './containers/App';
import urlBase64ToUint8Array from './utils/urlBase64ToUint8Array';

// Global / Default local for moment
moment.locale('fr');

// Persistent Storage request

// Chromestore.init(requestedBytes);

//---------------------------NOTIF-----------------------------------------


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('service-worker.js', { scope: '/' });
    navigator.serviceWorker.ready
      .then(registration => {
        console.log('SW registered: ', registration);
        console.log('SW publicVApidKey ', publicVapidKey);
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
          })
          .then(subscription => {
            console.log('value of subscription : ', subscription);
            console.log('API URL :', API_URL);
            if (subscription) {
              fetch(`${API_URL}/notifications/subscribe`, {
                method: 'POST',
                body: JSON.stringify(subscription),
                headers: {
                  'Content-Type': 'application/json'
                }
              });
            }
          });
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}

function renderWithHotReload(RootElement) {
  render(
    <AppContainer>
      <RootElement />
    </AppContainer>,
    document.getElementById('root')
  );
}
renderWithHotReload(App);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./containers/App', () => {
    const ReplaceContainer = require('./containers/App');
    renderWithHotReload(ReplaceContainer);
  });
}
