import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const onBlurValidation = (onBlur, validationCallback, validationRule, value) => {
  if (onBlur) {
    onBlur(value);
  }
  if (validationCallback) {
    validationCallback(validationRule ? validationRule(value) : false);
  }
};

const Input = ({
  className,
  id,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  type,
  disabled,
  onSearch,
  validationRule,
  validationCallback,
  errorMessage
}) => (
  <div className={classNames(className, { 'read-only': disabled })}>
    {errorMessage ? (
      <div className="field-error">
        {errorMessage}
        <i className="material-icons">error_outline</i>
      </div>
    ) : null}
    <input
      id={id || 'input'}
      type={type || 'text'}
      placeholder={placeholder}
      value={value || ''}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          return onSearch(value);
        }
      }}
      onChange={onChange ? e => onChange(e.target.value) : null}
      onBlur={e => onBlurValidation(onBlur, validationCallback, validationRule, e.target.value)}
      disabled={disabled ? 'disabled' : ''}
    />
    {label ? <label htmlFor={id || 'input'}>{label}</label> : null}
  </div>
);

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onSearch: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  validationRule: PropTypes.func,
  validationCallback: PropTypes.func,
  errorMessage: PropTypes.string
};

export default Input;
