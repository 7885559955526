import React from 'react';
import { NavLink } from 'react-router-dom';

import { toLower } from 'lodash';
import moment from 'moment';

const SearchDetailsView = ({ obj }) => {
  let objData = obj;
  if (obj && obj.id_action_de_formation) {
    objData.type = 'Formation';
  }
  return (
    <NavLink
      to={
        obj.type === 'Dossier'
          ? `/folder/${obj.folder_id}/details`
          : obj.type === 'Article'
          ? `/folder/${obj.folder_id}/article/${obj.article_id}/details`
          : obj.type === 'Edito'
          ? `/edito/${obj.edito_id}/details`
          : obj.type === 'Actualité'
          ? `/redirectAric/${encodeURIComponent(obj.url)}`
          : obj.type === 'Formation'
          ? `/session/${obj.id_action_de_formation}/details`
          : ''
      }
      className={`search-news search-news-${toLower(obj.type)}`}
    >
      <div className="search-news-header">
        <button className="search-news-type">{obj.type}</button>
        <div className="search-news-date">
          {(obj.type === 'Formation' && moment(obj.date_debut).format('DD/MM/YYYY')) ||
            (obj.type === 'Dossier' &&
              moment(obj.dateDebut).format('MMM') + '/' + moment(obj.dateFin).format('MMM YYYY')) ||
            (obj.type === 'Actualité' && moment(obj.date).format('DD MMMM'))}
        </div>
      </div>
      <div className="search-news-title">{obj.type === 'Formation' ? obj.intitule : obj.title}</div>
      <div className="search-news-abstract">
        {obj.type === 'Formation'
          ? obj && obj.modules && obj.modules[0] && obj.modules[0].points_forts
          : obj.summary && obj.summary.replace(/<\/?[^>]+(>|$)/g, '')}
      </div>
    </NavLink>
  );
};

SearchDetailsView.propTypes = {};

export default SearchDetailsView;
