import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getSessionsUser } from '../../store/modules/sessions';
import { logout } from '../../store/modules/auth';

import { map, orderBy, filter } from 'lodash';
import moment from 'moment';

import { NavLink, withRouter } from 'react-router-dom';

import BaseLayout from '../shared/BaseLayout';
import CardFormation from '../../components/formations/CardFormation';

const MonCompte = ({ user, getSessionsUser, sessions, doLogout }) => {
  // avenir  DateDebut > DateJour  ||  (Datedebut <= dateJour ET DateFin >= Datejour)

  const [loading, setLoading] = useState(false);
  const formationsAvenirAndEnCours = filter(
    filter(
      sessions,
      s =>
        moment(moment(s.date_debut).endOf('day')).isAfter(moment()) ||
        (moment(moment(s.date_debut).endOf('day')).isSameOrBefore(moment()) &&
          moment(moment(s.date_fin).endOf('day')).isSameOrAfter(moment()))
    ),
    s => s.visibility
  );
  const formationsPassees = filter(sessions, s =>
    moment(s.date_fin)
      .endOf('day')
      .isBefore(moment())
  );

  useEffect(() => {
    setLoading(true);
    console.log('user : ', user);
    getSessionsUser(user.id_participant).then(() => setLoading(false));
  }, []);

  return (
    <BaseLayout title="Mon compte" isBoxContent classScreen="page-compte container-padding">
      <div className="box-list">
        <div className="box-list-header">
          <div className="box-list-title">Mes formations à venir</div>
        </div>
        {!loading && formationsAvenirAndEnCours && formationsAvenirAndEnCours.length > 0 ? (
          map(orderBy(formationsAvenirAndEnCours, ['date_debut'], ['asc']), (session, i) => (
            <CardFormation key={i} type="futur" session={session} />
          ))
        ) : (
          <div class="empty-list-message">Aucune formation à venir</div>
        )}
      </div>
      <div className="box-list">
        <div className="box-list-header">
          <div className="box-list-title">Mes formations passées</div>
        </div>
        {!loading && formationsPassees && formationsPassees.length > 0 ? (
          map(orderBy(formationsPassees, ['date_debut'], ['desc']), (session, i) => (
            <CardFormation key={i} type="futur" session={session} />
          ))
        ) : (
          <div class="empty-list-message">Aucune formation passée</div>
        )}
      </div>
      <div className="btn-group center">
        <NavLink to="/" exact className="btn btn-primary" onClick={doLogout}>
          <span>Déconnexion</span>
        </NavLink>
      </div>
    </BaseLayout>
  );
};

MonCompte.propTypes = {
  getSessionsUser: PropTypes.func.isRequired,
  doLogout: PropTypes.func
};

export default connect(
  state => ({
    user: state.auth.user,
    sessions: state.data.entities.sessions
  }),
  {
    doLogout: logout,
    getSessionsUser
  }
)(withRouter(MonCompte));
