import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_NOTIFICATIONS_REQUEST = 'arwa/notifications/FETCH_ALL_NOTIFICATIONS_REQUEST';
export const FETCH_ALL_NOTIFICATIONS_SUCCESS = 'arwa/notifications/FETCH_ALL_NOTIFICATIONS_SUCCESS';
const FETCH_ALL_NOTIFICATIONS_FAILURE = 'arwa/notifications/FETCH_ALL_NOTIFICATIONS_FAILURE';
const FETCH_NOTIFICATION_REQUEST = 'arwa/notifications/FETCH_NOTIFICATION_REQUEST';
export const FETCH_NOTIFICATION_SUCCESS = 'arwa/notifications/FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAILURE = 'arwa/notifications/FETCH_NOTIFICATION_FAILURE';
const SET_NOTIFICATION_SUCCESS = 'arwa/notifications/SET_NOTIFICATION_SUCCESS';

const DELETE_NOTIFICATION_SUCCESS = 'arwa/notifications/DELETE_NOTIFICATION_SUCCESS';

export const notificationsActionsHandlers = {
  [FETCH_ALL_NOTIFICATIONS_REQUEST]: state => {
    return flow(set('loaded.notifications', false), set('loading.notifications', true))(state);
  },
  [FETCH_ALL_NOTIFICATIONS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.notifications', action.response || {}),
      // set('entitiesSort.notifications', action.response.entitiesSort.notifications || {}),
      set('loaded.notifications', true),
      set('loading.notifications', false)
    )(state);
  },
  [FETCH_ALL_NOTIFICATIONS_FAILURE]: state => {
    return flow(set('loaded.notifications', false), set('loading.notifications', false))(state);
  },
  [FETCH_NOTIFICATION_REQUEST]: state => {
    return flow(set('loaded.notifications', true), set('loading.notifications', true))(state);
  },
  [FETCH_NOTIFICATION_FAILURE]: state => {
    return flow(set('loaded.notifications', false), set('loading.notifications', false))(state);
  },
  [FETCH_NOTIFICATION_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.notifications.${action.id}`, action.response.entities.notifications[action.id]),
      set('loaded.notifications', true),
      set('loading.notifications', false)
    )(state);
  },
  [SET_NOTIFICATION_SUCCESS]: (state, action) => {
    return flow(
      set('entities.notifications', {
        ...state.entities.notifications,
        ...action.response.entities.notifications
      })
    )(state);
  },
  [DELETE_NOTIFICATION_SUCCESS]: (state, action) => {
    return flow(set('entities.notifications', omit(state.entities.notifications, action.id)))(
      state
    );
  }
};

export function sendNotif(libelle, url) {
  return {
    libelle,
    url,
    [CALL_API]: {
      types: [
        FETCH_ALL_NOTIFICATIONS_REQUEST,
        FETCH_ALL_NOTIFICATIONS_SUCCESS,
        FETCH_ALL_NOTIFICATIONS_FAILURE
      ],
      method: 'POST',
      endpoint: `/notifications/send`,
      body: { libelle, url }
      // successMessage: 'Notifications envoyées avec succès'
    }
  };
}
