import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_FORMATIONS_REQUEST = 'arwa/formations/FETCH_ALL_FORMATIONS_REQUEST';
export const FETCH_ALL_FORMATIONS_SUCCESS = 'arwa/formations/FETCH_ALL_FORMATIONS_SUCCESS';
const FETCH_ALL_FORMATIONS_FAILURE = 'arwa/formations/FETCH_ALL_FORMATIONS_FAILURE';

const REFRESH_ALL_FORMATIONS_REQUEST = 'arwa/formations/REFRESH_ALL_FORMATIONS_REQUEST';
export const REFRESH_ALL_FORMATIONS_SUCCESS = 'arwa/formations/REFRESH_ALL_FORMATIONS_SUCCESS';
const REFRESH_ALL_FORMATIONS_FAILURE = 'arwa/formations/REFRESH_ALL_FORMATIONS_FAILURE';

const FETCH_FORMATION_REQUEST = 'arwa/formations/FETCH_FORMATION_REQUEST';
const FETCH_FORMATION_SUCCESS = 'arwa/formations/FETCH_FORMATION_SUCCESS';
const FETCH_FORMATION_FAILURE = 'arwa/formations/FETCH_FORMATION_FAILURE';
const SET_FORMATION_REQUEST = 'arwa/formations/SET_FORMATION_REQUEST';
const SET_FORMATION_SUCCESS = 'arwa/formations/SET_FORMATION_SUCCESS';
const SET_FORMATION_FAILURE = 'arwa/formations/SET_FORMATION_FAILURE';
const DELETE_FORMATION_REQUEST = 'arwa/formations/DELETE_FORMATION_REQUEST';
const DELETE_FORMATION_SUCCESS = 'arwa/formations/DELETE_FORMATION_SUCCESS';
const DELETE_FORMATION_FAILURE = 'arwa/formations/DELETE_FORMATION_FAILURE';

export const formationsActionsHandlers = {
  [FETCH_ALL_FORMATIONS_REQUEST]: state =>
    flow(set('loaded.formations', false), set('loading.formations', true))(state),
  [FETCH_ALL_FORMATIONS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.formations', action.response.entities.formations || {}),
      set('entitiesSort.formations', action.response.entitiesSort.formations || {}),
      set('loaded.formations', true),
      set('loading.formations', false)
    )(state);
  },
  [FETCH_ALL_FORMATIONS_FAILURE]: state =>
    flow(set('loaded.formations', false), set('loading.formations', false))(state),
  [REFRESH_ALL_FORMATIONS_REQUEST]: state =>
    flow(set('loaded.formations', false), set('loading.formations', true))(state),
  [REFRESH_ALL_FORMATIONS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.formations', action.response.entities.formations || {}),
      set('entitiesSort.formations', action.response.entitiesSort.formations || {}),
      set('loaded.formations', true),
      set('loading.formations', false)
    )(state);
  },
  [REFRESH_ALL_FORMATIONS_FAILURE]: state =>
    flow(set('loaded.formations', false), set('loading.formations', false))(state),

  [FETCH_FORMATION_REQUEST]: state => {
    return flow(set('loaded.formations', false), set('loading.formations', true))(state);
  },
  [FETCH_FORMATION_SUCCESS]: (state, action) =>
    flow(set(`entities.formations.${action.id}`, action.response.entities.formations))(state),
  [FETCH_FORMATION_FAILURE]: state =>
    flow(set('loaded.formations', false), set('loading.formations', false))(state),
  [SET_FORMATION_SUCCESS]: (state, action) =>
    flow(
      set('entities.formations', {
        ...state.entities.formations,
        ...action.response.entities.formations
      })
    )(state),
  [DELETE_FORMATION_SUCCESS]: (state, action) =>
    flow(set('entities.formations', omit(state.entities.formations, action.id)))(state)
};

export function loadFormation(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_FORMATION_REQUEST, FETCH_FORMATION_SUCCESS, FETCH_FORMATION_FAILURE],
      method: 'GET',
      endpoint: `/formations/${id}`,
      schema: Schemas.FORMATION,
      successMessage: 'Formations chargées avec succès'
    }
  };
}

export function loadFormations() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_FORMATIONS_REQUEST,
        FETCH_ALL_FORMATIONS_SUCCESS,
        FETCH_ALL_FORMATIONS_FAILURE
      ],
      method: 'GET',
      endpoint: '/formations',
      schema: Schemas.FORMATION_ARRAY,
      successMessage: 'Formations chargées avec succès'
    }
  };
}
export function refreshFormations() {
  return {
    [CALL_API]: {
      types: [
        REFRESH_ALL_FORMATIONS_REQUEST,
        REFRESH_ALL_FORMATIONS_SUCCESS,
        REFRESH_ALL_FORMATIONS_FAILURE
      ],
      method: 'GET',
      endpoint: '/formations/refresh',
      schema: Schemas.FORMATION_ARRAY,
      successMessage: 'Formations actualisées avec succès'
    }
  };
}

export function createOrUpdateFormation(formation) {
  return {
    [CALL_API]: {
      types: [SET_FORMATION_REQUEST, SET_FORMATION_SUCCESS, SET_FORMATION_FAILURE],
      method: !formation || !Number.isInteger(formation.formation_id) ? 'POST' : 'PUT',
      endpoint:
        !formation || !Number.isInteger(formation.formation_id)
          ? '/formations'
          : `/formations/${formation.formation_id}`,
      schema: Schemas.FORMATION,
      body: formation,
      successMessage: 'Votre Formation est enregistrée avec succès'
    }
  };
}

export function deleteFormation(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_FORMATION_REQUEST, DELETE_FORMATION_SUCCESS, DELETE_FORMATION_FAILURE],
      method: 'DELETE',
      endpoint: `/formations/${id}`,
      successMessage: 'Formations supprimées avec succès'
    }
  };
}
