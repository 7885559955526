import { flow, set } from 'lodash/fp';
import { isUndefined } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_FOLDERS_REQUEST = 'arwa/folders/FETCH_ALL_FOLDERS_REQUEST';
export const FETCH_ALL_FOLDERS_SUCCESS = 'arwa/folders/FETCH_ALL_FOLDERS_SUCCESS';
const FETCH_ALL_FOLDERS_FAILURE = 'arwa/folders/FETCH_ALL_FOLDERS_FAILURE';
const FETCH_FOLDER_REQUEST = 'arwa/folders/FETCH_FOLDER_REQUEST';
const FETCH_FOLDER_SUCCESS = 'arwa/folders/FETCH_FOLDER_SUCCESS';
const FETCH_FOLDER_FAILURE = 'arwa/folders/FETCH_FOLDER_FAILURE';
const SET_FOLDER_REQUEST = 'arwa/folders/SET_FOLDER_REQUEST';
const SET_FOLDER_SUCCESS = 'arwa/folders/SET_FOLDER_SUCCESS';
const SET_FOLDER_FAILURE = 'arwa/folders/SET_FOLDER_FAILURE';
const DELETE_FOLDER_REQUEST = 'arwa/folders/DELETE_FOLDER_REQUEST';
const DELETE_FOLDER_SUCCESS = 'arwa/folders/DELETE_FOLDER_SUCCESS';
const DELETE_FOLDER_FAILURE = 'arwa/folders/DELETE_FOLDER_FAILURE';

export const foldersActionsHandlers = {
  [FETCH_ALL_FOLDERS_REQUEST]: state =>
    flow(set('loaded.folders', false), set('loading.folders', true))(state),
  [FETCH_FOLDER_REQUEST]: state => {
    return flow(set('loaded.folders', false), set('loading.folders', true))(state);
  },
  [FETCH_ALL_FOLDERS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.folders', action.response.entities.folders || {}),
      set('entitiesSort.folders', action.response.entitiesSort.folders || {}),
      set('loaded.folders', true),
      set('loading.folders', false)
    )(state);
  },
  [FETCH_ALL_FOLDERS_FAILURE]: state =>
    flow(set('loaded.folders', false), set('loading.folders', false))(state),
  [FETCH_FOLDER_FAILURE]: state =>
    flow(set('loaded.folders', false), set('loading.folders', false))(state),
  [FETCH_FOLDER_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.folders.${action.id}`, action.response.entities.folders[action.id]),
      set('loaded.folders', true),
      set('loading.folders', false)
    )(state);
  },
  [SET_FOLDER_REQUEST]: state =>
    flow(set('loaded.folders', false), set('loading.folders', true))(state),
  [SET_FOLDER_SUCCESS]: (state, action) => {
    return flow(
      set('entities.folders', {
        ...state.entities.folders,
        ...action.response.entities.folders
      }),
      set('loaded.folders', true),
      set('loading.folders', false)
    )(state);
  },
  [SET_FOLDER_FAILURE]: (state, action) =>
    flow(set('loaded.folders', false), set('loading.folders', false))(state),
  [DELETE_FOLDER_REQUEST]: state => {
    return flow(set('loaded.folders', false), set('loading.folders', true))(state);
  },
  [DELETE_FOLDER_SUCCESS]: (state, action) =>
    flow(
      set('entities.folders', action.response.entities.folders),
      set('entitiesSort.folders', action.response.entitiesSort.folders || {}),
      set('loaded.folders', true),
      set('loading.folders', false)
    )(state)
};

export function loadFolder(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_FOLDER_REQUEST, FETCH_FOLDER_SUCCESS, FETCH_FOLDER_FAILURE],
      method: 'GET',
      endpoint: `/folders/${id}`,
      schema: Schemas.FOLDER,
      successMessage: 'Dossiers chargés avec succès'
    }
  };
}

export function loadFolders() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_FOLDERS_REQUEST, FETCH_ALL_FOLDERS_SUCCESS, FETCH_ALL_FOLDERS_FAILURE],
      method: 'GET',
      endpoint: '/folders',
      schema: Schemas.FOLDER_ARRAY,
      successMessage: 'Dossiers chargés avec succès'
    }
  };
}

export function createOrUpdateFolder(folder) {
  return {
    [CALL_API]: {
      types: [SET_FOLDER_REQUEST, SET_FOLDER_SUCCESS, SET_FOLDER_FAILURE],
      method:
        !folder ||
        (!isUndefined(folder.folder_id) && !Number.isInteger(folder.folder_id)) ||
        isUndefined(folder.folder_id)
          ? 'POST'
          : 'PUT',
      endpoint:
        !folder ||
        (!isUndefined(folder.folder_id) && !Number.isInteger(folder.folder_id)) ||
        isUndefined(folder.folder_id)
          ? '/folders'
          : `/folders/${folder.folder_id}`,
      schema: Schemas.FOLDER,
      body: folder,
      successMessage: 'Votre dossier enregistré avec succès'
    }
  };
}

export function deleteFolder(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_FOLDER_REQUEST, DELETE_FOLDER_SUCCESS, DELETE_FOLDER_FAILURE],
      method: 'DELETE',
      endpoint: `/folders/${id}`,
      schema: Schemas.FOLDER_ARRAY,
      successMessage: 'Dossiers supprimés avec succès'
    }
  };
}
