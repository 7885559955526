import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

export function requireAuthentication(Component) {
  const AuthenticatedComponent = props => {
    useEffect(() => {
      checkAuth();
    }, []);

    useEffect(() => {
      checkAuth();
    }, [props.location]);

    const checkAuth = () => {
      if (!props.user) {
        const redirectAfterLogin = props.location.pathname;
        // props.history.push(`/login?next=${redirectAfterLogin}`);
      }
    };
    return <Fragment>{props.user ? <Component {...props} /> : null}</Fragment>;
  };

  const mapStateToProps = state => ({
    user: state.auth.user
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
}
