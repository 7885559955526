import { flow, set } from 'lodash/fp';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_FORMATEURS_REQUEST = 'arwa/FORMATEURS/FETCH_ALL_FORMATEURS_REQUEST';
const FETCH_ALL_FORMATEURS_SUCCESS = 'arwa/FORMATEURS/FETCH_ALL_FORMATEURS_SUCCESS';
const FETCH_ALL_FORMATEURS_FAILURE = 'arwa/FORMATEURS/FETCH_ALL_FORMATEURS_FAILURE';

export const formateursActionsHandlers = {
  [FETCH_ALL_FORMATEURS_REQUEST]: state =>
    flow(set('loaded.formateurs', false), set('loading.formateurs', true))(state),
  [FETCH_ALL_FORMATEURS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.formateurs', action.response.entities.formateurs || {}),
      set('entitiesSort.formateurs', action.response.entitiesSort.formateurs || {}),
      set('loaded.formateurs', true),
      set('loading.formateurs', false)
    )(state);
  },
  [FETCH_ALL_FORMATEURS_FAILURE]: state =>
    flow(set('loaded.formateurs', false), set('loading.formateurs', false))(state)
};

export function loadFormateurs() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_FORMATEURS_REQUEST,
        FETCH_ALL_FORMATEURS_SUCCESS,
        FETCH_ALL_FORMATEURS_FAILURE
      ],
      method: 'GET',
      endpoint: '/utilisateurs/formateurs',
      schema: Schemas.FORMATEUR_ARRAY,
      successMessage: 'Sessions chargées avec succès'
    }
  };
}
