import React from 'react';
import PropTypes from 'prop-types';

import { map, includes } from 'lodash';

const QuestionsCheckBoxItems = ({
  isMulti,
  labelQuestion,
  choix,
  selectedReponse,
  onChangeReponse
}) => {
  return (
    <div>
      <h4>{labelQuestion}</h4>
      {map(choix, (c, cIndex) => (
        <div
          key={cIndex}
          onClick={() =>
            (isMulti && onChangeReponse([...selectedReponse, c.value])) || onChangeReponse(c.value)
          }
          className={`radio${includes(selectedReponse, c.value) ? ' checked' : ''}`}
        >
          <span className="indicator"></span>
          <span>{c.label}</span>
        </div>
      ))}
    </div>
  );
};

QuestionsCheckBoxItems.propTypes = {
  choix: PropTypes.array.isRequired,
  labelQuestion: PropTypes.string.isRequired
};
export default QuestionsCheckBoxItems;
