import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
const Accordion = ({
  onChangeItemActive,
  itemName,
  itemAccordionActive,
  icon,
  title,
  content,
  markdown
}) => {
  const isActive = itemName === itemAccordionActive;

  const toogleItem = () => {
    onChangeItemActive(itemName);
  };

  const [detail, setDetail] = useState({ content: content });
  useEffect(() => {
    setDetail({ content: content });
  }, [content]);
  return (
    <div className="accordion" onClick={() => toogleItem()}>
      <div className="accordion-header">
        <div className="accordion-title">
          <i className={icon}></i> {title}
        </div>
        <i className={`indicator far fa-angle-${(isActive && 'down') || 'right'}`} />
      </div>
      {isActive ? (
        <div
          className="accordion-content"
          dangerouslySetInnerHTML={{
            __html: detail.content
          }}
        />
      ) : null}
    </div>
  );
};

Accordion.propTypes = {
  onChangeItemActive: PropTypes.func.isRequired,
  itemAccordionActive: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};
export default Accordion;
