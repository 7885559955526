import { flow, set } from 'lodash/fp';

import { CALL_API, Schemas } from '../Schemas';

const GET_INSCRIPTIONS_USER_REQUEST = 'arwa/INSCRIPTIONS/GET_INSCRIPTIONS_USER_REQUEST';
const GET_INSCRIPTIONS_USER_SUCCESS = 'arwa/INSCRIPTIONS/GET_INSCRIPTIONS_USER_SUCCESS';
const GET_INSCRIPTIONS_USER_FAILURE = 'arwa/INSCRIPTIONS/GET_INSCRIPTIONS_USER_FAILURE';

export const inscriptionsActionsHandlers = {
  [GET_INSCRIPTIONS_USER_SUCCESS]: (state, action) => {
    return flow(
      set('entities.inscriptions', action.response.entities.inscriptions || {}),
      set('entitiesSort.inscriptions', action.response.entitiesSort.inscriptions || {}),
      set('loaded.inscriptions', true),
      set('loading.inscriptions', false)
    )(state);
  }
};

export function getInscriptionsByUser(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [
        GET_INSCRIPTIONS_USER_REQUEST,
        GET_INSCRIPTIONS_USER_SUCCESS,
        GET_INSCRIPTIONS_USER_FAILURE
      ],
      method: 'GET',
      endpoint: `/inscriptions/myInscriptions/${userId}`,
      schema: Schemas.INSCRIPTION_ARRAY,
      successMessage: 'Inscriptions chargées avec succès'
    }
  };
}
