import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Field, InputField } from 'react-components-linaia';
import { NavLink, useHistory } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import { Fragment } from 'react';
import { isEmpty, values } from 'lodash';
import CircleLoader from 'react-spinners/CircleLoader';

const LoginForm = ({ handleSubmit, errorProps, isAdmin, loading }) => {
  const navigation = useHistory();

  return (
    <div className="box animated">
      <div className="btn btn-tertiary rounded btn-back" onClick={() => navigation.goBack()}>
        <i className="far fa-long-arrow-left" />
      </div>
      <div className="box-header animated">
        <img src={require('../../../assets/images/logo-aric.svg')} alt="" />
      </div>
      <div className="box-content animated">
        <form onSubmit={handleSubmit} className="login-form">
          {isAdmin ? (
            <Fragment>
              <Field
                name="email"
                component={InputField}
                type="text"
                label="Identifiant"
                placeholder="Identifiant"
                fontIcon="far fa-user"
              />
              <Field
                name="password"
                component={InputField}
                type="password"
                label="Mot de passe"
                placeholder="Mot de passe"
                fontIcon="far fa-lock"
              />
              {errorProps && <div className="field-error">{errorProps}</div>}
            </Fragment>
          ) : (
            <Field
              name="extranet_code"
              // validate={required}
              component={p => {
                return (
                  <Fragment>
                    {errorProps && <div className="field-error">{errorProps.extranet_code}</div>}
                    <PatternFormat
                      format="### - ### - ###"
                      // type="text"
                      allowEmptyFormatting
                      mask="_"
                      value={p.input.formattedValue}
                      onValueChange={(values, sourceInfo) => {
                        p.input.onChange({
                          formattedValue: values.formattedValue,
                          value: values.value
                        });
                      }}
                    />
                  </Fragment>
                );
              }}
              type="text"
              label="Code extranet"
              fontIcon="far fa-user"
            />
          )}
          {/* <PatternFormat format="+1 (###) #### ###" allowEmptyFormatting mask="_" /> */}
          <button type="submit" className="btn btn-full" disabled={loading}>
            {loading ? <CircleLoader size={30} color={'black'} loading={loading} /> : 'Connexion'}
          </button>
          {!isAdmin ? (
            <div className="login-footer-informations">
              Vous n'avez pas d'identifiants ou vous les avez perdus ?
              <br />
              <br />
              Contactez Audrey au <a href="tel:+33 2 99 41 50 07">02 99 41 50 07</a>
              ou par le biais du
              <NavLink to="/contact" exact activeClassName="active">
                <a href="/contact">formulaire de contact</a>
              </NavLink>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};
LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClickRecoverPassword: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'LoginForm',
  validate: (values = {}) => {
    const errors = {};
    if (!values.extranet_code && !values.email) {
      errors.email = 'Champ obligatoire';
    }
    // ne marche pas
    if (values.extranet_code && values.extranet_code.value && isEmpty(values.extranet_code.value)) {
      errors.extranet_code = 'Champ obligatoire';
    }
    return errors;
  }
  // touchOnBlur: false
})(LoginForm);
