import React, { useEffect } from 'react';
import { withRouter, BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';

import { includes, filter, parseInt } from 'lodash';
import { connect } from 'react-redux';

import ResetPassword from './login/ResetPassword';

import PageNotFound from './shared/PageNotFound';
import Home from './home/Home';
import FolderDetailView from '../components/folders/FolderDetailView';
import { requireAuthentication } from '../components/hoc/AuthenticatedComponent';
import FolderAdminEditView from '../components/folders/FolderAdminEditView';
import AdminEditView from '../components/accounts/AdminEditView';

import EditoDetailView from '../components/editos/EditoDetailView';
import FavoritesView from '../components/menu/Favorites';
import QuestionFormView from '../components/menu/QuestionForm';
import ArticleDetailView from '../components/articles/ArticleDetailView';
import DetailArticleAdminViewFolder from '../components/articles/DetailArticleAdminViewFolder';

import LoginContainer from './login/Login';
import LoginAdmin from './login/LoginAdmin';
import AdminContainer from './admin/AdminContainer';
import SearchView from './search/SearchView';
import MyAccountContainer from './accounts/MyAccountContainer';

import FormationsAdminContainer from './formations/FormationsAdminContainer';
import FormulairesEvaluationsContainer from './evaluations/FormulairesEvaluationsContainer';

import ActualitiesAdminContainer from './actualities/ActualitiesAdminContainer';
import NotificationsAdminContainer from './notifications/NotificationsAdminContainer';
import AdministratorsAdminContainer from './administrators/AdministratorsAdminContainer';
import FoldersAdminContainer from './folders/FoldersAdminContainer';

import { createOrUpdateArticle } from '../store/modules/articles';
import { createOrUpdateAccount } from '../store/modules/accounts';
import SessionDetails from './formations/SessionDetails';
import MonCompte from './monCompte/MonCompte';

import Evaluationformation from './formations/Evaluationformation';
import CreateOrEditFormulaireEvalContainer from './evaluations/CreateOrEditFormulaireEvalContainer';
import EvaluationsContainer from './evaluations/EvaluationsContainer';
import DocumentsContainer from './DocumentsContainer';
import { requiredRoleElu } from '../components/hoc/ReservedElu';

function _ScrollToTop(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const Router = function({ createOrUpdateArticle, createOrUpdateAccount }) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/admin/login" component={LoginAdmin} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={QuestionFormView} />
          <Route exact path="/favorites" component={FavoritesView} />
          <Route
            path="/redirectAric/:path"
            component={subProps => {
              let path = decodeURIComponent(subProps.match.params.path);
              if (includes(path, 'https://www.aric.asso.fr/')) {
                window.location.replace(path);
                return null;
              } else {
                return <Home />;
              }
            }}
          />
          <Route
            exact
            path="/search/:defaultTag?"
            component={subProps => {
              return <SearchView defaultTag={subProps.match.params.defaultTag} />;
            }}
          />
          <Route
            path="/folder/:folderId/details"
            component={requiredRoleElu(({ match }) => {
              return <FolderDetailView folderId={match.params.folderId} />;
            }, true)}
          />
          <Route
            path="/folder/:folderId/edito/:editoId/details"
            component={requiredRoleElu(({ match }) => {
              return (
                <EditoDetailView
                  folder_id={match.params.folderId}
                  edito_id={match.params.editoId}
                />
              );
            }, true)}
          />
          <Route
            path="/folder/:folderId/article/:articleId/details"
            component={requiredRoleElu(({ match }) => {
              return (
                <ArticleDetailView
                  folder_id={match.params.folderId}
                  article_id={match.params.articleId}
                />
              );
            }, true)}
          />
          <Route
            path="/session/:sessionId/details"
            component={({ match }) => {
              return <SessionDetails sessionId={match.params.sessionId} />;
            }}
          />
          <Route exact path="/mon-compte" component={requireAuthentication(MonCompte)} />
          {/* // TODO JULES idSessionWebservice remplacement par id_action_de_formation dendreo */}
          <Route
            exact
            path="/formation-evaluation/:idSessionWebservice"
            component={({ match }) => {
              return <Evaluationformation sessionId={match.params.idSessionWebservice} />;
            }}
          />
          <Route exact path="/user/account" component={requireAuthentication(MyAccountContainer)} />
          <Route
            exact
            path="/admin/folders/:folderId/details"
            component={requireAuthentication(({ match }) => {
              return (
                <FolderAdminEditView
                  name="Details"
                  baseUrl={'/admin/folders'}
                  folders_id={match.params.folderId}
                />
              );
            })}
          />
          <Route
            exact
            path="/admin/folders/new"
            component={requireAuthentication(({ match }) => {
              return <FolderAdminEditView name="Details" baseUrl={'/admin/folders'} />;
            })}
          />
          {/*-----------------------------------------------------------------------------_*/}
          <Route
            exact
            path="/admin/folders/:folderId/articles/new"
            component={requireAuthentication(({ match, history }) => {
              return (
                <DetailArticleAdminViewFolder
                  folder_id={match.params.folderId}
                  baseUrl={`/admin/folders/${match.params.folderId}/articles`}
                  onSubmit={v => {
                    return createOrUpdateArticle(v).then(() =>
                      history.push(`/admin/folders/${match.params.folderId}/articles`)
                    );
                  }}
                />
              );
            })}
          />
          {/*-----------------------------------------------------------------------------_*/}
          <Route
            exact
            path="/admin/folders/:folderId/articles/:articleId/details"
            component={requireAuthentication(({ match, history }) => {
              return (
                <DetailArticleAdminViewFolder
                  article_id={match.params.articleId}
                  folder_id={match.params.folderId}
                  onSubmit={v => {
                    return createOrUpdateArticle(v).then(() =>
                      history.push(`/admin/folders/${match.params.folderId}/articles`)
                    );
                  }}
                />
              );
            })}
          />
          {/* /EDITOS */}
          <Route
            exact
            path="/admin/folders/:folderId/editos"
            component={requireAuthentication(({ match }) => {
              return (
                <FolderAdminEditView
                  name="Edito"
                  baseUrl={'/admin/folders'}
                  folders_id={match.params.folderId}
                />
              );
            })}
          />
          {/* /ARTICLES */}
          <Route
            exact
            path="/admin/folders/:folderId/articles"
            component={requireAuthentication(({ match }) => {
              return (
                <FolderAdminEditView
                  name="Articles"
                  baseUrl={'/admin/folders'}
                  folders_id={match.params.folderId}
                />
              );
            })}
          />
          <Route
            exact
            path="/admin/folders/:folderId/articles/:articleId/edit"
            component={requireAuthentication(subProps => {
              return (
                <FolderAdminEditView
                  initialValues={articles[parseInt(subProps.match.params.articleId)]}
                  folders={folders[subProps.match.params.folderId]}
                  baseUrl={'/admin/folders'}
                  editos={filter(
                    editos,
                    edito => edito.folder_id === parseInt(subProps.match.params.folderId)
                  )}
                  articles={filter(
                    articles,
                    article => article.folder_id === parseInt(subProps.match.params.folderId)
                  )}
                  showEditArticle={articles[parseInt(subProps.match.params.articleId)]}
                />
              );
            })}
          />
          {/* /DETAILS FOLDERS*/}
          <Route
            exact
            path="/admin/administrators/new"
            component={requireAuthentication(({ match, history }) => {
              return (
                <AdminEditView
                  name="new"
                  onSubmit={v => {
                    return createOrUpdateAccount(v);
                  }}
                />
              );
            })}
          />
          <Route
            exact
            path="/admin/administrators/:accountId/details"
            component={requireAuthentication(({ match }) => {
              return (
                <AdminEditView
                  account_id={match.params.accountId}
                  name="Details"
                  onSubmit={v => {
                    return createOrUpdateAccount(v);
                  }}
                />
              );
            })}
          />
          {/* <Route
            path="/formateur/documents"
            component={requireAuthentication(DocumentsContainer)}
          /> */}
          <Route
            path="/admin/formations"
            component={requireAuthentication(FormationsAdminContainer)}
          />
          {/* <Route
            path="/admin/evaluations"
            component={requireAuthentication(EvaluationsContainer)}
          /> */}
          {/* <Route
            path="/admin/formulaires-evaluations"
            component={requireAuthentication(FormulairesEvaluationsContainer)}
          /> */}
          {/* <Route
            path="/admin/formulaire-evaluation"
            component={requireAuthentication(CreateOrEditFormulaireEvalContainer)}
          /> */}
          <Route
            path="/admin/actualities"
            component={requireAuthentication(ActualitiesAdminContainer)}
          />
          <Route
            path="/admin/notifications"
            component={requireAuthentication(NotificationsAdminContainer)}
          />
          <Route
            path="/admin/administrators"
            component={requireAuthentication(AdministratorsAdminContainer)}
          />
          <Route path="/admin/folders" component={requireAuthentication(FoldersAdminContainer)} />
          <Route path="/admin" component={requireAuthentication(AdminContainer)} />
          <Route path="*" render={PageNotFound} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default connect(state => ({}), {
  createOrUpdateArticle,
  createOrUpdateAccount
})(Router);
