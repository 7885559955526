import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_DOCUMENTS_REQUEST = 'arwa/FETCH_ALL_DOCUMENTS_REQUEST';
const FETCH_ALL_DOCUMENTS_SUCCESS = 'arwa/FETCH_ALL_DOCUMENTS_SUCCESS';
const FETCH_ALL_DOCUMENTS_FAILURE = 'arwa/FETCH_ALL_DOCUMENTS_FAILURE';

const CREATE_DOCUMENT_REQUEST = 'arwa/CREATE_DOCUMENT_REQUEST';
const CREATE_DOCUMENT_SUCCESS = 'arwa/CREATE_DOCUMENT_SUCCESS';
const CREATE_DOCUMENT_FAILURE = 'arwa/CREATE_DOCUMENT_FAILURE';

const DELETE_DOCUMENT_REQUEST = 'arwa/DELETE_DOCUMENT_REQUEST';
const DELETE_DOCUMENT_SUCCESS = 'arwa/DELETE_DOCUMENT_SUCCESS';
const DELETE_DOCUMENT_FAILURE = 'arwa/DELETE_DOCUMENT_FAILURE';

const UPDATE_DOCUMENT_REQUEST = 'arwa/UPDATE_DOCUMENT_REQUEST';
const UPDATE_DOCUMENT_SUCCESS = 'arwa/UPDATE_DOCUMENT_SUCCESS';
const UPDATE_DOCUMENT_FAILURE = 'arwa/UPDATE_DOCUMENT_FAILURE';

export const documentsActionsHandlers = {
  [FETCH_ALL_DOCUMENTS_REQUEST]: state =>
    flow(set('loaded.documents', false), set('loading.documents', true))(state),
  [FETCH_ALL_DOCUMENTS_SUCCESS]: (state, action) =>
    flow(
      set('entities.documents', action.response.entities.documents || {}),
      set('loaded.documents', true),
      set('loading.documents', false)
    )(state),
  [FETCH_ALL_DOCUMENTS_FAILURE]: state =>
    flow(set('loaded.documents', false), set('loading.documents', false))(state),
  [CREATE_DOCUMENT_SUCCESS]: (state, action) => {
    return flow(
      set('entities.documents', {
        ...state.entities.documents,
        ...action.response.entities.documents
      }),
      set('loaded.documents', true),
      set('loading.documents', false)
    )(state);
  },
  [UPDATE_DOCUMENT_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.documents.${action.doc.document_id}`, action.response),
      set('loaded.documents', true),
      set('loading.documents', false)
    )(state);
  },
  [DELETE_DOCUMENT_SUCCESS]: (state, action) =>
    flow(set('entities.documents', omit(state.entities.documents, action.docId)))(state)
};

export function loadDocuments() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_DOCUMENTS_REQUEST,
        FETCH_ALL_DOCUMENTS_SUCCESS,
        FETCH_ALL_DOCUMENTS_FAILURE
      ],
      method: 'GET',
      endpoint: '/documents',
      schema: Schemas.DOCUMENT_ARRAY,
      successMessage: 'Documents chargés avec succès'
    }
  };
}

export function createDocument(data) {
  return {
    [CALL_API]: {
      types: [CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS, CREATE_DOCUMENT_FAILURE],
      method: 'POST',
      endpoint: '/documents',
      schema: Schemas.DOCUMENT,
      body: data
    }
  };
}

export function deleteDocument(docId) {
  return {
    docId,
    [CALL_API]: {
      types: [DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILURE],
      method: 'DELETE',
      endpoint: `/documents/${docId}`,
      successMessage: 'Le document a bien été supprimé'
    }
  };
}

export function updateDocument(doc, data) {
  return {
    doc,
    [CALL_API]: {
      types: [UPDATE_DOCUMENT_REQUEST, UPDATE_DOCUMENT_SUCCESS, UPDATE_DOCUMENT_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/documents/${doc.document_id}`,
      successMessage: 'Le document a bien été modifié'
    }
  };
}
