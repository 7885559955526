import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { isUndefined } from 'lodash';

import { createOrUpdateFolder, deleteFolder } from '../../store/modules/folders';

import BaseLayout from '../shared/BaseLayout';

import TableListView from '../../components/admin/TableListView';

const FoldersAdminContainer = ({
  match: { url },
  history: { push },
  folders,
  foldersSorted,
  baseUrl,
  loadedFolders,
  loadingFolders,
  createOrUpdateFolder,
  deleteFolder,
  onClickDelete,
  ...props
}) => {
  return (
    <BaseLayout>
      <div className="admin-header">
        <div className="admin-header-title">
          <h1>Dossiers</h1>
        </div>
        <div className="admin-header-buttons">
          <button className="btn" onClick={() => push(`${url}/new`)}>
            Créer un dossier
          </button>
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table-container">
          {!loadingFolders && !isUndefined(folders) && (
            <TableListView
              data={folders}
              sorted={foldersSorted}
              nameDetails="folder"
              name="folders"
              tabs={['Visibilité', 'Date', 'Titre', 'Réservé', 'Tags', 'Buttons']}
              edit={data => createOrUpdateFolder(data)}
              deleteFolder={data => deleteFolder(data)}
              // deleteArticle={data => deleteArticle(data)}
              // deleteEdito={data => deleteEdito(data)}
              editFolder={data => createOrUpdateFolder(data)}
              isButtonEditoArticle
            />
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

FoldersAdminContainer.propTypes = {
  folders: PropTypes.objectOf(PropTypes.shape()).isRequired,
  baseUrl: PropTypes.string,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,

  onClickDelete: PropTypes.func
};

export default connect(
  state => ({
    foldersSorted: (state.data.entitiesSort && state.data.entitiesSort.folders) || [],
    folders: (state.data.entities && state.data.entities.folders) || {},
    loadedFolders: state.data.loaded.folders,
    loadingFolders: state.data.loading.folders
  }),
  {
    createOrUpdateFolder,
    deleteFolder
  }
)(withRouter(FoldersAdminContainer));
