import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadFiles, removeFile } from '../../store/modules/uploads';
import { createDocument, updateDocument } from '../../store/modules/documents';
import { map, find, sortBy, filter, isEmpty, head, includes, values } from 'lodash';
import CircleLoader from 'react-spinners/CircleLoader';
import moment from 'moment';
moment.locale('fr');
import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';
import FieldUploadDropZone from '../../components/shared/FieldUploadDropZone';

const ModalFormDocument = ({
  user,
  doc,
  editMode,
  sessions,
  updateDocument,
  createDocument,
  uploadFiles,
  rmFile
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    console.log('VALUES', values);
    setLoading(true);

    let data = values;
    data.idSessionWebservice = Number(data.idSessionWebservice);

    const idFormationWebservice =
      sessions[Number(data.idSessionWebservice)] &&
      sessions[Number(data.idSessionWebservice)].formation &&
      sessions[Number(data.idSessionWebservice)].formation.idFormationWebservice;

    data.formateurId = user.account_id;
    data.idFormationWebservice = Number(idFormationWebservice);

    if (editMode) {
      if (data.fileName !== doc.fileName) {
        return uploadFiles(data.fileName)
          .then(res => {
            data.fileName = res.response[0];

            return updateDocument(doc, data)
              .then(() => {
                setIsOpen(false);
                setLoading(false);
              })
              .catch(err => {
                setLoading(false);
                setError(err);
              });
          })
          .catch(err => setError(err));
      } else {
        return updateDocument(doc, data)
          .then(() => {
            setLoading(false);
            setIsOpen(false);
          })
          .catch(err => {
            setLoading(false);
            setError(err);
          });
      }
    } else {
      return uploadFiles(data.fileName)
        .then(res => {
          data.fileName = res.response[0];

          return createDocument(data)
            .then(() => {
              setLoading(false);
              setIsOpen(false);
            })
            .catch(err => {
              setLoading(false);
              setError(err);
            });
        })
        .catch(err => {
          setLoading(false);
          setError(err);
        });
    }
  };

  const formateurSessions = filter(values(sessions), s => includes(s.formateurs, user.username));

  const currentSessions = filter(
    formateurSessions,
    s =>
      s.dateFin >=
      moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD')
  );

  const optionsSessions = map(sortBy(currentSessions, 'dateDebut'), s => {
    const start = moment(s.dateDebut, 'YYYY-MM-DD').format('dddd DD/MM/YYYY');
    const end = moment(s.dateFin, 'YYYY-MM-DD').format('dddd DD/MM/YYYY');
    return {
      label:
        start === end
          ? `${s.formation.titre} (le ${start})`
          : `${s.formation.titre} (du ${start} au ${end})`,
      value: Number(s.idSessionWebservice)
    };
  });

  return (
    <Fragment>
      {!editMode && !isEmpty(optionsSessions) && (
        <button
          onClick={() => {
            setError(null);
            setIsOpen(!isOpen);
          }}
          className="btn btn-primary"
        >
          <i className="fas fa-plus-circle" />
          <span>Ajouter un document</span>
        </button>
      )}

      <Modal {...modalDefaultClass} id="modal-document" isOpen={isOpen}>
        <div className="modal-title">
          {!editMode ? 'Ajouter un document' : 'Modifier un document'}
          <div onClick={() => setIsOpen(false)} className="btn-close">
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid">
                  <div className="col-12">
                    <Field
                      name="idSessionWebservice"
                      validate={required}
                      initialValue={
                        (editMode &&
                          optionsSessions &&
                          find(optionsSessions, o => o.value === doc.idSessionWebservice).value) ||
                        (optionsSessions && head(optionsSessions) && head(optionsSessions).value) ||
                        null
                      }
                      component="select"
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <label>Session *</label>
                            <Select
                              options={optionsSessions}
                              className="react-select"
                              classNamePrefix="select"
                              defaultValue={optionsSessions[0]}
                              onChange={option => {
                                input.onChange(option.value);
                              }}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-12">
                    <Field name="titre" validate={required} initialValue={editMode && doc.titre}>
                      {({ input, meta }) => {
                        return (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <label>Titre *</label>
                            <input {...input} type="text" />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-12">
                    <Field
                      name="fileName"
                      validate={required}
                      initFile={doc && doc.fileName}
                      initialValue={editMode && doc.fileName}
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <label>Fichier *</label>
                            <FieldUploadDropZone
                              rmFile={file => rmFile(file)}
                              acceptedFiles={[
                                '.pdf',
                                '.doc',
                                '.docx',
                                '.xls',
                                '.xlsx',
                                '.ppt',
                                '.pptx',
                                '.odt',
                                '.ods',
                                '.odp'
                              ]}
                              initFile={doc && doc.fileName}
                              {...input}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>

                {error && <div className="error-message">{error}</div>}

                <div className="modal-footer">
                  <div className="btn-group center">
                    <button
                      type="button"
                      onClick={() => setIsOpen(false)}
                      className="btn btn-secondary"
                      disabled={loading}
                    >
                      <span>Annuler</span>
                    </button>

                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      {loading ? (
                        <CircleLoader size={30} color={'black'} loading={loading} />
                      ) : (
                        <span>Valider</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalFormDocument.propTypes = {
  user: PropTypes.object,
  doc: PropTypes.object,
  sessions: PropTypes.array,
  createDocument: PropTypes.func.isRequired,
  updateDocument: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  rmFile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  sessions: state.data.entities.sessions && state.data.entities.sessions
});

export default connect(mapStateToProps, {
  createDocument,
  updateDocument,
  uploadFiles,
  rmFile: removeFile
})(ModalFormDocument);
