import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { THEMES_FORMATIONS } from '../../constants/Properties';

import { connect } from 'react-redux';
import { getSessionsUser } from '../../store/modules/sessions';
import { createEvaluation } from '../../store/modules/evaluations';

import BaseLayout from '../shared/BaseLayout';

import { map, values, xor, findIndex, isNull, isArray, includes } from 'lodash';
import { Form, Field } from 'react-final-form';

import QuestionsCheckBoxItems from '../../components/shared/QuestionsCheckBoxItems';
import ModalSuccessEvaluateSession from '../../components/modals/ModalSuccessEvaluateSession';

const Evaluationformation = ({ createEvaluation, formation, user, session, coordonnees }) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const onSubmit = questions => {
    const reponses = [];

    map(questions, (reponsesQuestion, questionFieldName) => {
      const indexQuestionParent = questionFieldName[9];
      const indexQuestionEnfant = questionFieldName[11] === '0' ? '' : questionFieldName[11];
      const isRemarque = questionFieldName.includes('remarques');

      const questionAnnexe = `Q${indexQuestionParent}.${indexQuestionEnfant} ${(isRemarque &&
        'remarques') ||
        ''}`;
      // question_6_0  themes formations doit etre tableau

      if (questionFieldName === 'question_6_0') {
        reponses.push({
          label: 'VIE_MUN',
          reponses: (includes(reponsesQuestion, 'VIE_MUN') && 'VRAI') || ''
        });
        reponses.push({
          label: 'URBANISME',
          reponses: (includes(reponsesQuestion, 'URBANISME') && 'VRAI') || ''
        });
        reponses.push({
          label: 'EFFICACITE',
          reponses: (includes(reponsesQuestion, 'EFFICACITE') && 'VRAI') || ''
        });
        reponses.push({
          label: 'SOCIAL',
          reponses: (includes(reponsesQuestion, 'SOCIAL') && 'VRAI') || ''
        });
        reponses.push({
          label: 'FIN_LOC',
          reponses: (includes(reponsesQuestion, 'FIN_LOC') && 'VRAI') || ''
        });
        reponses.push({
          label: 'PJT_COM',
          reponses: (includes(reponsesQuestion, 'PJT_COM') && 'VRAI') || ''
        });
        reponses.push({
          label: 'COMM_MUNI',
          reponses: (includes(reponsesQuestion, 'COMM_MUNI') && 'VRAI') || ''
        });
      } else {
        reponses.push({
          label: questionAnnexe,
          reponses: isArray(reponsesQuestion) ? reponsesQuestion.toString() : reponsesQuestion
        });
      }
    });

    return createEvaluation({
      prenom: user.lastName || '',
      nom: user.firstName || '',
      identifiantElu: Number(user.username),
      nomFormation: formation.titre,
      idFormationWebservice: Number(formation.idFormationWebservice),
      idSessionWebservice: Number(session.idSessionWebservice),
      dateSession: session.dateDebut,
      formateurs: session.formateurs,
      ville:
        (coordonnees &&
          !isNull(session.idAdresse) &&
          coordonnees[findIndex(coordonnees, c => c.idAdresse === Number(session.idAdresse))] &&
          coordonnees[findIndex(coordonnees, c => c.idAdresse === Number(session.idAdresse))]
            .ville) ||
        '',
      reponses: reponses
    })
      .then(() => {
        setOpenSuccessModal(true);
      })
      .catch(err => {});
  };

  const renderField = (question, groups, groupIndex, questionIndex) => {
    const sectionNumber =
      groups.length > 1 ? `${groupIndex + 1}.${questionIndex + 1}` : `${groupIndex + 1}.`;

    switch (question.question_type) {
      case 'checkbox':
      case 'multiple-checkbox':
        return (
          <Fragment>
            <Field
              name={`question_${String(groupIndex + 1)}_${String(
                groups.length > 1 ? questionIndex + 1 : questionIndex
              )}`}
              initialValue={''}
              className="field"
            >
              {({ input, meta }) => (
                <QuestionsCheckBoxItems
                  isMulti={question && question.question_type === 'multiple-checkbox'}
                  selectedReponse={input.value}
                  onChangeReponse={reponse => {
                    question && question.question_type === 'multiple-checkbox'
                      ? input.onChange(xor(input.value, [reponse]))
                      : input.onChange([reponse]);
                  }}
                  labelQuestion={`${sectionNumber} ${question.titre}`}
                  choix={map(question.choix, reponse => {
                    return {
                      label: (question.isThemes && THEMES_FORMATIONS[reponse]) || reponse,
                      value: reponse
                    };
                  })}
                />
              )}
            </Field>

            {question && question.subinput === 'text-area' && (
              <Field
                name={`question_${String(groupIndex + 1)}_${String(
                  groups.length > 1 ? questionIndex + 1 : questionIndex
                )}_remarques`}
                component="input"
                initialValue=""
                className="field"
              >
                {({ input }) => (
                  <div className="field">
                    <textarea
                      placeholder="Remarques"
                      {...input}
                      onChange={value => {
                        input.onChange(value);
                      }}
                    />
                  </div>
                )}
              </Field>
            )}
          </Fragment>
        );
      case 'text-area':
        return (
          <Field
            name={`question_${String(groupIndex + 1)}_${String(
              groups.length > 1 ? questionIndex + 1 : questionIndex
            )}`}
            component="input"
            className="field"
            initialValue=""
          >
            {({ input }) => (
              <div className="field">
                <h4>{`${sectionNumber} ${(question.titre && question.titre) || 'remarques'}`}</h4>
                <textarea
                  placeholder="Votre texte ici…"
                  {...input}
                  onChange={value => {
                    input.onChange(value);
                  }}
                />
              </div>
            )}
          </Field>
        );
    }
  };
  return (
    <BaseLayout title="Évaluation" classScreen="evaluation">
      <div className="news-header">
        <div className="news-header-wrapper">
          <h1 className="news-title">{formation && formation.titre}</h1>
        </div>
      </div>
      <div className="news-body">
        <div className="news-wrapper">
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {formation &&
                  formation.formulaire_evaluation &&
                  map(values(formation.formulaire_evaluation), (groups, groupIndex) => {
                    return map(groups, (group, questionIndex) => {
                      return renderField(group, groups, groupIndex, questionIndex);
                    });
                  })}
                <div className="btn-group center">
                  <button className="btn btn-primary" type="submit">
                    <span>Valider</span>
                  </button>
                </div>
              </form>
            )}
          />
          <ModalSuccessEvaluateSession
            session={session}
            openModal={openSuccessModal}
            closeModal={() => setOpenSuccessModal(false)}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

Evaluationformation.propTypes = {
  getSessionsUser: PropTypes.func.isRequired,
  createEvaluation: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const sessions = state.data.entities.sessions;

  const idFormationWebservice =
    (sessions[Number(props.sessionId)] &&
      sessions[Number(props.sessionId)].idFormationWebservice) ||
    Number(props.sessionId);

  return {
    coordonnees: state.data.entities.coordonnees && values(state.data.entities.coordonnees),
    session: props.sessionId && sessions && sessions[Number(props.sessionId)],
    user: state.auth.user,
    formation:
      state.data.entities.formations && state.data.entities.formations[idFormationWebservice]
  };
};

export default connect(mapStateToProps, {
  getSessionsUser,
  createEvaluation
})(withRouter(Evaluationformation));
