export default {
  entities: {
    accounts: {},
    options: {},
    models: {},
    uploadContents: {},
    posts: {},
    folders: {},
    formations: {},
    sessions: {},
    inscriptions: {},
    evaluations: {},
    documents: {},
    formateurs: {},
    coordonnees: {},
    actualities: {},
    favorites: {},
    questions: {},
    editos: {},
    articles: {},
    notifications: {}
  },
  loaded: {
    appstorage: false,
    accounts: false,
    options: false,
    models: false,
    uploadContents: false,
    posts: false,
    folders: false,
    formations: false,
    inscriptions: false,
    evaluations: false,
    sessions: false,
    formateurs: false,
    coordonnees: false,
    actualities: false,
    favorites: false,
    questions: false,
    editos: false,
    articles: false,
    notifications: false
  },
  loading: {
    accounts: false,
    options: false,
    models: false,
    uploadContents: false,
    posts: false,
    folders: false,
    formations: false,
    inscriptions: false,
    evaluations: false,
    documents: false,
    sessions: false,
    formateurs: false,
    coordonnees: false,
    actualities: false,
    articles: false,
    favorites: false,
    editos: false,
    questions: false,
    notifications: false
  },
  message: {
    text: '',
    messageType: '',
    delay: 0
  },
  config: {
    menuIsTiny: null,
    menuIsOpen: null
  }
};
