import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ButtonConfirmModal = ({ actionDelete, message }) => {
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const confirmDelete = () => {
    return actionDelete()
      .then(() => {
        setIsOpen(false);
      })
      .catch(err => setError(err));
  };
  return (
    <Fragment>
      <button className="btn" type="button" onClick={() => setIsOpen(true)}>
        <i className="far fa-trash-alt" />
      </button>
      <Modal {...modalDefaultClass} id="confirm-delete" isOpen={isOpen}>
        <div className="modal-title">
          Suppression
          <div
            onClick={() => {
              setIsOpen(false);
              setError(null);
            }}
            className="btn-close"
          >
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">{message}</div>
        {error && <div className="error-message">{error}</div>}
        <div className="modal-footer">
          <div className="btn-group center">
            <button
              onClick={() => {
                setIsOpen(false);
                setError(null);
              }}
              className="btn btn-secondary"
            >
              <span>Annuler</span>
            </button>
            <button className="btn btn-primary" onClick={() => confirmDelete()}>
              <span>Supprimer</span>
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

ButtonConfirmModal.propTypes = {
  actionDelete: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default ButtonConfirmModal;
