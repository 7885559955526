import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_ARTICLES_REQUEST = 'arwa/articles/FETCH_ALL_ARTICLES_REQUEST';
export const FETCH_ALL_ARTICLES_SUCCESS = 'arwa/articles/FETCH_ALL_ARTICLES_SUCCESS';
const FETCH_ALL_ARTICLES_FAILURE = 'arwa/articles/FETCH_ALL_ARTICLES_FAILURE';
const FETCH_ARTICLE_REQUEST = 'arwa/articles/FETCH_ARTICLE_REQUEST';
export const FETCH_ARTICLE_SUCCESS = 'arwa/articles/FETCH_ARTICLE_SUCCESS';
const FETCH_ARTICLE_FAILURE = 'arwa/articles/FETCH_ARTICLE_FAILURE';
const SET_ARTICLE_REQUEST = 'arwa/articles/SET_ARTICLE_REQUEST';
const SET_ARTICLE_SUCCESS = 'arwa/articles/SET_ARTICLE_SUCCESS';
const SET_ARTICLE_FAILURE = 'arwa/articles/SET_ARTICLE_FAILURE';
const DELETE_ARTICLE_REQUEST = 'arwa/articles/DELETE_ARTICLE_REQUEST';
const DELETE_ARTICLE_SUCCESS = 'arwa/articles/DELETE_ARTICLE_SUCCESS';
const DELETE_ARTICLE_FAILURE = 'arwa/articles/DELETE_ARTICLE_FAILURE';

export const articlesActionsHandlers = {
  [FETCH_ALL_ARTICLES_REQUEST]: state =>
    flow(set('loaded.articles', false), set('loading.articles', true))(state),
  [FETCH_ALL_ARTICLES_SUCCESS]: (state, action) =>
    flow(
      set('entities.articles', action.response.entities.articles || {}),
      set('entitiesSort.articles', action.response.entitiesSort.articles || {}),
      set('loaded.articles', true),
      set('loading.articles', false)
    )(state),
  [FETCH_ARTICLE_REQUEST]: state => {
    return flow(set('loaded.articles', false), set('loading.articles', true))(state);
  },
  [FETCH_ARTICLE_FAILURE]: state =>
    flow(set('loaded.articles', false), set('loading.articles', false))(state),
  [FETCH_ALL_ARTICLES_FAILURE]: state =>
    flow(set('loaded.articles', false), set('loading.articles', false))(state),
  [FETCH_ARTICLE_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.articles.${action.id}`, action.response.entities.articles[action.id]),
      set('loaded.articles', true),
      set('loading.articles', false)
    )(state);
  },
  [SET_ARTICLE_SUCCESS]: (state, action) =>
    flow(
      set('entities.articles', {
        ...state.entities.articles,
        ...action.response.entities.articles
      })
    )(state),
  [DELETE_ARTICLE_SUCCESS]: (state, action) =>
    flow(set('entities.articles', omit(state.entities.articles, action.id)))(state)
};

export function loadArticle(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_ARTICLE_REQUEST, FETCH_ARTICLE_SUCCESS, FETCH_ARTICLE_FAILURE],
      method: 'GET',
      endpoint: `/articles/${id}`,
      schema: Schemas.ARTICLE
    }
  };
}

export function loadArticles() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_ARTICLES_REQUEST, FETCH_ALL_ARTICLES_SUCCESS, FETCH_ALL_ARTICLES_FAILURE],
      method: 'GET',
      endpoint: '/articles',
      schema: Schemas.ARTICLE_ARRAY,
      successMessage: 'Articles chargés avec succès'
    }
  };
}

export function createOrUpdateArticle(article) {
  return {
    [CALL_API]: {
      types: [SET_ARTICLE_REQUEST, SET_ARTICLE_SUCCESS, SET_ARTICLE_FAILURE],
      method: !article || !Number.isInteger(article.article_id) ? 'POST' : 'PUT',
      endpoint:
        !article || !Number.isInteger(article.article_id)
          ? '/articles'
          : `/articles/${article.article_id}`,
      schema: Schemas.ARTICLE,
      body: article,
      successMessage: 'Votre Article a été mis à jour'
    }
  };
}

export function deleteArticle(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_ARTICLE_REQUEST, DELETE_ARTICLE_SUCCESS, DELETE_ARTICLE_FAILURE],
      method: 'DELETE',
      endpoint: `/articles/${id}`,
      successMessage: 'Articles supprimés avec succès'
    }
  };
}
