import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_FAVORITES_REQUEST = 'arwa/favorites/FETCH_ALL_FAVORITES_REQUEST';
export const FETCH_ALL_FAVORITES_SUCCESS = 'arwa/favorites/FETCH_ALL_FAVORITES_SUCCESS';
const FETCH_ALL_FAVORITES_FAILURE = 'arwa/favorites/FETCH_ALL_FAVORITES_FAILURE';
const SET_FAVORITE_REQUEST = 'arwa/favorites/SET_FAVORITE_REQUEST';
const SET_FAVORITE_SUCCESS = 'arwa/favorites/SET_FAVORITE_SUCCESS';
const SET_FAVORITE_FAILURE = 'arwa/favorites/SET_FAVORITE_FAILURE';
const DELETE_FAVORITE_REQUEST = 'arwa/favorites/DELETE_FAVORITE_REQUEST';
const DELETE_FAVORITE_SUCCESS = 'arwa/favorites/DELETE_FAVORITE_SUCCESS';
const DELETE_FAVORITE_FAILURE = 'arwa/favorites/DELETE_FAVORITE_FAILURE';
const FETCH_FAVORITE_FAILURE = 'arwa/favorites/FETCH_FAVORITE_FAILURE';
const FETCH_FAVORITE_SUCCESS = 'arwa/favorites/FETCH_FAVORITE_SUCCESS';
const FETCH_FAVORITE_REQUEST = 'arwa/favorites/FETCH_FAVORITE_REQUEST';

export const favoritesActionsHandlers = {
  [FETCH_ALL_FAVORITES_REQUEST]: state =>
    flow(set('loaded.favorites', false), set('loading.favorites', true))(state),
  [FETCH_FAVORITE_REQUEST]: state => {
    return flow(set('loaded.favorites', false), set('loading.favorites', true))(state);
  },
  [FETCH_ALL_FAVORITES_SUCCESS]: (state, action) => {
    return flow(
      set('entities.favorites', action.response.entities.favorites || {}),
      set('loaded.favorites', true),
      set('loading.favorites', false)
    )(state);
  },
  [FETCH_ALL_FAVORITES_FAILURE]: state =>
    flow(set('loaded.favorites', false), set('loading.favorites', false))(state),
  [FETCH_FAVORITE_FAILURE]: state => {
    return flow(set('loaded.favorites', false), set('loading.favorites', false))(state);
  },
  [FETCH_FAVORITE_SUCCESS]: (state, action) => {
    return flow(
      set(
        `entities.favorites.${action.id}`,
        action.response.entities.favorites[action.id]
          ? action.response.entities.favorites[action.id]
          : { folders_id: [], formations_id: [], actualities_id: [] }
      ),
      set('loaded.favorites', true),
      set('loading.favorites', false)
    )(state);
  },
  [SET_FAVORITE_SUCCESS]: (state, action) => {
    return flow(
      set('entities.favorites', {
        ...state.entities.favorites,
        ...action.response.entities.favorites
      })
    )(state);
  },
  [DELETE_FAVORITE_SUCCESS]: (state, action) =>
    flow(set('entities.favorites', omit(state.entities.favorites, action.id)))(state)
};

export function loadFavorite(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_FAVORITE_REQUEST, FETCH_FAVORITE_SUCCESS, FETCH_FAVORITE_FAILURE],
      method: 'GET',
      endpoint: `/favorites/${id}`,
      schema: Schemas.FAVORITE,
      successMessage: 'Favoris chargés avec succès'
    }
  };
}

export function loadFavorites() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_FAVORITES_REQUEST,
        FETCH_ALL_FAVORITES_SUCCESS,
        FETCH_ALL_FAVORITES_FAILURE
      ],
      method: 'GET',
      endpoint: '/favorites',
      schema: Schemas.FAVORITE_ARRAY,
      successMessage: 'Favoris chargés avec succès'
    }
  };
}

export function createOrUpdateFavorite(favorite, account_id) {
  return {
    [CALL_API]: {
      types: [SET_FAVORITE_REQUEST, SET_FAVORITE_SUCCESS, SET_FAVORITE_FAILURE],
      method: !favorite || !Number.isInteger(favorite.account_id) ? 'POST' : 'PUT',
      endpoint: `/favorites/${account_id}`,
      schema: Schemas.FAVORITE,
      body: {
        ...favorite,
        account_id: favorite.account_id ? favorite.account_id : account_id
      },
      successMessage: 'Votre favoris enregistré avec succès'
    }
  };
}

export function deleteFavorite(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_FAVORITE_REQUEST, DELETE_FAVORITE_SUCCESS, DELETE_FAVORITE_FAILURE],
      method: 'DELETE',
      endpoint: `/favorites/${id}`,
      successMessage: 'Favoris supprimés avec succès'
    }
  };
}
