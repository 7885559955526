import React from 'react';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalSuccessPreInscription = ({ openModal, closeModal }) => {
  return (
    <Modal
      className="modal-content modal-content-secondary small-modal client"
      overlayClassName="modal-overlay modal-restricted-content"
      id="modal-success"
      isOpen={openModal}
    >
      <div className="box">
        <i className="fal fa-check-circle lock" />
        <p>Votre demande de pré-inscription a été enregistrée</p>
        <div className="btn-group center">
          <button onClick={() => closeModal()} className="btn btn-primary">
            <span>Fermer</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccessPreInscription;
