import React, { Fragment } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setConfig } from '../../store/modules/globals';
import { logout } from '../../store/modules/auth';
import { IconButton } from 'react-components-linaia';
import { isAdmin, isUser, isFormateur, isElu } from '../../utils/RolesUtils';

import logoAric from '../../../assets/images/logo-aric-pwa.svg';
import logoAdmin from '../../../assets/images/logo-admin.svg';
import logoBanque from '../../../assets/images/logo-banque_territoires.png';

const Header = ({ menuIsOpen, menuIsTiny, account, setConf, doLogout }) => (
  <Fragment>
    <header className="header">
      <div className="header-logo">
        <a href="/" className="logo-block">
          {account && isAdmin(account) ? (
            <img src={logoAdmin} className="logo" />
          ) : (
            <img src={logoAric} alt="Logo ARIC" className="logo" width="160" />
          )}
        </a>

        <div className="logo-banner">
          <span>en&nbsp;partenariat&nbsp;avec</span>
          <img
            src={logoBanque}
            width="185"
            alt="Logo Banque des Territoires et Caisse des dépôts "
            className=""
          />
        </div>
      </div>
      <div className={`header-menu${isAdmin(account) || isFormateur(account) ? ' tiny' : ''}`}>
        <nav
          className={classNames(
            'main-nav',
            { 'animated opened': menuIsOpen === true },
            { 'animated closed': menuIsOpen === false }
          )}
        >
          {account && isAdmin(account) ? (
            <ul>
              <li>
                <NavLink
                  to="/admin/folders"
                  onClick={menuIsOpen === true ? () => setConf('menuIsOpen', false) : null}
                  exact
                  activeClassName="active"
                >
                  <i className="item-icon fal fa-books" />
                  <span>Dossiers</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/admin/formations"
                  onClick={menuIsOpen === true ? () => setConf('menuIsOpen', false) : null}
                  exact
                  activeClassName="active"
                >
                  <i className="item-icon fal fa-award" />
                  <span>Formations</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/admin/formulaires-evaluations"
                  onClick={menuIsOpen === true ? () => setConf('menuIsOpen', false) : null}
                  exact
                  activeClassName="active"
                >
                  <i className="item-icon fal fa-clipboard-list" />
                  <span>Formulaires</span>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  to="/admin/evaluations"
                  onClick={menuIsOpen === true ? () => setConf('menuIsOpen', false) : null}
                  exact
                  activeClassName="active"
                >
                  <i className="item-icon fal fa-check-double" />
                  <span>Evaluations</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/admin/actualities"
                  onClick={menuIsOpen === true ? () => setConf('menuIsOpen', false) : null}
                  exact
                  activeClassName="active"
                >
                  <i className="item-icon fal fa-newspaper" />
                  <span>Actualités</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/notifications"
                  onClick={menuIsOpen === true ? () => setConf('menuIsOpen', false) : null}
                  exact
                  activeClassName="active"
                >
                  <i className="item-icon fal fa-bell" />
                  <span>Notifications</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/admin/administrators"
                  onClick={menuIsOpen === true ? () => setConf('menuIsOpen', false) : null}
                  exact
                  activeClassName="active"
                >
                  <i className="item-icon fal fa-user"></i>
                  <span>Administrateurs</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/" exact activeClassName="" onClick={doLogout}>
                  <i className="item-icon far fa-user-lock" />
                  <span>Déconnexion</span>
                </NavLink>
              </li>
            </ul>
          ) : (
            <ul>
              {!isFormateur(account) && (
                <li>
                  <NavLink to="/" exact activeClassName="active">
                    <i className="item-icon far fa-home" />
                    <span>Accueil</span>
                  </NavLink>
                </li>
              )}
              {!isFormateur(account) && (
                <li>
                  <NavLink to="/search" exact activeClassName="active">
                    <i className="item-icon far fa-search" />
                    <span>Recherche</span>
                  </NavLink>
                </li>
              )}

              {/* {isFormateur(account) && (
                <Fragment>
                  <li>
                    <NavLink
                      to="/formateur/documents"
                      onClick={menuIsOpen === true ? () => setConf('menuIsOpen', false) : null}
                      exact
                      activeClassName="active"
                    >
                      <i className="item-icon fal fa-folder-open" />
                      <span>Documents</span>
                    </NavLink>
                  </li>
                </Fragment>
              )} */}

              {isUser(account) && (
                <li>
                  <NavLink to="/favorites" exact activeClassName="active">
                    <i className="item-icon far fa-heart" />
                    <span>Favoris</span>
                  </NavLink>
                </li>
              )}
              {(!account || isUser(account)) && (
                <li>
                  <NavLink to="/contact" exact activeClassName="active">
                    <i className="item-icon far fa-question" />
                    <span>Question</span>
                  </NavLink>
                </li>
              )}

              {isUser(account) && (
                <li>
                  <NavLink to="/mon-compte" exact activeClassName="active">
                    <i className="item-icon far fa-user" />
                    <span>Mon compte</span>
                  </NavLink>
                </li>
              )}
              {!account && (
                <li>
                  <NavLink to="/login" exact activeClassName="active">
                    <i className="item-icon far fa-user-lock" />
                    <span>Connexion</span>
                  </NavLink>
                </li>
              )}
              {account && isFormateur(account) && (
                <li>
                  <NavLink to="/" exact activeClassName="" onClick={doLogout}>
                    <i className="item-icon far fa-user-lock" />
                    <span>Déconnexion</span>
                  </NavLink>
                </li>
              )}
            </ul>
          )}
        </nav>
      </div>
    </header>
  </Fragment>
);

Header.propTypes = {
  menuIsOpen: PropTypes.bool,
  menuIsTiny: PropTypes.bool,
  account: PropTypes.shape({
    username: PropTypes.string
  }),

  setConf: PropTypes.func.isRequired,
  doLogout: PropTypes.func
};

export default connect(
  state => ({
    menuIsOpen: state.data.config.menuIsOpen,
    menuIsTiny: state.data.config.menuIsTiny,
    account: state.auth.user
  }),
  { setConf: setConfig, doLogout: logout }
)(Header);
