import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { map, isNull, findIndex, values, isEmpty } from 'lodash';
import { updateSession } from '../../store/modules/sessions';
import BaseLayout from '../shared/BaseLayout';
import TableView from '../../components/shared/TableView';
import moment from 'moment';
import { TagInput } from 'reactjs-tag-input';

const FormationsAdminContainer = ({
  match: { url },
  history: { push },
  baseUrl,
  sessions,
  formationsSorted,
  updateSession,

  coordonnees,
  ...props
}) => {
  const [isEditRowTags, setIsEditRowTags] = useState(null);
  const [sessionWaitUpdated, setSessionWaitUpdated] = useState(null);

  const update = values => {
    return updateSession(values).then(() => {
      setIsEditRowTags(null);
      setSessionWaitUpdated(null);
    });
  };
  const updateTags = () => {
    update(sessionWaitUpdated);
  };

  return (
    <BaseLayout>
      <div className="admin-header">
        <div className="admin-header-title">
          <h1>Formations</h1>
        </div>
        {/*  <div className="btn-group right">
          <button className="btn btn-primary" type="update" onClick={() => refreshFormations()}>
            <i className="fas fa-sync-alt"></i>
          </button>
        </div> */}
      </div>

      <TableView
        className="table-wrapper"
        data={sessions}
        sortOrderDefault={'desc'}
        sortByDefault={'date_debut'}
        cols={() => ({
          visibility: {
            label: 'Visibilité',
            className: 'center',
            render: (isVisible, row) => (
              <button
                onClick={() => {
                  return update({ ...row, visibility: !isVisible });
                }}
                className={`fas fa-${(isVisible && 'eye') || 'eye-slash'}`}
              ></button>
            )
          },
          date_debut: {
            label: 'Date',
            value: value => moment(value).format('YYYY/MM/DD'),
            render: value => moment(value).format('YYYY/MM/DD')
          },
          intitule: {
            label: 'Titre',
            className: 'cellFull',
            value: (value, row) => row.intitule,
            render: (value, row) => row.intitule
          },
          ville: {
            label: 'Ville',
            value: (value, row) =>
              (row.salles && !isEmpty(row.salles) && row.salles[0].ville) || '',
            render: (value, row) =>
              (row.salles && !isEmpty(row.salles) && row.salles[0].ville) || ''
          },
          duree: {
            label: 'Durée',
            className: 'center',
            value: (value, row) => value,
            render: (value, row) => value
          },
          tags: {
            label: 'Tags',
            render: (tags, row) => {
              return !isNull(isEditRowTags) &&
                Number(isEditRowTags) === Number(row.id_action_de_formation) ? (
                <TagInput
                  placeholder={'Ajouter tag'}
                  tags={map(tags, (t, i) => {
                    return {
                      index: String(i),
                      displayValue: t
                    };
                  })}
                  onTagsChanged={tags => {
                    setSessionWaitUpdated({ ...row, tags: map(tags, t => t.displayValue) });
                  }}
                />
              ) : (
                map(tags, (tag, i) => <span key={i}> {tag} </span>)
              );
            }
          }
        })}
        rowButtons={session => (
          <div className="btn-group right">
            {!isNull(isEditRowTags) &&
            Number(isEditRowTags) === Number(session.id_action_de_formation) ? (
              <button type="button" className="btn" onClick={() => updateTags()}>
                <i className="fas fa-check" />
              </button>
            ) : (
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setSessionWaitUpdated(null);
                  setIsEditRowTags(session.id_action_de_formation);
                }}
              >
                <i className="fas fa-pen" />
              </button>
            )}
          </div>
        )}
      />
    </BaseLayout>
  );
};

FormationsAdminContainer.propTypes = {
  updateSession: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  state => ({
    coordonnees: state.data.entities.coordonnees && values(state.data.entities.coordonnees),
    formationsSorted: null /* (state.data.entitiesSort && state.data.entitiesSort.sessions) || */,
    sessions: (state.data.entities && state.data.entities.sessions) || {}
  }),
  {
    updateSession
  }
)(withRouter(FormationsAdminContainer));
