import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_EDITOS_REQUEST = 'arwa/editos/FETCH_ALL_EDITOS_REQUEST';
export const FETCH_ALL_EDITOS_SUCCESS = 'arwa/editos/FETCH_ALL_EDITOS_SUCCESS';
const FETCH_ALL_EDITOS_FAILURE = 'arwa/editos/FETCH_ALL_EDITOS_FAILURE';
const FETCH_EDITO_REQUEST = 'arwa/editos/FETCH_EDITO_REQUEST';
const FETCH_EDITO_SUCCESS = 'arwa/editos/FETCH_EDITO_SUCCESS';
const FETCH_EDITO_FAILURE = 'arwa/editos/FETCH_EDITO_FAILURE';
const SET_EDITO_REQUEST = 'arwa/editos/SET_EDITO_REQUEST';
const SET_EDITO_SUCCESS = 'arwa/editos/SET_EDITO_SUCCESS';
const SET_EDITO_FAILURE = 'arwa/editos/SET_EDITO_FAILURE';
const DELETE_EDITO_REQUEST = 'arwa/editos/DELETE_EDITO_REQUEST';
const DELETE_EDITO_SUCCESS = 'arwa/editos/DELETE_EDITO_SUCCESS';
const DELETE_EDITO_FAILURE = 'arwa/editos/DELETE_EDITO_FAILURE';

export const editosActionsHandlers = {
  [FETCH_ALL_EDITOS_REQUEST]: state =>
    flow(set('loaded.editos', false), set('loading.editos', true))(state),
  [FETCH_ALL_EDITOS_SUCCESS]: (state, action) =>
    flow(
      set('entities.editos', action.response.entities.editos || {}),
      set('entitiesSort.editos', action.response.entitiesSort.editos || {}),
      set('loaded.editos', true),
      set('loading.editos', false),
    )(state),
  [FETCH_ALL_EDITOS_FAILURE]: state =>
    flow(set('loaded.editos', false), set('loading.editos', false))(state),
  [FETCH_EDITO_SUCCESS]: (state, action) => {
    return flow(
      set(
        `entities.editos.${action.id}`,
        action.response.entities.editos[action.id],
      ),
    )(state);
  },
  [SET_EDITO_SUCCESS]: (state, action) =>
    flow(
      set('entities.editos', {
        ...state.entities.editos,
        ...action.response.entities.editos,
      }),
    )(state),
  [DELETE_EDITO_SUCCESS]: (state, action) =>
    flow(set('entities.editos', omit(state.entities.editos, action.id)))(state),
};

export function loadEdito(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_EDITO_REQUEST, FETCH_EDITO_SUCCESS, FETCH_EDITO_FAILURE],
      method: 'GET',
      endpoint: `/editos/${id}`,
      schema: Schemas.EDITO,
    },
  };
}

export function loadEditos() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_EDITOS_REQUEST,
        FETCH_ALL_EDITOS_SUCCESS,
        FETCH_ALL_EDITOS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/editos',
      schema: Schemas.EDITO_ARRAY,
      successMessage: 'Editos chargés avec succès',
    },
  };
}

export function createOrUpdateEdito(edito) {
  return {
    [CALL_API]: {
      types: [SET_EDITO_REQUEST, SET_EDITO_SUCCESS, SET_EDITO_FAILURE],
      method: !edito || !Number.isInteger(edito.edito_id) ? 'POST' : 'PUT',
      endpoint:
        !edito || !Number.isInteger(edito.edito_id)
          ? '/editos'
          : `/editos/${edito.edito_id}`,
      schema: Schemas.EDITO,
      body: edito,
      successMessage: 'Votre Edito a été mis à jour',
    },
  };
}

export function deleteEdito(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_EDITO_REQUEST, DELETE_EDITO_SUCCESS, DELETE_EDITO_FAILURE],
      method: 'DELETE',
      endpoint: `/editos/${id}`,
      successMessage: 'Editos supprimés avec succès',
    },
  };
}
