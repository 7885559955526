import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { values } from 'lodash';

import BaseLayout from '../shared/BaseLayout';

import FolderListView from '../../components/folders/FolderListView';
import FormationsListView from '../../components/formations/FormationsListView';
import NewsListView from '../../components/actualities/NewsListView';

import { loadActualities } from '../../store/modules/actualities';
import { loadFormations } from '../../store/modules/formations';
import { loadFolders } from '../../store/modules/folders';
import { loadFavorite } from '../../store/modules/favorites';
import { loadEditos } from '../../store/modules/editos';
import { loadArticles } from '../../store/modules/articles';
import { loadAccounts } from '../../store/modules/accounts';
import { getSessionsUser, loadSessions } from '../../store/modules/sessions';
import { getEvaluationsByElu } from '../../store/modules/evaluations';
import { getInscriptionsByUser } from '../../store/modules/inscriptions';
import { loadDocuments } from '../../store/modules/documents';
import urlBase64ToUint8Array from '../../utils/urlBase64ToUint8Array';
import { API_URL, publicVapidKey } from '../../constants/Config';

const Home = ({
  account,
  folders,
  sessions,
  actualities,
  loadFolders,
  loadFormations,
  loadActualities,
  loadEditos,
  loadArticles,
  loadFavorite,
  loadAccounts,
  loadDocuments,
  loadSessions,
  getEvaluationsByElu,
  getInscriptionsByUser
}) => {
  useEffect(() => {
    async function fetchData() {
      // You can await here
      let response = await loadActualities();
      response = await loadSessions();
      response = await loadFormations();
      response = await loadDocuments();

      if (account && account.username) {
        response = await getEvaluationsByElu(account.username);
        response = await getInscriptionsByUser(account.username);
      }

      response = await loadFolders();
      response = await loadEditos();
      response = await loadArticles();

      response = await loadAccounts();
      response = account ? await loadFavorite(account.account_id) : null;
      // ...
    }

    fetchData();
  }, [account]);

  const [isSubscribed, setIsSubscribed] = useState(localStorage.getItem('isSubscribed') === 'true');
  const [errorNotif, setErrorNotif] = useState(null);
  const subscribeToNotifications = async () => {
    setErrorNotif(null);
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
        })
        .then(subscription => {
          console.log('value of subscription : ', subscription);
          console.log('API URL :', API_URL);
          // Envoyez le token d'abonnement à votre serveur
          const token = subscription.toJSON();
          console.log('Token:', token);

          // Stockez l'état de l'abonnement dans le localStorage
          localStorage.setItem('isSubscribed', 'true');
          setIsSubscribed(true);
          if (subscription) {
            fetch(`${API_URL}/notifications/subscribe`, {
              method: 'POST',
              body: JSON.stringify(subscription),
              headers: {
                'Content-Type': 'application/json'
              }
            });
          }
        });
    } catch (error) {
      console.error('Erreur lors de la souscription aux notifications:', error);
      setErrorNotif(error);
    }
  };

  return (
    <BaseLayout title="Les formations">
      <div className="box-list-header">
        <div className="box-list-title">Formations</div>
      </div>
      <FormationsListView formations={sessions} isHome={true} />
      <div className="inset-container">
        <div className="btn-group">
          <a
            className="btn btn-primary"
            target="_blank"
            href="https://www.aric.asso.fr/infos-pratiques/"
          >
            DIFe
          </a>
          <a
            className="btn btn-primary"
            target="_blank"
            href="https://www.aric.asso.fr/nos-formations/calendrier/"
          >
            Calendrier
          </a>
          <a
            className="btn btn-primary"
            target="_blank"
            href="https://www.aric.asso.fr/nos-formations/catalogue/"
          >
            Catalogue
          </a>
        </div>
      </div>
      <div className="box-list-header">
        <div className="box-list-title">Dossiers</div>
      </div>
      <FolderListView folders={folders} isHome={true} />
      <div className="box-list-header">
        <div className="box-list-title">Actualités</div>
        <div className="box-list-header-buttons">
          <a className="btn btn-primary" target="_blank" href="https://www.aric.asso.fr/actualites">
            Toutes les actualités
          </a>
        </div>
      </div>
      <NewsListView actualities={actualities} isHome={true} />
      <div>
        {!isSubscribed && (
          <button onClick={subscribeToNotifications}>
            <i className="fas fa-bell" />
          </button>
        )}
        {errorNotif && (
          <div
            className="field-error"
            dangerouslySetInnerHTML={{
              __html: String(errorNotif)
            }}
          />
        )}
      </div>
    </BaseLayout>
  );
};

export default connect(
  state => ({
    folders: values(state.data.entities.folders),
    sessions: values(state.data.entities.sessions),
    actualities: values(state.data.entities.actualities),
    editos: values(state.data.entities.editos),
    articles: values(state.data.entities.articles),
    accounts: values(state.data.entities.accounts),
    account: state.auth.user
  }),
  {
    loadActualities,
    loadFormations,
    loadSessions,
    loadFolders,
    loadArticles,
    loadEditos,
    loadFavorite,
    loadAccounts,
    loadDocuments,
    getEvaluationsByElu,
    getSessionsUser,
    getInscriptionsByUser
  }
)(Home);
