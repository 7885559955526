import { schema } from 'normalizr';

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr

const accountSchema = new schema.Entity('accounts', {}, { idAttribute: 'account_id' });
const modelSchema = new schema.Entity('models', {}, { idAttribute: 'model_id' });
const postSchema = new schema.Entity('posts', {}, { idAttribute: 'id' });
const uploadContentSchema = new schema.Entity(
  'uploadContents',
  {},
  { idAttribute: 'upload_content_id' }
);
const folderSchema = new schema.Entity('folders', {}, { idAttribute: 'folder_id' });
const formationSchema = new schema.Entity(
  'formations',
  {},
  { idAttribute: 'idFormationWebservice' }
);
const formateurSchema = new schema.Entity('formateurs', {}, { idAttribute: 'idTiers' });
const sessionSchema = new schema.Entity('sessions', {}, { idAttribute: 'id_action_de_formation' });
const actualitySchema = new schema.Entity('actualities', {}, { idAttribute: 'actuality_id' });
const favoriteSchema = new schema.Entity('favorites', {}, { idAttribute: 'account_id' });
const questionSchema = new schema.Entity('questions', {}, { idAttribute: 'question_id' });
const articleSchema = new schema.Entity('articles', {}, { idAttribute: 'article_id' });
const coordonneeSchema = new schema.Entity('coordonnees', {}, { idAttribute: 'coordonnee_id' });
const evaluationSchema = new schema.Entity('evaluations', {}, { idAttribute: 'evaluation_id' });
const documentSchema = new schema.Entity('documents', {}, { idAttribute: 'document_id' });
const editoSchema = new schema.Entity('editos', {}, { idAttribute: 'edito_id' });
const inscriptionSchema = new schema.Entity('inscriptions', {}, { idAttribute: 'inscription_id' });
const notificationSchema = new schema.Entity(
  'notifications',
  {},
  { idAttribute: 'notification_id' }
);

// Schemas for API responses.
export const Schemas = {
  ACCOUNT: accountSchema,
  ACCOUNT_ARRAY: new schema.Array(accountSchema),
  MODEL: modelSchema,
  MODEL_ARRAY: new schema.Array(modelSchema),
  POST: postSchema,
  POST_ARRAY: new schema.Array(postSchema),
  UPLOAD_CONTENT: uploadContentSchema,
  UPLOAD_CONTENT_ARRAY: new schema.Array(uploadContentSchema),
  FOLDER: folderSchema,
  FOLDER_ARRAY: new schema.Array(folderSchema),
  FORMATION: formationSchema,
  FORMATION_ARRAY: new schema.Array(formationSchema),
  EVALUATION: evaluationSchema,
  EVALUATION_ARRAY: new schema.Array(evaluationSchema),
  FORMATEUR: formateurSchema,
  FORMATEUR_ARRAY: new schema.Array(formateurSchema),
  SESSION: sessionSchema,
  SESSION_ARRAY: new schema.Array(sessionSchema),
  COORDONNEE: coordonneeSchema,
  COORDONNEE_ARRAY: new schema.Array(coordonneeSchema),
  ACTUALITY: actualitySchema,
  ACTUALITY_ARRAY: new schema.Array(actualitySchema),
  FAVORITE: favoriteSchema,
  FAVORITE_ARRAY: new schema.Array(favoriteSchema),
  QUESTION: questionSchema,
  QUESTION_ARRAY: new schema.Array(questionSchema),
  ARTICLE: articleSchema,
  ARTICLE_ARRAY: new schema.Array(articleSchema),
  EDITO: editoSchema,
  EDITO_ARRAY: new schema.Array(editoSchema),
  INSCRIPTION: inscriptionSchema,
  INSCRIPTION_ARRAY: new schema.Array(inscriptionSchema),
  DOCUMENT: documentSchema,
  DOCUMENT_ARRAY: new schema.Array(documentSchema),
  NOTIFICATION: notificationSchema,
  NOTIFICATION_ARRAY: new schema.Array(notificationSchema)
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
