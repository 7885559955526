import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { reduxForm, formValueSelector } from 'redux-form';
import { Field, InputField, WysiwygTextarea, FileField } from 'react-components-linaia';

import { UPLOADS_URL } from '../../constants/Config';
import { uploadFiles, removeFile } from '../../store/modules/uploads';

let EditoAdminViewFolder = ({ handleSubmit, upFiles, change, textContent, rmFile }) => {
  return (
    <Fragment>
      <form className="" onSubmit={handleSubmit} name="EditoAdminViewFolder">
        <div className="grid">
          <div className="col">
            <div className="grid">
              <Field name="title" component={InputField} label="Titre" className="col-12" />

              <Field
                name="content"
                component={WysiwygTextarea}
                className="col-12"
                text={textContent}
                onBlurProps={e => {
                  return change('content', e);
                }}
                options={[
                  'inline',
                  'blockType',
                  'fontSize',
                  'list',
                  'textAlign',
                  'link',
                  'history',
                  'embedded'
                ]}
              />
            </div>
          </div>
          <div className="col-2_md-5_lg-4_xl-3_xxl-2">
            <div className="box">
              <div className="grid">
                <Field
                  name="authorName"
                  component={InputField}
                  label="Nom du rédacteur"
                  className="col-12"
                />
                <Field
                  name="authorFonction"
                  component={InputField}
                  label="Fonction du rédacteur"
                  className="col-12"
                />
                <Field
                  name="authorPhoto"
                  component={FileField}
                  uploadFiles={upFiles}
                  removeFile={rmFile}
                  getUrl={item => `${UPLOADS_URL}/${item}`}
                  buttonLabel="Importer un Fichier"
                  className="col-12"
                />
                <Field
                  name="creditPhoto"
                  component={InputField}
                  label="Crédit photo"
                  className="col-12"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="btn-group right">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </Fragment>
  );
};

EditoAdminViewFolder.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape().isRequired
};

const selector = formValueSelector('EditEditoReduxForm');

export default reduxForm({
  form: 'EditEditoReduxForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.title) errors.title = "Merci de renseigner le titre de l'edito";
    if (!values.content) errors.content = "Merci de renseigner le contenu de l'edito";
    if (!values.authorName) errors.authorName = 'Merci de renseigner le nom du rédacteur';
    if (!values.authorFonction)
      errors.authorFonction = 'Merci de renseigner la fonction du rédacteur';
    return errors;
  }
})(
  connect(
    (state, { initialValues }) => ({
      textContent: selector(state, 'content') || (initialValues && initialValues.content) || ''
    }),
    {
      upFiles: uploadFiles,
      rmFile: removeFile
    }
  )(EditoAdminViewFolder)
);
