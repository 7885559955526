import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import { loadArticle, FETCH_ARTICLE_SUCCESS } from '../../store/modules/articles';
import {
  createOrUpdateFavorite,
  loadFavorites,
  FETCH_ALL_FAVORITES_SUCCESS
} from '../../store/modules/favorites';
import { isUser } from '../../utils/RolesUtils';

import { includes, isEmpty } from 'lodash';

import { API_URL } from '../../constants/Config';

const ArticleDetailView = ({
  folder_id,
  article,
  article_id,
  loadArticle,
  loadFavorites,
  account,
  createOrUpdateFavorite,
  favorites
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    async function fetchData() {
      const getArticles = await loadArticle(article_id);
      const getFavorites = await loadFavorites();
      if (
        getArticles.type === FETCH_ARTICLE_SUCCESS &&
        getFavorites.type === FETCH_ALL_FAVORITES_SUCCESS
      ) {
        setIsLoaded(true);
      }
    }
    fetchData();
  }, []);
  return (
    <div className="news news-article">
      <div className="news-header">
        <div className="news-header-wrapper">
          <div className="news-nav">
            <NavLink
              className="btn btn-secondary rounded btn-back"
              to={`/folder/${folder_id}/details`}
            >
              <i className="far fa-long-arrow-left" />
            </NavLink>
            {isUser(account) && (
              <button
                className="btn btn-secondary rounded"
                onClick={() => {
                  !favorites.folders_id
                    ? (favorites.folders_id = [Number(folder_id)])
                    : favorites.folders_id.includes(Number(folder_id))
                    ? favorites.folders_id.pop(Number(folder_id))
                    : favorites.folders_id.push(Number(folder_id));
                  return createOrUpdateFavorite(favorites, account.account_id);
                }}
              >
                {favorites && !includes(favorites.folders_id, Number(folder_id)) && (
                  <i className="far fa-heart"></i>
                )}
                {favorites && includes(favorites.folders_id, Number(folder_id)) && (
                  <i className="fas fa-heart"></i>
                )}
              </button>
            )}
          </div>
        </div>
        <div className="news-picture-wrapper">
          <div className="news-picture-mask" />
          <img src={article && API_URL + '/uploads/' + article.photoUrl} />
        </div>
      </div>
      {isLoaded && (
        <div className="news-body">
          <div className="news-wrapper">
            <div className="post-infos">
              <h1 className="news-title">{article && article.title}</h1>
            </div>
            {(article && article.authorName) ||
            article.authorPhoto ||
            !isEmpty(article.authorPhoto) ||
            article.authorFonction ? (
              <div className="news-author-wrapper">
                {article && article.authorPhoto && !isEmpty(article.authorPhoto) && (
                  <div className="news-author-picture">
                    <img src={article && API_URL + '/uploads/' + article.authorPhoto} />
                  </div>
                )}
                <div className="news-author-infos">
                  {article.authorName ? (
                    <div className="news-author-name">{article && article.authorName}</div>
                  ) : null}
                  {article.authorFonction ? (
                    <div className="news-author-function">{article && article.authorFonction}</div>
                  ) : null}
                  {article && article.creditPhoto && (
                    <div className="copyright">© {article && article.creditPhoto}</div>
                  )}
                </div>
              </div>
            ) : null}

            <div className="news-abstract">{article && article.summary}</div>
            {article && (
              <div
                className="news-content"
                dangerouslySetInnerHTML={{
                  __html: article.content
                }}
              ></div>
            )}
            <div className="news-signature">{article && article.sign}</div>
          </div>
          {article &&
            (article.telContact ||
              article.siteContact ||
              article.EmailContact ||
              article.adresseContact ||
              article.cpContact ||
              article.villeContact ||
              article.lieuContact) && (
              <div className="news-contact-infos">
                <div className="news-contact-infos-title">Contact</div>
                <div className="news-contact-infos-adress">
                  {article.lieuContact}
                  <br />
                  {article.adresseContact}
                  <br />
                  {article.cpContact} {article.villeContact}
                </div>
                <div className="news-contact-buttons">
                  {article.telContact && (
                    <a className="btn" href={article && 'tel:' + article.telContact}>
                      <i className="far fa-phone" />
                      <span>{article.telContact}</span>
                    </a>
                  )}
                  {article.EmailContact && (
                    <a className="btn" href={article && 'mailto:' + article.EmailContact}>
                      <i className="far fa-envelope" />
                      <span>{article.EmailContact}</span>
                    </a>
                  )}
                  {article.siteContact && (
                    <a className="btn" href={article && article.siteContact}>
                      <i className="far fa-globe" />
                      <span>{article.siteContact}</span>
                    </a>
                  )}
                </div>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

// warning car check quand article est undefined
// ArticleDetailView.propTypes = {
//   article: {
//     article_id: PropTypes.number.isRequired,
//     date: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//     summary: PropTypes.string.isRequired,
//     photoUrl: PropTypes.string,
//     tags: PropTypes.arrayOf(PropTypes.string),

//   },
//   baseUrl: PropTypes.string,
// };

export default connect(
  (state, { article_id, folder_id }) => {
    return {
      account: state.auth.user,
      article: state.data.entities.articles[article_id],
      favorites: state.auth.user && state.data.entities.favorites[state.auth.user.account_id]
    };
  },
  {
    loadArticle,
    loadFavorites,
    createOrUpdateFavorite
  }
)(ArticleDetailView);
