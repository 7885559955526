import { flow, set } from 'lodash/fp';

import { CALL_API } from '../Schemas';

const LOGIN_REQUEST = 'arwa/auth/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'arwa/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'arwa/auth/LOGIN_FAIL';
const LOGOUT_REQUEST = 'arwa/auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'arwa/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'arwa/auth/LOGOUT_FAIL';
const RECOVER_PASSWORD_REQUEST = 'arwa/auth/RECOVER_PASSWORD_REQUEST';
export const RECOVER_PASSWORD_SUCCESS = 'arwa/auth/RECOVER_PASSWORD_SUCCESS';
const RECOVER_PASSWORD_FAILURE = 'arwa/auth/RECOVER_PASSWORD_FAILURE';
const RESET_PASSWORD_REQUEST = 'arwa/auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'arwa/auth/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAILURE = 'arwa/auth/RESET_PASSWORD_FAILURE';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return flow(set('loggingIn', false), set('user', action.response))(state);
    case LOGIN_FAIL:
      return flow(set('loggingIn', false), set('user', null))(state);
    case LOGOUT_REQUEST:
      return flow(set('loggingOut', true))(state);
    case LOGOUT_SUCCESS:
      return flow(set('loggingOut', false), set('user', null))(state);
    case LOGOUT_FAIL:
      return flow(set('loggingOut', false))(state);
    default:
      return state;
  }
}

export function login(email, password, extranet_code) {
  return {
    [CALL_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL],
      method: 'POST',
      endpoint: '/auth/login',
      body: {
        email,
        password,
        extranet_code
      }
    }
  };
}

export function logout() {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function recoverPassword(email) {
  return {
    [CALL_API]: {
      types: [RECOVER_PASSWORD_REQUEST, RECOVER_PASSWORD_SUCCESS, RECOVER_PASSWORD_FAILURE],
      method: 'PUT',
      endpoint: '/auth/password',
      body: {
        email
      }
    }
  };
}

export function resetPassword(accountId, resetToken, password) {
  return {
    [CALL_API]: {
      types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
      method: 'PUT',
      endpoint: '/auth/reset-password',
      body: {
        accountId,
        resetToken,
        password
      }
    }
  };
}
