import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { createOrUpdateActuality, refreshActualities } from '../../store/modules/actualities';

import BaseLayout from '../shared/BaseLayout';

import TableListView from '../../components/admin/TableListView';

const ActualitiesAdminContainer = ({
  match: { url },
  history: { push },
  baseUrl,
  actualities,
  actualitiesSorted,
  createOrUpdateActuality,
  refreshActualities,
  loadingActualities,
  ...props
}) => {
  return (
    <BaseLayout>
      <div className="admin-header">
        <div className="admin-header-title">
          <h1>Actualités</h1>
        </div>
        <div className="btn-group right">
          <button className="btn btn-primary" type="update" onClick={() => refreshActualities()}>
            <i className="fas fa-sync-alt"></i>
          </button>
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table-container">
          {!loadingActualities && !isUndefined(actualities) && (
            <TableListView
              data={actualities}
              sorted={actualitiesSorted}
              nameDetails="actuality"
              name="actualities"
              tabs={['Visibilité', 'Date', 'Titre', 'Tags', 'Buttons']}
              edit={data => createOrUpdateActuality(data)}
              isButtonEditTagAndNotif
            />
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

ActualitiesAdminContainer.propTypes = {
  actualities: PropTypes.objectOf(PropTypes.shape()).isRequired,
  baseUrl: PropTypes.string.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default connect(
  state => {
    return {
      actualitiesSorted: (state.data.entitiesSort && state.data.entitiesSort.actualities) || [],
      actualities: (state.data.entities && state.data.entities.actualities) || {},
      loadingActualities: state.data.loading.actualities
    };
  },
  {
    createOrUpdateActuality,
    refreshActualities
  }
)(withRouter(ActualitiesAdminContainer));
