import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

import { TABLEAU_CORRES } from '../../constants/Properties';

import { connect } from 'react-redux';
import { createOrUpdateFormation } from '../../store/modules/formations';
import { createOrUpdateFolder } from '../../store/modules/folders';
import { createOrUpdateActuality } from '../../store/modules/actualities';

import { map, keyBy, flatMap, fromPairs, includes } from 'lodash';
import { ButtonConfirmModal } from 'react-components-linaia';
import { TagInput } from 'reactjs-tag-input';

import moment from 'moment';

const TableListView = ({
  match: { url },
  data,
  sorted,
  name,
  deleteArticle,
  deleteFolder,
  deleteAccount,
  tabs,
  edit,
  nameDetails,
  isButtonEditTag,
  isButtonEditTagAndNotif,
  isButtonEditoArticle,
  isButtonDeleteAdmin,
  isButtonEditDeleteArticle
}) => {
  const [editTag, onClickEdit] = useState(
    keyBy(
      map(data, (obj, indexObj) => ({
        formation_id: obj[nameDetails + '_id'],
        isEditing: false
      })),
      'formation_id'
    )
  );
  //Hooks permettant de stocker les formation_id par id. Permet de crée un objet par KEY. Map sur toutes les data et sors les Formations_id
  const [tags, setTags] = useState(
    includes(tabs, 'Tags')
      ? fromPairs(
          map(data, (obj, indexObj) => [
            obj[nameDetails + '_id'],
            map(obj[TABLEAU_CORRES[name]['Tags']], (obj, indexObj) => ({
              index: indexObj,
              displayValue: obj
            }))
          ])
        )
      : null
  );
  // Hooks permettant de map et return an object composed from keys. Permet de remplacer le tableau de string en tableau d'objet

  const onChangeTags = (tagsToChange, idTag) => {
    setTags({
      ...tags,
      [idTag]: tagsToChange
    });
  };

  const onValidateTags = obj => {
    if (!editTag[obj[nameDetails + '_id']].isEditing) {
      return onClickEdit({
        ...editTag,
        [obj[nameDetails + '_id']]: {
          ...editTag[obj[nameDetails + '_id']],
          isEditing: !editTag[obj[nameDetails + '_id']].isEditing
        }
      });
    } else {
      Promise.resolve()
        .then(() =>
          edit({
            ...obj,
            tags: flatMap(tags[obj[nameDetails + '_id']], objTag => objTag.displayValue)
          })
        )
        .then(() =>
          onClickEdit({
            ...editTag,
            [obj[nameDetails + '_id']]: {
              ...editTag[obj[nameDetails + '_id']],
              isEditing: !editTag[obj[nameDetails + '_id']].isEditing
            }
          })
        );
    }
  };

  return (
    <table className="table">
      <thead>
        <tr>
          {map(tabs, (nameTab, indexTab) => (
            <th className={nameTab === 'Visibilité' ? 'center visibility' : ''} key={indexTab}>
              {nameTab === 'Buttons' ? '' : nameTab}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {map(sorted || data, (objProps, indexObjProps) => {
          let obj = {};
          let indexObj = {};
          if (sorted) {
            obj = data[objProps];
            indexObj = objProps;
          } else {
            obj = objProps;
            indexObj = indexObjProps;
          }
          return (
            <tr key={indexObj}>
              {map(tabs, (nameTab, indexTab) => {
                return (
                  <Fragment key={indexTab.toString()}>
                    {nameTab === 'Visibilité' && (
                      <td className="center">
                        {nameTab === 'Visibilité' &&
                          (obj[TABLEAU_CORRES[name]['Visibilité']] ? (
                            <button
                              className="fas fa-eye"
                              onClick={() =>
                                edit({
                                  ...obj,
                                  [TABLEAU_CORRES[name]['Visibilité']]: !obj[
                                    TABLEAU_CORRES[name]['Visibilité']
                                  ]
                                })
                              }
                            />
                          ) : (
                            <button
                              className="fas fa-eye-slash"
                              onClick={() =>
                                edit({
                                  ...obj,
                                  [TABLEAU_CORRES[name]['Visibilité']]: !obj[
                                    TABLEAU_CORRES[name]['Visibilité']
                                  ]
                                })
                              }
                            />
                          ))}
                      </td>
                    )}
                    {/* TODO CHECK IF ROLES === ADMIN*/}

                    {(nameTab === 'Identifiant' || nameTab === 'Prénom' || nameTab === 'Nom') && (
                      <td key={indexTab}>{obj[TABLEAU_CORRES[name][nameTab]]}</td>
                    )}
                    {nameTab === 'Date' ? (
                      name === 'folders' ? (
                        <td key={indexTab} className="capitalize">
                          {moment(obj[TABLEAU_CORRES[name]['Date']]).format('MMM YYYY')}
                          &nbsp;/&nbsp;
                          {moment(obj[TABLEAU_CORRES[name]['DateFin']]).format('MMM YYYY')}
                        </td>
                      ) : (
                        <td key={indexTab}>
                          {moment(obj[TABLEAU_CORRES[name][nameTab]]).format('DD/MM/YYYY')}
                        </td>
                      )
                    ) : null}

                    {(nameTab === 'Titre' ||
                      nameTab === 'Ville' ||
                      nameTab === 'Durée' ||
                      nameTab === "Nom de l'élu.e") && (
                      <td key={indexTab}>{obj[TABLEAU_CORRES[name][nameTab]]}</td>
                    )}

                    {nameTab === 'Tags' && (
                      <Fragment>
                        {editTag[obj[nameDetails + '_id']] &&
                        editTag[obj[nameDetails + '_id']].isEditing &&
                        editTag[obj[nameDetails + '_id']].isEditing === true ? (
                          <td>
                            <TagInput
                              placeholder={'Ajouter tag'}
                              tags={tags[indexObj]}
                              onTagsChanged={t => {
                                return onChangeTags(t, obj[nameDetails + '_id']);
                              }}
                            />
                          </td>
                        ) : (
                          <td className="tabs-list" key={indexTab}>
                            {map(obj[TABLEAU_CORRES[name][nameTab]], tag => (
                              <span>{tag}</span>
                            ))}
                          </td>
                        )}
                      </Fragment>
                    )}

                    {nameTab === 'Réservé' && (
                      <td className="reserved">
                        {nameTab === 'Réservé' &&
                          (obj[TABLEAU_CORRES[name]['Réservé']] ? 'Oui' : 'Non')}
                      </td>
                    )}

                    {nameTab === 'Buttons' && (
                      <Fragment>
                        {/* isButtonEditTag  Permet de savoir si l'on est dans une ACTU / formation/ article au lieu de Folder qui contient isButtonEditoArticle*/}
                        {isButtonEditTag && (
                          <td className="onebtn">
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn"
                                onClick={() => onValidateTags(obj)}
                              >
                                <i
                                  className={`fas fa-${
                                    editTag[obj[nameDetails + '_id']] &&
                                    editTag[obj[nameDetails + '_id']].isEditing
                                      ? 'check'
                                      : 'pen'
                                  }`}
                                />
                              </button>
                            </div>
                          </td>
                        )}

                        {/*-----------------------------------------------------------------------------_*/}

                        {isButtonEditTagAndNotif && (
                          <td className="onebtn">
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn"
                                onClick={() => onValidateTags(obj)}
                              >
                                <i
                                  className={`fas fa-${
                                    editTag[obj[nameDetails + '_id']] &&
                                    editTag[obj[nameDetails + '_id']].isEditing
                                      ? 'check'
                                      : 'pen'
                                  }`}
                                />
                              </button>
                            </div>
                          </td>
                        )}

                        {/*-----------------------------------------------------------------------------_*/}

                        {isButtonDeleteAdmin && (
                          <td className="twobtn">
                            <div className="btn-group">
                              <NavLink
                                className="btn"
                                to={{
                                  pathname: `${url}/${obj[nameDetails + '_id']}/details`,
                                  foldersProps: {
                                    name: 'Details'
                                  }
                                }}
                              >
                                <i className="fas fa-pen"></i>
                              </NavLink>
                              <ButtonConfirmModal
                                onConfirmationButton={() => deleteAccount(obj[nameDetails + '_id'])}
                                message="Voulez-vous vraiment supprimer cet administrateur ?"
                              >
                                <button className="btn" type="button">
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </ButtonConfirmModal>
                            </div>
                          </td>
                        )}

                        {/*-----------------------------------------------------------------------------_*/}

                        {/* ARTICLES PAGE */}
                        {isButtonEditDeleteArticle && (
                          <td className="twobtn">
                            <div className="btn-group">
                              <NavLink
                                className="btn"
                                to={{
                                  pathname: `${url}/${obj[nameDetails + '_id']}/details`,
                                  foldersProps: {
                                    name: 'Details'
                                  }
                                }}
                              >
                                <i className="fas fa-pen"></i>
                              </NavLink>

                              <ButtonConfirmModal
                                onConfirmationButton={() => deleteArticle(obj[nameDetails + '_id'])}
                                message="Voulez-vous vraiment supprimer cet article ?"
                              >
                                <button className="btn" type="button">
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </ButtonConfirmModal>
                            </div>
                          </td>
                        )}
                        {/* isButtonEditoArticle  Permet de savoir si l'on est dans un folder au lieu de formation isButtonEditTag*/}
                        {isButtonEditoArticle && (
                          <td>
                            <div className="btn-group right">
                              <NavLink
                                className="btn"
                                to={{
                                  pathname: `/admin/folders/${obj[nameDetails + '_id']}/editos`,
                                  foldersProps: {
                                    name: 'Edito'
                                  }
                                }}
                              >
                                Edito
                              </NavLink>

                              <NavLink
                                className="btn"
                                to={{
                                  pathname: `/admin/folders/${obj[nameDetails + '_id']}/articles`,
                                  foldersProps: {
                                    name: 'Articles'
                                  }
                                }}
                              >
                                Articles
                              </NavLink>
                              {/*-----------------------------------------------------------------------------_*/}

                              <NavLink
                                className="btn"
                                to={{
                                  pathname: `/admin/folders/${obj[nameDetails + '_id']}/details`,
                                  foldersProps: {
                                    name: 'Details'
                                  }
                                }}
                              >
                                <i className="fas fa-pen"></i>
                              </NavLink>
                              {/*-----------------------------------------------------------------------------_*/}

                              <ButtonConfirmModal
                                onConfirmationButton={() => deleteFolder(obj[nameDetails + '_id'])}
                                message="Voulez-vous vraiment supprimer ce dossier ?"
                              >
                                <button className="btn" type="button">
                                  <i className="fas fa-trash-alt" />
                                </button>
                              </ButtonConfirmModal>
                            </div>
                          </td>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

TableListView.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()), //.isRequired,
  sorted: PropTypes.arrayOf(PropTypes.number),
  name: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.string),
  baseUrl: PropTypes.string,
  edit: PropTypes.function,
  sortOrderDefault: PropTypes.string,
  sortByDefault: PropTypes.string,
  deleteFolder: PropTypes.function,
  deleteArticle: PropTypes.function,
  deleteAccount: PropTypes.function
};

export default connect(null, {
  createOrUpdateFormation,
  createOrUpdateFolder,
  createOrUpdateActuality
})(withRouter(TableListView));
