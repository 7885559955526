import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { NavLink, withRouter } from 'react-router-dom';

import { head, values, filter, parseInt } from 'lodash';
import { connect } from 'react-redux';

import BaseLayout from '../../containers/shared/BaseLayout';

import EditoAdminViewFolder from '../editos/EditoAdminViewFolder';
import ArticleAdminViewFolder from '../articles/ArticleAdminViewFolder';
import DetailAdminViewFolder from './DetailAdminViewFolder';

import { createOrUpdateFolder } from '../../store/modules/folders';
import { createOrUpdateEdito } from '../../store/modules/editos';
import { createOrUpdateArticle } from '../../store/modules/articles';

const BONavLink = ({ url, title, setRef }) => (
  <li ref={ref => setRef(ref)}>
    <NavLink to={url} exact activeClassName="active">
      <span>{title}</span>
    </NavLink>
  </li>
);

BONavLink.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setRef: PropTypes.func.isRequired
};

let FolderAdminEditView = ({
  name,
  articles,
  history: { push },
  match: { url },
  editos,
  folder,
  baseUrl,
  createOrUpdateFolder,
  createOrUpdateEdito
}) => {
  const [tabValue, setTabValue] = useState(0);

  const [nav, setNavref] = useState();
  const [details, setDetailsRef] = useState();
  const [editoss, setEditosRef] = useState();
  const [articless, setArticlesRef] = useState();

  const [showEditArticle, setEditArticle] = useState(false);
  const toggleEdit = () => {
    return setEditArticle(!showEditArticle);
  };

  useEffect(() => {
    switch (name) {
      case 'Details':
        setTabValue(0);
        break;
      case 'Edito':
        setTabValue(1);
        break;
      case 'Articles':
        setTabValue(2);
        break;
      default:
        setTabValue(0);
    }
  }, [name]);

  return (
    <BaseLayout title="Dossier">
      <div className="admin-header">
        <div className="admin-header-title">
          <NavLink
            className="btn"
            to={{
              pathname: `/admin/folders`,
              foldersProps: {
                name: 'Back'
              }
            }}
          >
            <i className="far fa-chevron-left"></i>
          </NavLink>
          <h1>
            Dossier
            <span>{folder && folder.title}</span>
          </h1>
        </div>
      </div>

      <div className="admin-submenu-wrapper">
        <nav ref={ref => setNavref(ref)} className="admin-submenu">
          {folder && folder.folder_id && (
            <ul>
              <BONavLink
                setRef={setDetailsRef}
                url={`/admin/folders/${folder && folder.folder_id}/details`}
                title="Détails"
              />
              <BONavLink
                setRef={setEditosRef}
                url={`/admin/folders/${folder && folder.folder_id}/editos`}
                title="Edito"
              />
              <BONavLink
                setRef={setArticlesRef}
                url={`/admin/folders/${folder && folder.folder_id}/articles`}
                title="Articles"
              />
            </ul>
          )}
        </nav>
        {tabValue === 2 && (
          <div>
            <button className="btn btn-main-mobile" onClick={() => push(`${url}/new`)}>
              Créer un article
            </button>
          </div>
        )}
      </div>

      {/*    CASE DETAILS_FOLDER */}
      {tabValue === 0 && (
        <DetailAdminViewFolder
          initialValues={folder}
          onSubmit={v => {
            return createOrUpdateFolder(v).then(res => {
              push(`${baseUrl}/${values(res.response.entities.folders)[0].folder_id}/editos`);
            });
          }}
        />
      )}

      {/*    CASE EDITO */}
      {tabValue === 1 && (
        <EditoAdminViewFolder
          initialValues={editos || (folder && { folder_id: folder.folder_id })}
          onSubmit={v => {
            return createOrUpdateEdito(v);
          }}
        />
      )}

      {/*    CASE ARTICLES */}
      {tabValue === 2 && (
        <Fragment>
          <ArticleAdminViewFolder articles={articles} toggleEdit={() => toggleEdit()} />
        </Fragment>
      )}
    </BaseLayout>
  );
};

FolderAdminEditView.propTypes = {
  initialValues: PropTypes.shape(),
  baseUrl: PropTypes.string.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  }).isRequired,
  folder: PropTypes.shape(),
  name: PropTypes.string,
  editos: PropTypes.arrayOf(),
  articles: PropTypes.arrayOf()
};

FolderAdminEditView = connect(
  (state, props) => {
    return {
      editos: head(
        filter(
          state.data.entities.editos,
          edito => edito.folder_id === parseInt([props.folders_id])
        )
      ),
      articles: filter(
        state.data.entities.articles,
        article => article.folder_id === parseInt([props.folders_id])
      ),
      folder: state.data.entities.folders[props.folders_id]
    };
  },
  {
    createOrUpdateFolder,
    createOrUpdateEdito,
    createOrUpdateArticle
  }
)(FolderAdminEditView);

export default withRouter(FolderAdminEditView);
