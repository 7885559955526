import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { map } from 'lodash';
import { uploadFiles, removeFile } from '../../store/modules/uploads';

import { reduxForm, getFormValues } from 'redux-form';
import {
  Field,
  InputField,
  TextareaField,
  ToggleField,
  FileField,
  DateField
} from 'react-components-linaia';

import { UPLOADS_URL } from '../../constants/Config';
import CustomArea from './customArea';

let DetailAdminViewFolder = ({ handleSubmit, upFiles, rmFile, values, change }) => {
  const onChangeFormTags = tagsToChange => {
    change(
      'tags',
      map(tagsToChange, obj => obj.displayValue)
    );
  };
  let tagsParent = values && values.tags;
  return (
    <Fragment>
      <form
        className=""
        onSubmit={handleSubmit}
        name="DetailAdminViewFolder"
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <div className="grid">
          <div className="col">
            <div className="grid">
              <div className="col-12">
                <div className="grid">
                  <Field name="title" component={InputField} label="Titre" className="col_md-12" />
                  <Field
                    name="dateDebut"
                    component={DateField}
                    selectMonthYear
                    label="Mois début"
                    className="col-2_xs-12_md-6_lg-3"
                    displayFormat="MM/yyyy"
                  />
                  <Field
                    name="dateFin"
                    component={DateField}
                    selectMonthYear
                    label="Mois fin"
                    className="col-2_xs-12_md-6_lg-3"
                    displayFormat="MM/yyyy"
                  />
                </div>
              </div>
              <Field
                name="summary"
                component={TextareaField}
                label="Chapô"
                className="col-12"
                fieldClassName="textarea"
              />
              <Field
                name="tags"
                component={CustomArea}
                tagsParent={tagsParent}
                onChangeFormTags={onChangeFormTags}
                className="col-12"
                label="Tags"
              />
              <Field
                name="restricted"
                component={ToggleField}
                type="checkbox"
                className="col-12"
                label="Réservé aux abonnés"
              />
            </div>
          </div>
          <div className="col-2_md-5_lg-4_xl-3_xxl-2">
            <div className="box">
              <div className="grid">
                <Field
                  name="photoUrl"
                  component={FileField}
                  uploadFiles={upFiles}
                  removeFile={rmFile}
                  getUrl={item => `${UPLOADS_URL}/${item}`}
                  buttonLabel="Choisir une Image"
                  label="Photo à la une"
                  className="col-12"
                />
                <Field
                  name="creditPhoto"
                  component={InputField}
                  buttonLabel=""
                  className="col-12"
                  label="Crédit Photo"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="btn-group right">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </Fragment>
  );
};

DetailAdminViewFolder.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape().isRequired,
  folders: PropTypes.objectOf(PropTypes.shape()).isRequired
  //isNew: PropTypes.bool,
};

export default reduxForm({
  form: 'DetailAdminViewFolder',
  validate: (values = {}) => {
    const errors = {};

    if (!values.title) errors.title = 'Merci de renseigner le Titre du dossier';
    if (!values.dateDebut) errors.dateDebut = 'Merci de renseigner la date du début du dossier';
    if (!values.dateFin) errors.dateFin = 'Merci de renseigner la date de fin du dossier';
    if (!values.photoUrl) errors.photoUrl = 'Merci de renseigner une photo';
    if (!values.summary) errors.summary = 'Merci de renseigner le Résumé du dossier';
    /*if (!values.photoUrl)
      errors.photoUrl = 'Merci de renseigner la Photo du dossier';*/

    return errors;
  }
})(
  connect(
    (state, props) => {
      return {
        values: props.initialValues || getFormValues('DetailAdminViewFolder')(state)
      };
    },
    {
      upFiles: uploadFiles,
      rmFile: removeFile
    }
  )(DetailAdminViewFolder)
);
