import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

import { connect } from 'react-redux';
import { createOrUpdateFavorite } from '../../store/modules/favorites';

import { map, includes, findIndex, values, sortBy, isEmpty, filter, reverse } from 'lodash';

import { isUser } from '../../utils/RolesUtils';
import moment from 'moment';

const FormationsListView = ({
  formations,
  account,
  createOrUpdateFavorite,
  favorites,
  isHome,
  cordonnees
}) => {
  const sortedSessions = sortBy(formations, 'date_debut');
  return (
    <Swiper
      slidesPerView={2}
      spaceBetween={10}
      freeMode={true}
      navigation
      modules={[FreeMode, Navigation]}
      className="mySwiper"
      breakpoints={{
        576: {
          width: 576,
          slidesPerView: 2
        },
        1024: {
          width: 1024,
          slidesPerView: 3
        }
      }}
    >
      {map(sortedSessions, (session, sessionId) => {
        return (
          (!isHome || session.visibility) && (
            <SwiperSlide key={sessionId}>
              <NavLink
                className="box box-news"
                to={`/session/${session.id_action_de_formation}/details`}
              >
                <div className="box-news-picture">
                  <img src={(session && session.modules[0].visuel_url) || ''} />
                </div>
                <div className="box-content">
                  <div className="box-news-actions">
                    {/* plus de favoris ???? 
                    {isUser(account) && (
                      <div
                        className="btn btn-secondary rounded"
                        onClick={() => {
                          !favorites.formations_id
                            ? (favorites.formations_id = [session.session_id])
                            : favorites.formations_id.includes(session.session_id)
                            ? favorites.formations_id.pop(session.session_id)
                            : favorites.formations_id.push(session.session_id);
                          return createOrUpdateFavorite(favorites, account.account_id);
                        }}
                      >
                        {!favorites ||
                          (!includes(favorites.formations_id, session.session_id) && (
                            <i className="far fa-heart"></i>
                          ))}
                        {favorites && includes(favorites.formations_id, session.session_id) && (
                          <i className="fas fa-heart"></i>
                        )}
                      </div>
                    )} */}
                    <div className="btn btn-secondary duration">
                      <span>
                        {` ${session.duree} ${(session && session.duree > 1 && 'jours') || 'jour'}`}
                      </span>
                    </div>
                  </div>
                  <div className="box-news-date">
                    {moment(session.date_debut).format('ddd DD MMM Y')}
                  </div>
                  <a href={session.modules[0].visuel_url}>
                    <div className="box-news-title">
                      {session && session.formation && session.modules[0].intitule}
                    </div>
                  </a>
                </div>
                <div className="box-subContent">
                  {session && session.salles && session.salles[0] && (
                    <div className="formation-info">
                      <i className="fa-light fa-map-marker-alt" />
                      {session.salles[0].ville}
                    </div>
                  )}

                  {!isEmpty(session.tags) ? (
                    <div className="news-tags-list">
                      {map(session.tags, (tag, key) => (
                        <NavLink key={key} className="news-tags" to={`/search/${tag}`}>
                          {tag}
                        </NavLink>
                      ))}
                    </div>
                  ) : null}
                </div>
              </NavLink>
            </SwiperSlide>
          )
        );
      })}
    </Swiper>
  );
};

FormationsListView.propTypes = {
  formations: PropTypes.arrayOf(
    PropTypes.shape({
      formation_id: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string,
      duration: PropTypes.number.isRequired,
      photoUrl: PropTypes.string.isRequired,
      city: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string)
    })
  )
};

// export default FormationsListView;
export default connect(
  state => ({
    account: state.auth.user,
    cordonnees: state.data.entities.coordonnees && values(state.data.entities.coordonnees),
    favorites: state.auth.user && state.data.entities.favorites[state.auth.user.account_id]
  }),
  { createOrUpdateFavorite }
)(FormationsListView);
