import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { deleteAccount } from '../../store/modules/accounts';
import { createOrUpdateAccount } from '../../store/modules/accounts';

import { withRouter } from 'react-router-dom';

import BaseLayout from '../shared/BaseLayout';

import TableListView from '../../components/admin/TableListView';

const AdministratorsAdminContainer = ({
  match: { url },
  history: { push },
  baseUrl,
  deleteAccount,
  createOrUpdateAccount,
  accounts,
  ...props
}) => (
  <BaseLayout>
    <div className="admin-header">
      <div className="admin-header-title">
        <h1>Administrateurs</h1>
      </div>
      <div className="admin-header-buttons">
        <button className="btn" onClick={() => push(`${url}/new`)}>
          Créer un Administrateur
        </button>
      </div>
    </div>
    <div className="table-wrapper">
      <div className="table-container">
        <TableListView
          data={accounts}
          name="accounts"
          nameDetails="account"
          tabs={['Identifiant', 'Prénom', 'Nom', 'Buttons']}
          isButtonDeleteAdmin
          deleteAccount={data => deleteAccount(data)}
        />
      </div>
    </div>
  </BaseLayout>
);

AdministratorsAdminContainer.propTypes = {
  accounts: PropTypes.objectOf(PropTypes.shape()), //.isRequired,
  baseUrl: PropTypes.string.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default connect(
  state => ({
    accounts: state.data.entities.accounts
  }),
  {
    deleteAccount,
    createOrUpdateAccount
  }
)(withRouter(AdministratorsAdminContainer));
