import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import { includes, filter, map } from 'lodash';

const ModalSuccessEvaluateSession = ({ openModal, closeModal, formationsComplementaires }) => {
  const navigation = useHistory();

  const close = () => {
    closeModal();
    navigation.push('/mon-compte');
  };

  return (
    <Modal
      className="modal-content modal-content-secondary small-modal client"
      overlayClassName="modal-overlay modal-restricted-content"
      id="modal-success-evaluate"
      isOpen={openModal}
    >
      <div className="box">
        <i className="fal fa-check-circle lock" />
        <p>Merci d'avoir participé à cette évaluation</p>

        {formationsComplementaires && formationsComplementaires.length > 0 && (
          <div>
            <p>Si vous souhaitez aller plus loin vous pourriez être intéressé par :</p>

            {map(formationsComplementaires, (f, i) => (
              <a href={f.url} target="_blank" className="btn btn-primary btn-formation" key={i}>
                {f.titre}
              </a>
            ))}
          </div>
        )}

        <div className="btn-group center">
          <button
            onClick={() => {
              close();
            }}
            className="btn btn-primary"
          >
            <span>Fermer</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalSuccessEvaluateSession.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  const formationsComplementairesIds =
    (props.session && props.session.formation && props.session.formation.formationComplementaire) ||
    [];

  const formationsComplementaires = filter(state.data.entities.formations, f =>
    includes(formationsComplementairesIds, f.idFormation)
  );

  return {
    formationsComplementaires: formationsComplementaires || []
  };
};

export default connect(mapStateToProps, {})(ModalSuccessEvaluateSession);
