import { flow, set } from 'lodash/fp';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_COORDONNEES_REQUEST = 'arwa/COORDONNEES/FETCH_ALL_COORDONNEES_REQUEST';
const FETCH_ALL_COORDONNEES_SUCCESS = 'arwa/COORDONNEES/FETCH_ALL_COORDONNEES_SUCCESS';
const FETCH_ALL_COORDONNEES_FAILURE = 'arwa/COORDONNEES/FETCH_ALL_COORDONNEES_FAILURE';

export const coordonneesActionsHandlers = {
  [FETCH_ALL_COORDONNEES_REQUEST]: state =>
    flow(set('loaded.coordonnees', false), set('loading.coordonnees', true))(state),
  [FETCH_ALL_COORDONNEES_SUCCESS]: (state, action) => {
    return flow(
      set('entities.coordonnees', action.response.entities.coordonnees || {}),
      set('entitiesSort.coordonnees', action.response.entitiesSort.coordonnees || {}),
      set('loaded.coordonnees', true),
      set('loading.coordonnees', false)
    )(state);
  },
  [FETCH_ALL_COORDONNEES_FAILURE]: state =>
    flow(set('loaded.coordonnees', false), set('loading.coordonnees', false))(state)
};

export function loadCoordonnees() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_COORDONNEES_REQUEST,
        FETCH_ALL_COORDONNEES_SUCCESS,
        FETCH_ALL_COORDONNEES_FAILURE
      ],
      method: 'GET',
      endpoint: '/coordonnees',
      schema: Schemas.COORDONNEE_ARRAY,
      successMessage: 'Coordonnees chargées avec succès'
    }
  };
}
