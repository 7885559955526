import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { deleteArticle } from '../../store/modules/articles';
import createOrUpdateArticle from '../../store/modules/articles';

import TableListView from '../admin/TableListView';

const ArticleAdminViewFolder = ({
  match: { url },
  history: { push },
  deleteArticle,
  articles,
  toggleEdit
}) => {
  return (
    <Fragment title="Dossier">
      <div className="table-wrapper">
        {articles && (
          <TableListView
            data={articles}
            nameDetails="article"
            name="articles"
            tabs={['Titre', "Nom de l'élu.e", 'Buttons']}
            deleteArticle={data => deleteArticle(data)}
            isButtonEditDeleteArticle
            onEditArticle={toggleEdit}
            showEditArticle
          />
        )}
      </div>
    </Fragment>
  );
};

ArticleAdminViewFolder.PropTypes = {
  articles: PropTypes.objectOf(PropTypes.shape()).isRequired,
  folders: PropTypes.objectOf(PropTypes.shape()).isRequired,
  initialValues: PropTypes.shape(),

  toggleEdit: PropTypes.func,
  showEditArticle: PropTypes.bool
};

export default connect(state => ({}), {
  deleteArticle,
  createOrUpdateArticle
})(withRouter(ArticleAdminViewFolder));
