import React from 'react';
import { reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { createQuestion } from '../../store/modules/questions';
import { Field, InputField, TextareaField } from 'react-components-linaia';
import BaseLayout from '../../containers/shared/BaseLayout';

export const submit = (values, dispatch, props) => {
  dispatch(reset('QuestionForm'));
  props.createQuestion(props.values).then(() => {
    // do other success stuff
  });
};

const QuestionForm = ({ handleSubmit }) => {
  return (
    <BaseLayout title="Vos questions" classScreen="page-contact">
      <h1 className="page-title">Contactez-nous</h1>
      <form className="contact-form" onSubmit={handleSubmit(submit)} name="questionForm">
        <div className="grid">
          <Field
            name="name"
            component={InputField}
            placeholder="Nom / Prénom"
            label="Nom / Prénom"
            className="col-12"
          />
          <Field
            name="email"
            component={InputField}
            placeholder="Email"
            label="Email"
            className="col-12"
          />
          <Field
            name="collectivite"
            component={InputField}
            placeholder="Collectivité"
            label="Collectivité"
            className="col-12"
          />
          <Field
            name="message"
            component={TextareaField}
            placeholder="Message"
            label="Message"
            className="col-12"
          />
        </div>

        <div className="btn-group center">
          <button className="btn" type="submit">
            Envoyer
          </button>
        </div>
      </form>
      <div className="bloc-evaluer">
        <h2>Vous avez participé à une formation de l&apos;Aric ?</h2>
        <p>Merci de bien vouloir remplir le questionnaire d&apos;évaluation</p>
        <a
          className="btn btn-primary"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdyadKbSnCxJ6rfI0TjyIr4rurAplBYujkAqL1T45975KLEEQ/viewform?usp=sf_link"
        >
          Évaluez la formation
        </a>
      </div>
    </BaseLayout>
  );
};

export default connect(state => ({}), { createQuestion })(
  reduxForm({
    form: 'QuestionForm',
    validate: (values = {}) => {
      const errors = {};

      if (!values.name) errors.name = 'Champ requis';
      if (!values.collectivite) errors.description = 'Champ requis';
      if (!values.message) errors.date = 'Champ requis';

      return errors;
    }
  })(QuestionForm)
);
