import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { login } from '../../store/modules/auth';
import { get } from 'lodash';
import LoginModalForm from '../shared/LoginModalForm';

export function requiredRoleElu(Component) {
  const [requiredLogin, setRequiredLogin] = useState(false);

  const requiredEluComponent = props => {
    useEffect(() => {
      checkRole();
    }, []);

    useEffect(() => {
      checkRole();
    }, [props.location]);

    const checkRole = () => {
      if (
        !props.user &&
        get(props, `folders[${get(props, 'match.params.folderId', 0)}].restricted`, false)
      ) {
        setRequiredLogin(true);
      }
    };

    const submitLogin = values => {
      props.login(values.email, values.password).then(() => {
        setRequiredLogin(false);
      });
    };

    const closeModal = () => {
      props.history.push('/');
      setRequiredLogin(false);
    };
    return (
      <Fragment>
        <LoginModalForm
          isOpen={requiredLogin}
          onSubmit={v => submitLogin(v)}
          closeModal={() => closeModal()}
        />

        <Component {...props} />
      </Fragment>
    );
  };

  const mapStateToProps = state => ({
    user: state.auth.user,
    folders: state.data.entities.folders
  });

  return connect(mapStateToProps, { login })(requiredEluComponent);
}
