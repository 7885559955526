import { split, join, tail, isString, map, initial, last, compact, isEmpty } from 'lodash';

function transformStringEtoileToHtml(string) {
  if (isString(string) && split(string, '[*]').length === 1) {
    // on a finis de traiter les [list], faut faire la meme pour [*]
    return string;
  }
  if (isString(string) && split(string, '[*]').length > 1) {
    return join(
      map(compact(split(string, '[*]')), s => '<li>' + transformStringToHtml(s) + '</li>'),
      ''
    );
  }
  return string;
}

export function transformStringToHtml(string) {
  let workingString = string;
  if (isString(workingString)) {
    workingString = workingString.replace(/(\r\n|\n|\r)/gm, '');
  }

  let stringCutWithList = null;
  stringCutWithList = split(workingString, '[list]');
  if (isString(workingString) && stringCutWithList && stringCutWithList.length === 1) {
    // on a finis de traiter les [list], faut faire la meme pour [*]

    // return stringCutWithList;
    return transformStringEtoileToHtml(workingString);
  }
  if (isString(workingString) && stringCutWithList && stringCutWithList.length > 1) {
    return (
      transformStringToHtml(stringCutWithList[0]) +
      '<ul>' +
      transformStringToHtml(
        // on regroupe en ajoutant '[/list]' tous les element sauf le dernier element du 'split.join.tail'
        join(initial(split(join(tail(stringCutWithList), '[list]'), '[/list]')), '[/list]')
      ) +
      '</ul>' +
      transformStringToHtml(last(split(join(tail(stringCutWithList), '[list]'), '[/list]')))
    );
  }

  return workingString;
}
