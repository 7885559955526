import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getAllEvaluations } from '../../store/modules/evaluations';
import { loadSessions } from '../../store/modules/sessions';
import { loadFormations } from '../../store/modules/formations';
import { loadFormateurs } from '../../store/modules/formateurs';

import BaseLayout from '../shared/BaseLayout';

import TableView from '../../components/shared/TableView';
import Select from 'react-select';

import { CSVLink } from 'react-csv';

import { map, filter, union, flatMapDeep } from 'lodash';
import moment from 'moment';

const EvaluationsContainer = ({
  evaluations,
  getAllEvaluations,
  loadSessions,
  loadFormations,
  loadFormateurs,
  sessions,
  formateurs,
  formations
}) => {
  const [formationSelected, setFormationSelected] = useState({ label: 'Tous', value: 'tous' });
  const [sessionSelected, setSessionSelected] = useState({ label: 'Tous', value: 'tous' });

  const [evaluationsFilted, setEvaluationsFilted] = useState(evaluations);

  useEffect(() => {
    loadFormations();
    getAllEvaluations();
    loadSessions();
    loadFormateurs();
  }, []);

  const onChangeFormation = formation => {
    if (formation.value === 'tous') {
      setFormationSelected(formation);
      setEvaluationsFilted(evaluations);
    } else {
      setFormationSelected(formation);
      setEvaluationsFilted(filter(evaluations, e => formation.value === e.idFormationWebservice));
    }
  };

  const onChangeSession = session => {
    if (session.value === 'tous') {
      setSessionSelected(session);
      setEvaluationsFilted(
        filter(evaluations, e => e.idFormationWebservice === formationSelected.value)
      );
    } else {
      setSessionSelected(session);
      setEvaluationsFilted(filter(evaluations, s => s.idSessionWebservice === session.value));
    }
  };

  const headerCSV = [
    { label: 'Identifiant de l’élu', key: 'identifiantElu' },
    { label: 'Nom', key: 'nom' },
    { label: 'Prénom', key: 'prenom' },
    { label: 'Date/heure de réponse', key: 'created_at' },
    { label: 'Nom de la formation', key: 'nomFormation' },
    { label: 'Date session', key: 'dateSession' },
    { label: 'Ville', key: 'ville' },
    { label: 'Formateurs', key: 'formateurs' }
  ];

  const reponsesEvaluation =
    evaluationsFilted && evaluationsFilted[0] && evaluationsFilted[0].reponses;

  reponsesEvaluation &&
    map(reponsesEvaluation, r => {
      return headerCSV.push({
        label: r.label,
        key: r.label.replace(/\s/g, '')
      });
    });

  const optionsFormations = union(
    map(formations, f => {
      return {
        label: f.titre,
        value: f.idFormationWebservice
      };
    }),
    [{ value: 'tous', label: 'Tous' }]
  );

  const optionsSessions = union(
    map(
      filter(sessions, s => s.idFormationWebservice === formationSelected.value),
      session => {
        return {
          label: session.dateDebut,
          value: session.idSessionWebservice
        };
      }
    ),
    [{ value: 'tous', label: 'Tous' }]
  );

  const csvReponses = flatMapDeep(reponsesEvaluation, r => {
    return {
      [r.label.replace(/\s/g, '')]: r.reponses
    };
  });

  let dataCSV =
    evaluationsFilted &&
    map(evaluationsFilted, e => ({
      identifiantElu: e.identifiantElu,
      nom: e.nom,
      prenom: e.prenom,
      created_at: moment(e.created_at).format('DD/MM/YYYY HH:mm'),
      nomFormation: e.nomFormation,
      dateSession: e.dateSession,
      ville: e.ville,
      formateurs: map(e.formateurs, idTiers => {
        const formateur = formateurs[idTiers] || null;
        return `${formateur && formateur.prenom} ${formateur && formateur.nom}`;
      }),
      ...Object.assign({}, ...csvReponses)
    }));

  return (
    <BaseLayout>
      <div className="admin-header">
        <div className="admin-header-title">
          <h1>Évaluations</h1>
        </div>
      </div>

      <TableView
        className="table-wrapper"
        data={
          formationSelected && formationSelected.value === 'tous' ? evaluations : evaluationsFilted
        }
        sortByDefault={'created_at'}
        search={['identifiantElu', 'nomFormation', 'ville', 'prenom', 'nom']}
        searchLabel={'Rechercher'}
        filters={
          <div className="grid-noBottom full-width">
            <div className="col-8">
              <Select
                isSearchable={true}
                placeholder="Séléctionner une formation"
                onChange={formation => {
                  onChangeFormation(formation);
                }}
                value={formationSelected}
                noOptionsMessage={() => 'Aucunne formations'}
                className="react-select"
                classNamePrefix="select"
                options={optionsFormations}
              />
            </div>
            {formationSelected && formationSelected.value !== 'tous' && (
              <div className="col-4">
                <Select
                  isSearchable={true}
                  placeholder="Séléctionner une session"
                  onChange={session => {
                    onChangeSession(session);
                  }}
                  value={sessionSelected}
                  noOptionsMessage={() => 'Aucunne sessions'}
                  className="react-select"
                  classNamePrefix="select"
                  options={optionsSessions}
                />
              </div>
            )}
          </div>
        }
        buttons={data => {
          return (
            formationSelected.value !== 'tous' &&
            evaluationsFilted.length > 0 && (
              <CSVLink
                className="btn btn-primary"
                data={dataCSV}
                filename={`evaluations_${formationSelected.label.replace(/\s/g, '_')}.csv`}
                separator=";"
                headers={headerCSV}
              >
                <i className="fas fa-plus-circle" />
                <span>Exporter les données en CSV</span>
              </CSVLink>
            )
          );
        }}
        cols={() => ({
          identifiantElu: {
            label: 'Identifiant de l’élu'
          },
          nom: {
            label: 'Nom'
          },
          prenom: {
            label: 'Prénom'
          },
          created_at: {
            label: 'Date/heure de réponse',
            render: value => moment(value).format('DD/MM/YYYY HH:mm')
          },
          nomFormation: {
            label: 'Nom de la formation'
          },
          dateSession: {
            label: 'Date session'
          },
          ville: {
            label: 'Ville'
          },
          formateurs: {
            label: 'Formateur(s)',
            render: formateursIds =>
              map(formateursIds, idTiers => {
                const formateur = formateurs[idTiers];

                return `${(formateur && formateur.prenom) || ''} ${(formateur && formateur.nom) ||
                  ''}`;
              })
          }
        })}
      />
    </BaseLayout>
  );
};

EvaluationsContainer.propTypes = {
  getAllEvaluations: PropTypes.func.isRequired,
  loadSessions: PropTypes.func.isRequired,
  loadFormations: PropTypes.func.isRequired,
  formateurs: PropTypes.object.isRequired,
  loadFormateurs: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  sessions: state.data.entities.sessions && state.data.entities.sessions,
  formations: state.data.entities.formations && state.data.entities.formations,
  formateurs: state.data.entities.formateurs && state.data.entities.formateurs,
  evaluations: state.data.entities && state.data.entities.evaluations
});

export default connect(mapStateToProps, {
  getAllEvaluations,
  loadFormateurs,
  loadSessions,
  loadFormations
})(EvaluationsContainer);
