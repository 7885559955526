import React, { Fragment, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import { createOrUpdateFavorite } from '../../store/modules/favorites';
import { loadFolder } from '../../store/modules/folders';

import { map, includes, orderBy } from 'lodash';

import { isUser } from '../../utils/RolesUtils';
import moment from 'moment';
import { API_URL } from '../../constants/Config';

var folder_id = null;

const FolderDetailView = ({
  folder,
  folderId,
  loadFolder,
  account,
  createOrUpdateFavorite,
  favorites
}) => {
  folder_id = folderId;
  useEffect(() => {
    async function fetchData() {
      const response = await [loadFolder(folderId)];
    }
    fetchData();
  }, []);

  return (
    <div className="news news-folder">
      <div className="news-header">
        <div className="news-header-wrapper">
          <div className="news-nav">
            <NavLink className="btn btn-secondary rounded btn-back" to={`/`}>
              <i className="far fa-long-arrow-left" />
            </NavLink>
            {isUser(account) && (
              <button
                className="btn btn-secondary rounded"
                onClick={() => {
                  !favorites.folders_id
                    ? (favorites.folders_id = [Number(folder_id)])
                    : favorites.folders_id.includes(Number(folder_id))
                    ? favorites.folders_id.pop(Number(folder_id))
                    : favorites.folders_id.push(Number(folder_id));
                  return createOrUpdateFavorite(favorites, account.account_id);
                }}
              >
                {!favorites ||
                  (!includes(favorites.folders_id, Number(folder_id)) && (
                    <i className="far fa-heart"></i>
                  ))}
                {favorites && includes(favorites.folders_id, Number(folder_id)) && (
                  <i className="fas fa-heart"></i>
                )}
              </button>
            )}
          </div>
        </div>
        <div className="news-picture-wrapper">
          <div className="news-picture-mask" />
          <img src={folder && API_URL + '/uploads/' + folder.photoUrl} />
        </div>
      </div>
      <div className="news-body">
        <div className="news-wrapper">
          <div className="post-infos">
            <div className="flexbox justifyBetween alignCenter mb-14">
              <div className="news-type">Dossier</div>
              <div className="post-date capitalize">
                {folder && moment(folder.dateDebut).format('MMM')}&nbsp;/&nbsp;
                {folder && moment(folder.dateFin).format('MMM YYYY')}
              </div>
            </div>
            <h1 className="news-title">{folder && folder.title}</h1>
          </div>
        </div>
        {folder && folder.edito ? (
          <Fragment>
            <NavLink
              className="news-edito-btn"
              to={`/folder/${Number(folder_id)}/edito/${Number(folder.edito.edito_id)}/details`}
            >
              {folder.edito.authorPhoto ? (
                <div className="news-edito-btn-portrait">
                  <img src={folder && API_URL + '/uploads/' + folder.edito.authorPhoto} />
                </div>
              ) : null}
              <div className="news-edito-btn-text">
                <div className="news-edito-btn-text-title">
                  <span>L'Éditorial</span> de <span>{folder.edito.authorName}</span>
                </div>
                <div className="news-edito-btn-text-subtitle">{folder.edito.authorFonction}</div>
              </div>
            </NavLink>
            {folder.edito.creditPhoto ? (
              <div className="copyright">Crédit photo - {folder.edito.creditPhoto}</div>
            ) : null}
          </Fragment>
        ) : null}
        {folder && folder.tags && (
          <div className="news-tags-list">
            {folder &&
              map(folder.tags, (tag, key) => (
                <NavLink key={key} className="news-tags" to={`/search/${tag}`}>
                  {tag}
                </NavLink>
              ))}
          </div>
        )}

        {folder && folder.articles && folder.articles.length > 0 && (
          <div className="articles-list">
            {folder &&
              folder.articles &&
              map(orderBy(folder.articles, ['createdAt'], ['asc']), (article, key) =>
                folder && folder.restricted && !account ? (
                  <div>
                    {article.photoUrl && (
                      <span className="article-link-picture">
                        <img src={folder && API_URL + '/uploads/' + article.photoUrl} />
                      </span>
                    )}
                    <div>
                      <div className="article-link-title">{article.title}</div>
                      <div className="article-link-author">
                        {article.authorName}
                        {article.authorFonction && <Fragment> - {article.authorFonction}</Fragment>}
                      </div>
                    </div>
                  </div>
                ) : (
                  <NavLink
                    key={article.article_id}
                    className="article-link"
                    to={`/folder/${Number(folder_id)}/article/${article.article_id}/details`}
                  >
                    {article.photoUrl && (
                      <span className="article-link-picture">
                        <img src={folder && API_URL + '/uploads/' + article.photoUrl} />
                      </span>
                    )}
                    <div>
                      <div className="article-link-title">{article.title}</div>
                      <div className="article-link-author">
                        {article.authorName}
                        {article.authorFonction && <Fragment> - {article.authorFonction}</Fragment>}
                      </div>
                    </div>
                  </NavLink>
                )
              )}
          </div>
        )}
      </div>
    </div>
  );
};

// warning car check quand folder est undefined
// FolderDetailView.propTypes = {
//   folder: {
//     folder_id: PropTypes.number.isRequired,
//     date: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//     summary: PropTypes.string.isRequired,
//     photoUrl: PropTypes.string,
//     tags: PropTypes.arrayOf(PropTypes.string),

//   },
//   baseUrl: PropTypes.string,
// };

export default connect(
  state => ({
    account: state.auth.user,
    favorites: state.auth.user && state.data.entities.favorites[state.auth.user.account_id],
    folder: state.data.entities.folders[folder_id]
  }),
  {
    loadFolder,
    createOrUpdateFavorite
  }
)(FolderDetailView);
