import { isEmpty } from 'lodash';
import { ACCOUNTS_ROLES } from '../constants/Properties';

export function isAdmin(userAccount, uniqRole = false) {
  return (
    userAccount &&
    !isEmpty(userAccount) &&
    (!uniqRole || (userAccount.roles && userAccount.roles.length === 1)) &&
    userAccount.roles &&
    userAccount.roles.includes(ACCOUNTS_ROLES.ADMIN)
  );
}

export function isUser(userAccount, uniqRole = false) {
  if (userAccount && !isEmpty(userAccount)) {
    return true;
  }
  return (
    userAccount &&
    !isEmpty(userAccount) &&
    (!uniqRole || (userAccount.roles && userAccount.roles.length === 1)) &&
    ((userAccount.roles && userAccount.roles.includes(ACCOUNTS_ROLES.USER)) ||
      (userAccount.roles && userAccount.roles.includes(ACCOUNTS_ROLES.ELU)))
  );
}
export function isFormateur(userAccount, uniqRole = false) {
  return (
    userAccount &&
    !isEmpty(userAccount) &&
    (!uniqRole || (userAccount.roles && userAccount.roles.length === 1)) &&
    userAccount.roles &&
    userAccount.roles.includes(ACCOUNTS_ROLES.FORMATEUR)
  );
}
export function isElu(userAccount, uniqRole = false) {
  return (
    userAccount &&
    !isEmpty(userAccount) &&
    (!uniqRole || (userAccount.roles && userAccount.roles.length === 1)) &&
    userAccount.roles &&
    userAccount.roles.includes(ACCOUNTS_ROLES.ELU)
  );
}
export function canDoSomething(userAccount) {
  return isAdmin(userAccount) || isUser(userAccount);
}
