import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_ACTUALITIES_REQUEST = 'arwa/actualities/FETCH_ALL_ACTUALITIES_REQUEST';
export const FETCH_ALL_ACTUALITIES_SUCCESS = 'arwa/actualities/FETCH_ALL_ACTUALITIES_SUCCESS';
const FETCH_ALL_ACTUALITIES_FAILURE = 'arwa/actualities/FETCH_ALL_ACTUALITIES_FAILURE';

const REFRESH_ALL_ACTUALITIES_REQUEST = 'arwa/actualities/REFRESH_ALL_ACTUALITIES_REQUEST';
export const REFRESH_ALL_ACTUALITIES_SUCCESS = 'arwa/actualities/REFRESH_ALL_ACTUALITIES_SUCCESS';
const REFRESH_ALL_ACTUALITIES_FAILURE = 'arwa/actualities/REFRESH_ALL_ACTUALITIES_FAILURE';

const FETCH_ACTUALITY_REQUEST = 'arwa/actualities/FETCH_ACTUALITY_REQUEST';
const FETCH_ACTUALITY_SUCCESS = 'arwa/actualities/FETCH_ACTUALITY_SUCCESS';
const FETCH_ACTUALITY_FAILURE = 'arwa/actualities/FETCH_ACTUALITY_FAILURE';
const SET_ACTUALITY_REQUEST = 'arwa/actualities/SET_ACTUALITY_REQUEST';
const SET_ACTUALITY_SUCCESS = 'arwa/actualities/SET_ACTUALITY_SUCCESS';
const SET_ACTUALITY_FAILURE = 'arwa/actualities/SET_ACTUALITY_FAILURE';
const DELETE_ACTUALITY_REQUEST = 'arwa/actualities/DELETE_ACTUALITY_REQUEST';
const DELETE_ACTUALITY_SUCCESS = 'arwa/actualities/DELETE_ACTUALITY_SUCCESS';
const DELETE_ACTUALITY_FAILURE = 'arwa/actualities/DELETE_ACTUALITY_FAILURE';

export const actualitiesActionsHandlers = {
  [REFRESH_ALL_ACTUALITIES_REQUEST]: state =>
    flow(set('loaded.actualities', false), set('loading.actualities', true))(state),
  [REFRESH_ALL_ACTUALITIES_SUCCESS]: (state, action) => {
    return flow(
      set('entities.actualities', action.response.entities.actualities || {}),
      set('entitiesSort.actualities', action.response.entitiesSort.actualities || {}),
      set('loaded.actualities', true),
      set('loading.actualities', false)
    )(state);
  },
  [REFRESH_ALL_ACTUALITIES_FAILURE]: state =>
    flow(set('loaded.actualities', false), set('loading.actualities', false))(state),
  [FETCH_ALL_ACTUALITIES_REQUEST]: state =>
    flow(set('loaded.actualities', false), set('loading.actualities', true))(state),
  [FETCH_ACTUALITY_REQUEST]: state => {
    return flow(set('loaded.actualities', false), set('loading.actualities', true))(state);
  },
  [FETCH_ALL_ACTUALITIES_SUCCESS]: (state, action) => {
    return flow(
      set('entities.actualities', action.response.entities.actualities || {}),
      set('entitiesSort.actualities', action.response.entitiesSort.actualities || {}),
      set('loaded.actualities', true),
      set('loading.actualities', false)
    )(state);
  },
  [FETCH_ALL_ACTUALITIES_FAILURE]: state =>
    flow(set('loaded.actualities', false), set('loading.actualities', false))(state),
  [FETCH_ACTUALITY_FAILURE]: state =>
    flow(set('loaded.actualities', false), set('loading.actualities', false))(state),
  [FETCH_ACTUALITY_SUCCESS]: (state, action) => {
    return flow(set(`entities.actualities.${action.id}`, action.response.entities.actualities))(
      state
    );
  },
  [SET_ACTUALITY_SUCCESS]: (state, action) =>
    flow(
      set('entities.actualities', {
        ...state.entities.actualities,
        ...action.response.entities.actualities
      })
    )(state),
  [DELETE_ACTUALITY_SUCCESS]: (state, action) =>
    flow(set('entities.actualities', omit(state.entities.actualities, action.id)))(state)
};

export function loadActuality(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_ACTUALITY_REQUEST, FETCH_ACTUALITY_SUCCESS, FETCH_ACTUALITY_FAILURE],
      method: 'GET',
      endpoint: `/actualities/${id}`,
      schema: Schemas.ACTUALITY,
      successMessage: 'Actualités chargés avec succès'
    }
  };
}

export function loadActualities() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_ACTUALITIES_REQUEST,
        FETCH_ALL_ACTUALITIES_SUCCESS,
        FETCH_ALL_ACTUALITIES_FAILURE
      ],
      method: 'GET',
      endpoint: '/actualities',
      schema: Schemas.ACTUALITY_ARRAY,
      successMessage: 'Actualités chargés avec succès'
    }
  };
}

export function refreshActualities() {
  return {
    [CALL_API]: {
      types: [
        REFRESH_ALL_ACTUALITIES_REQUEST,
        REFRESH_ALL_ACTUALITIES_SUCCESS,
        REFRESH_ALL_ACTUALITIES_FAILURE
      ],
      method: 'GET',
      endpoint: '/actualities/refresh',
      schema: Schemas.ACTUALITY_ARRAY,
      successMessage: 'Actualités actualisées avec succès',
      successNext: [() => loadActualities()]
    }
  };
}

export function createOrUpdateActuality(actuality) {
  return {
    [CALL_API]: {
      types: [SET_ACTUALITY_REQUEST, SET_ACTUALITY_SUCCESS, SET_ACTUALITY_FAILURE],
      method: !actuality || !Number.isInteger(actuality.actuality_id) ? 'POST' : 'PUT',
      endpoint:
        !actuality || !Number.isInteger(actuality.actuality_id)
          ? '/actualities'
          : `/actualities/${actuality.actuality_id}`,
      schema: Schemas.ACTUALITY,
      body: actuality,
      successMessage: 'Votre Actualité enregistrée avec succès'
    }
  };
}

export function deleteActuality(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_ACTUALITY_REQUEST, DELETE_ACTUALITY_SUCCESS, DELETE_ACTUALITY_FAILURE],
      method: 'DELETE',
      endpoint: `/actualities/${id}`,
      successMessage: 'Actualités supprimées avec succès'
    }
  };
}
