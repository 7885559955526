import React from 'react';

import { connect } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';
import { isNull, filter } from 'lodash';

import BaseLayout from '../shared/BaseLayout';

import TableView from '../../components/shared/TableView';

const FormulairesEvaluationsContainer = ({ formations }) => {
  return (
    <BaseLayout>
      <div className="admin-header">
        <div className="admin-header-title">
          <h1>Formulaires d'évaluation</h1>
        </div>
        <div className="admin-header-buttons">
          <Link
            className="btn"
            to={{
              pathname: `/admin/formulaire-evaluation`,
              state: { mode: 'creation' }
            }}
          >
            <i className="fas fa-plus-circle" />
            <span>Ajouter un formulaire</span>
          </Link>
        </div>
      </div>

      <TableView
        className="table-wrapper"
        data={formations}
        sortByDefault={'titre'}
        cols={() => ({
          titre: {
            label: 'Formation'
          }
        })}
        rowButtons={formation => (
          <div className="btn-group right">
            <Link
              className="btn"
              to={{
                pathname: `/admin/formulaire-evaluation`,
                state: { formation: formation, mode: 'edition' }
              }}
            >
              <i className="fas fa-pen" />
            </Link>
          </div>
        )}
      />
    </BaseLayout>
  );
};

export default connect(
  state => ({
    formations:
      state.data.entities &&
      filter(state.data.entities.formations, f => !isNull(f.formulaire_evaluation))
  }),
  {}
)(withRouter(FormulairesEvaluationsContainer));
