import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { includes, map, filter } from 'lodash';

import TimeRangeCalculator from '../../utils/TimeRangeCalculator';
import { login } from '../../store/modules/auth';

const CardFormation = ({
  type,
  session,
  formations,
  evaluations,
  account,
  formationsComplementaires,
  documents,
  login
}) => {
  const isEvaluate = includes(
    map(evaluations, e => e.idFormationWebservice),
    session.idFormationWebservice
  );
  const formation = session && session.modules && session.modules[0];

  const isFinishSessionAndAfter7Days =
    moment().isSameOrAfter(moment(session.date_fin).startOf('day')) &&
    moment().isSameOrBefore(
      moment(session.date_fin)
        .add(7, 'days')
        .endOf('day')
    );

  return (
    <div className="box box-news">
      <NavLink to={`/session/${session.id_action_de_formation}/details`}>
        <div className="box-content">
          <div className="formation-info">
            <i className="fa-light fa-calendar-alt" />
            {(session && session.duree > 1 && (
              <Fragment>
                <span>du&nbsp;</span>
                {moment(session.date_debut).format('ddd DD MMM YYYY')}
                <span>&nbsp;au&nbsp;</span>
                {moment(session.date_fin).format('ddd DD MMM YYYY')}
              </Fragment>
            )) ||
              moment(session.date_debut).format('ddd DD MMM YYYY')}
          </div>
          <div className="box-news-title">{formation && formation.intitule}</div>
        </div>
      </NavLink>
      <div className="box-subContent">
        {/* TODO JULES idSessionWebservice a enlever */}
        <div className="btn-group center">
          {session &&
            session.documents &&
            session.documents.length > 0 &&
            TimeRangeCalculator(session.date_debut, session.date_fin, 5, 7) && (
              <HashLink
                to={`/session/${Number(
                  session.id_action_de_formation
                )}/details#hash-link-to-documents`}
              >
                <button type="button" className="btn btn-primary">
                  Documents
                </button>
              </HashLink>
            )}

          {type && type === 'futur' && isFinishSessionAndAfter7Days && (
            <button
              className="btn btn-secondary"
              onClick={() => {
                return login(null, null, {
                  value: account.extranet_code.replace(/-/g, ''),
                  formattedValue: account.extranet_code
                }).then(res => {
                  window.open(
                    `https://aric.asso.fr/nos-formations/formation/${session.modules[0].id_module}/?${res.response.catalogue_public_signature}`,
                    '_blank'
                  );
                });
              }}
            >
              Evaluer la formation
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const formationsComplementairesIds =
    (props.session && props.session.formation && props.session.formation.formationComplementaire) ||
    [];

  const formationsComplementaires = filter(state.data.entities.formations, f =>
    includes(formationsComplementairesIds, f.idFormation)
  );

  return {
    formations: state.data.entities.formations,
    evaluations: state.data.entities.evaluations,
    formationsComplementaires: formationsComplementaires || [],
    documents: state.data.entities.documents,
    account: state.auth.user
  };
};

export default connect(mapStateToProps, { login })(CardFormation);
