import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import mime from 'mime';

import { useDropzone } from 'react-dropzone';
import { isEmpty, map } from 'lodash';
import { API_URL } from '../../constants/Config';

const FieldUploadDropZone = props => {
  const [files, setFiles] = useState([]);

  const [fileInit, setFileInit] = useState(props.initFile && props.initFile);

  function fileSizeValidator(file) {
    if (file.size > 10000000) {
      return {
        code: 'file-too-large',
        message: `Votre fichier ${file.path} ne doit pas dépasser la taille de 10Mo`
      };
    }

    return null;
  }
  const fileType = mime.getType(fileInit);

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedFiles,
    validator: fileSizeValidator,
    multiple: false,
    noDrag: true,
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      setFiles(files);
      if (props.onChange) {
        props.onChange(files);
      }
    }
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) =>
    errors.map(e => (
      <div key={e.code} className="field-error">
        {e.message}
      </div>
    ))
  );

  const removeFile = file => () => {
    props.onChange(null);
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const removeFileExist = file => {
    props.onChange(null);
    props.rmFile(file);
    setFileInit(null);
    setFiles(null);
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files && files.length > 0 && files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Fragment>
      {isEmpty(files) && !fileInit ? (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <i className="fal fa-cloud-upload" />
          <span>Choisir un fichier</span>
          {fileRejectionItems}
        </div>
      ) : fileInit ? (
        <div className="uploaded-file-block">
          <div className="uploaded-file">
            {fileType === 'application/pdf' && (
              <Fragment>
                <i className="fas fa-file-pdf" />
                <span>{fileInit}</span>
              </Fragment>
            )}

            {(fileType === 'application/doc' ||
              fileType === 'application/msword' ||
              fileType === 'application/docx' ||
              fileType ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
              fileType === 'application/vnd.oasis.opendocument.text') && (
              <Fragment>
                <i className="far fa-file-word" />
                <span>{fileInit}</span>
              </Fragment>
            )}

            {(fileType === 'application/xls' ||
              fileType === 'application/vnd.ms-excel' ||
              fileType === 'application/xlsx' ||
              fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
              fileType === 'application/vnd.oasis.opendocument.spreadsheet') && (
              <Fragment>
                <i className="far fa-file-excel" />
                <span>{fileInit}</span>
              </Fragment>
            )}

            {(fileType === 'application/ppt' ||
              fileType === 'application/vnd.ms-powerpoint' ||
              fileType === 'application/pptx' ||
              fileType ===
                'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
              fileType === 'application/vnd.oasis.opendocument.presentation') && (
              <Fragment>
                <i className="far fa-file-powerpoint" />
                <span>{fileInit}</span>
              </Fragment>
            )}
          </div>
          <button type="button" className="btn" onClick={() => removeFileExist(fileInit)}>
            <i className={`${props.type != 'pdf' ? ' far fa-times' : ' fas fa-trash-alt'}`} />
          </button>
        </div>
      ) : (
        <aside>
          {map(files, file => (
            <div className="uploaded-file-block" key={file.name}>
              <div className={`uploaded-file${file.type != 'pdf' ? ' img' : ''}`}>
                {file.type === 'application/pdf' && (
                  <Fragment>
                    <i className="fas fa-file-pdf" />
                    <span>{file.path}</span>
                  </Fragment>
                )}

                {(file.type === 'application/msword' ||
                  file.type ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  file.type === 'application/vnd.oasis.opendocument.text') && (
                  <Fragment>
                    <i className="far fa-file-word" />
                    <span>{file.path}</span>
                  </Fragment>
                )}
                {(file.type === 'application/vnd.ms-excel' ||
                  file.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  file.type === 'application/vnd.oasis.opendocument.spreadsheet') && (
                  <Fragment>
                    <i className="far fa-file-excel" />
                    <span>{file.path}</span>
                  </Fragment>
                )}
                {(file.type === 'application/vnd.ms-powerpoint' ||
                  file.type ===
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  file.type === 'application/vnd.oasis.opendocument.presentation') && (
                  <Fragment>
                    <i className="far fa-file-powerpoint" />
                    <span>{file.path}</span>
                  </Fragment>
                )}
              </div>
              <button type="button" className="btn" onClick={removeFile(file)}>
                <i className={`${props.type != 'pdf' ? ' far fa-times' : ' fas fa-trash-alt'}`} />
              </button>
            </div>
          ))}
        </aside>
      )}
    </Fragment>
  );
};

FieldUploadDropZone.propTypes = {
  acceptedFiles: PropTypes.string.isRequired
};

export default FieldUploadDropZone;
