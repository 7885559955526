import React, { Fragment, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

import { connect } from 'react-redux';
import { createOrUpdateFavorite } from '../../store/modules/favorites';

import { map, includes } from 'lodash';

import { isUser } from '../../utils/RolesUtils';

import moment from 'moment';

const ActualitiesListView = ({
  actualities,
  account,
  createOrUpdateFavorite,
  favorites,
  isHome
}) => {
  const [sortActualities, setSortActualities] = useState(actualities);
  useEffect(() => {
    actualities.sort((f1, f2) => new Date(f1.date).getTime() - new Date(f2.date).getTime());
    return setSortActualities(actualities.reverse());
  });

  return (
    <Swiper
      slidesPerView={2}
      spaceBetween={10}
      freeMode={true}
      navigation
      modules={[FreeMode, Navigation]}
      className="mySwiper"
      breakpoints={{
        576: {
          width: 576,
          slidesPerView: 2
        },
        1024: {
          width: 1024,
          slidesPerView: 3
        }
      }}
    >
      {map(
        sortActualities,
        (actuality, actualityId) =>
          (!isHome || actuality.visibility) && (
            <SwiperSlide key={actualityId}>
              {actuality && (
                <div className="box box-news">
                  <a className="box-news-picture" href={actuality.url}>
                    <img src={actuality.photoUrl} />
                  </a>
                  <div className="box-content">
                    {isUser(account) && (
                      <div className="box-news-actions">
                        <div
                          className="btn btn-secondary rounded"
                          onClick={() => {
                            !favorites.actualities_id
                              ? (favorites.actualities_id = [actuality.actuality_id])
                              : favorites.actualities_id.includes(actuality.actuality_id)
                              ? favorites.actualities_id.pop(actuality.actuality_id)
                              : favorites.actualities_id.push(actuality.actuality_id);
                            return createOrUpdateFavorite(favorites, account.account_id);
                          }}
                        >
                          {!favorites ||
                            (!includes(favorites.actualities_id, actuality.actuality_id) && (
                              <i className="far fa-heart"></i>
                            ))}
                          {favorites &&
                            includes(favorites.actualities_id, actuality.actuality_id) && (
                              <i className="fas fa-heart"></i>
                            )}
                        </div>
                      </div>
                    )}
                    <div className="box-news-date">{moment(actuality.date).format('DD MMMM')}</div>
                    <a className="box-news-title" href={actuality.url}>
                      {actuality.title}
                    </a>
                    <div className="news-tags-list">
                      {map(actuality.tags, (tag, key) => (
                        <NavLink key={key} className="news-tags" to={`/search/${tag}`}>
                          {tag}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </SwiperSlide>
          )
      )}
    </Swiper>
  );
};

ActualitiesListView.propTypes = {
  actualities: PropTypes.arrayOf(
    PropTypes.shape({
      actuality_id: PropTypes.number.isRequired,
      type: PropTypes.string,
      date: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      photoUrl: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string)
    })
  )
};

export default connect(
  state => ({
    account: state.auth.user,
    favorites: state.auth.user && state.data.entities.favorites[state.auth.user.account_id]
  }),
  { createOrUpdateFavorite }
)(ActualitiesListView);
