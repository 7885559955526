import React, { useEffect } from 'react';

import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import { loadEdito } from '../../store/modules/editos';
import { loadFolder } from '../../store/modules/folders';
import { createOrUpdateFavorite } from '../../store/modules/favorites';
import { includes, isEmpty } from 'lodash';

import { isUser } from '../../utils/RolesUtils';

import { API_URL } from '../../constants/Config';

const EditoDetailView = ({
  edito,
  edito_id,
  folder_id,
  loadEdito,
  account,
  createOrUpdateFavorite,
  favorites,
  folder
}) => {
  useEffect(() => {
    async function fetchData() {
      var response = await [loadEdito(edito_id)];
      response = await [loadFolder(folder_id)];
    }
    fetchData();
  }, []);
  return (
    <div className="news news-edito">
      <div className="news-header">
        <div className="news-header-wrapper">
          <div className="news-nav">
            <NavLink
              className="btn btn-secondary rounded btn-back"
              to={`/folder/${edito && edito.folder_id}/details`}
            >
              <i className="far fa-long-arrow-left" />
            </NavLink>
            {isUser(account) && (
              <button
                className="btn btn-secondary rounded"
                onClick={() => {
                  !favorites.folders_id
                    ? (favorites.folders_id = [Number(folder_id)])
                    : favorites.folders_id.includes(Number(folder_id))
                    ? favorites.folders_id.pop(Number(folder_id))
                    : favorites.folders_id.push(Number(folder_id));
                  return createOrUpdateFavorite(favorites, account.account_id);
                }}
              >
                {favorites && !includes(favorites.folders_id, Number(folder_id)) && (
                  <i className="far fa-heart"></i>
                )}
                {favorites && includes(favorites.folders_id, Number(folder_id)) && (
                  <i className="fas fa-heart"></i>
                )}
              </button>
            )}
          </div>
        </div>
        <div className="news-picture-wrapper">
          <div className="news-picture-mask" />
          <img src={folder && API_URL + '/uploads/' + folder.photoUrl} />
        </div>
      </div>
      <div className="news-body">
        <div className="news-wrapper">
          <div className="post-infos">
            <h1 className="news-title">{edito && edito.title}</h1>
          </div>
          <div className="news-author-wrapper">
            {edito && edito.authorPhoto && !isEmpty(edito.authorPhoto) && (
              <div className="news-author-picture">
                <img src={edito && API_URL + '/uploads/' + edito.authorPhoto} />
              </div>
            )}
            <div className="news-author-infos">
              <div className="news-author-name">{edito && edito.authorName}</div>
              <div className="news-author-function">{edito && edito.authorFonction}</div>
              {edito && edito.creditPhoto && (
                <div className="copyright">© {edito && edito.creditPhoto}</div>
              )}
            </div>
          </div>
          {edito && (
            <div
              className="news-content"
              dangerouslySetInnerHTML={{
                __html: edito.content
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state, { edito_id, folder_id }) => ({
    account: state.auth.user,
    edito: state.data.entities.editos[edito_id],
    folder_id: folder_id,
    favorites: state.auth.user && state.data.entities.favorites[state.auth.user.account_id],
    folder: state.data.entities.folders[folder_id]
  }),
  {
    loadEdito,
    loadFolder,
    createOrUpdateFavorite
  }
)(EditoDetailView);
