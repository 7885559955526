import moment from 'moment';

function TimeRangeCalculator(dateDebut, dateFin, daysBefore, daysAfter) {
  return (
    dateDebut <=
      moment()
        .add(daysBefore, 'days')
        .format('YYYY-MM-DD') &&
    dateFin >=
      moment()
        .subtract(daysAfter, 'days')
        .format('YYYY-MM-DD')
  );
}

export default TimeRangeCalculator;
