import React, { useState, useEffect } from 'react';
import { filter, map, includes, toLower, values, isEmpty, union, find } from 'lodash';

import { connect } from 'react-redux';
import BaseLayout from '../shared/BaseLayout';

import DetailsTags from '../../components/search/DetailsTags';
import SearchDetailsView from '../../components/search/SearchDetailsView';
import Input from '../../components/elements/Input';

import { loadActualities } from '../../store/modules/actualities';
import { loadFormations } from '../../store/modules/formations';
import { loadFolders } from '../../store/modules/folders';
import { loadArticles } from '../../store/modules/articles';
import { loadEditos } from '../../store/modules/editos';
import { loadAccounts } from '../../store/modules/accounts';
import { loadSessions } from '../../store/modules/sessions';

const SearchView = ({
  placeholder,
  tags,
  folders,
  formations,
  sessions,
  actualities,
  articles,
  editos,
  loadFolders,
  loadFormations,
  loadSessions,
  loadActualities,
  loadArticles,
  loadEditos,
  loadAccounts,
  defaultTag
}) => {
  const [filterValue, setFilter] = useState('');
  const [resultSearch, onSearch] = useState([]);

  const [resultSearchTag, onSearchTag] = useState();
  const [tagSelected, onClickTag] = useState({}); // Permet de connaitre si un tag est selectionné ou pas

  // charge les données depuis l'api au cas ou
  useEffect(() => {
    async function fetchData() {
      let response = await loadActualities();
      response = await loadSessions();
      // response = await loadFormations();
      response = await loadFolders();
      response = await loadArticles();
      response = await loadEditos();
      response = await loadAccounts();
    }
    fetchData();
  }, []);

  // quand on tape dans la recherche
  useEffect(() => {
    if (filterValue.length >= 3) {
      return onSearchTag(filter(tags, obj => includes(toLower(obj), toLower(filterValue))));
    }
    return onSearchTag([]);
  }, [filterValue]);

  // au clique sur un tag
  const testTag = tag => {
    onClickTag(tag);
    setFilter('');
    onSearch(
      filter(
        sessions
          .concat(folders)
          .concat(actualities)
          .concat(articles),
        obj => {
          return find(obj.tags, t => {
            return toLower(t) === toLower(tag);
          });
        }
      )
    );
  };

  // si on passe un tag en parametre
  useEffect(() => {
    if (defaultTag) {
      testTag(defaultTag);
    }
  }, [actualities]);

  return (
    <BaseLayout title="Recherche">
      <div className="page-container">
        <div className="box-list-title mt-14">Rechercher</div>
        <div className="search-bar">
          <Input
            id="list-filter-input"
            placeholder={placeholder || 'Rechercher'}
            value={filterValue}
            onChange={setFilter}
            className="searchBar-field"
            onSearch={e =>
              onSearch(
                filter(
                  sessions
                    .concat(folders)
                    .concat(actualities)
                    .concat(articles)
                    .concat(editos),
                  obj => {
                    return (
                      toLower(obj.id_action_de_formation ? obj.intitule : obj.title).indexOf(
                        toLower(e)
                      ) > -1 ||
                      (obj.id_action_de_formation
                        ? obj.summary && toLower(obj.summary).indexOf(toLower(e)) > -1
                        : obj.modules &&
                          obj.modules[0] &&
                          obj.modules[0].points_forts &&
                          toLower(obj.modules[0].points_forts).indexOf(toLower(e)) > -1)
                    );
                  }
                )
              )
            }
          />
          <button
            className="btn btn-primary rounded"
            tabIndex="0"
            onClick={() => {
              return onSearch(
                filter(
                  sessions
                    .concat(folders)
                    .concat(actualities)
                    .concat(articles)
                    .concat(editos),
                  obj => {
                    return (
                      toLower(obj.id_action_de_formation ? obj.intitule : obj.title).indexOf(
                        toLower(filterValue)
                      ) > -1 ||
                      (obj.id_action_de_formation
                        ? obj.summary && toLower(obj.summary).indexOf(toLower(filterValue)) > -1
                        : obj.modules &&
                          obj.modules[0] &&
                          obj.modules[0].points_forts &&
                          toLower(obj.modules[0].points_forts).indexOf(toLower(filterValue)) > -1)
                    );
                  }
                )
              );
            }}
          >
            <i className="fa-solid fa-search"></i>
          </button>
        </div>

        <DetailsTags
          onClickTag={tag => testTag(tag)}
          tagSelected={tagSelected}
          data={resultSearchTag}
          filterValue={filterValue}
        />
        {resultSearch && !isEmpty(resultSearch) ? (
          <div className="search-results-list">
            {resultSearch &&
              map(resultSearch, (obj, index) => (
                <SearchDetailsView obj={obj} tagSelected={tagSelected} key={index} />
              ))}
          </div>
        ) : (
          <div className="search-empty-message">
            Rechercher un terme présent dans un dossier, un article, une formation ou une actualité
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default connect(
  state => ({
    folders: values(state.data.entities.folders),
    sessions: values(state.data.entities.sessions),
    actualities: values(state.data.entities.actualities),
    articles: values(state.data.entities.articles),
    editos: values(state.data.entities.editos),
    accounts: values(state.data.entities.accounts),
    // fusionne les listes des tags en une seule liste tout en supprimant les doublons
    tags: union(
      map(state.data.entities.sessions, 'tags').reduce(
        (o, m) => [...m, ...o].map(val => toLower(val)),
        []
      ),
      map(state.data.entities.folders, 'tags').reduce(
        (o, m) => [...m, ...o].map(val => toLower(val)),
        []
      ),
      map(state.data.entities.actualities, 'tags').reduce(
        (o, m) => [...m, ...o].map(val => toLower(val)),
        []
      ),
      map(state.data.entities.articles, 'tags').reduce(
        (o, m) => [...m, ...o].map(val => toLower(val)),
        []
      )
    )
  }),
  {
    loadActualities,
    loadFormations,
    loadSessions,
    loadFolders,
    loadArticles,
    loadEditos,
    loadAccounts
  }
)(SearchView);
