import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { reduxForm, formValueSelector } from 'redux-form';
import { Field, InputField } from 'react-components-linaia';

import BaseLayout from '../../containers/shared/BaseLayout';

import { validateEmail } from '../../utils/StringValidator';

let AdminEditView = ({ handleSubmit, match: { url }, account }) => {
  return (
    <BaseLayout title="Dossier">
      <div className="admin-header">
        <div className="admin-header-title">
          <NavLink
            className="btn"
            to={{
              pathname: `/admin/administrators`,
              foldersProps: {
                name: 'Back'
              }
            }}
          >
            <i className="far fa-chevron-left"></i>
          </NavLink>

          {account && account.email ? (
            <h1>
              Modification du compte
              {account && account.firstName && (
                <span>
                  {account && account.firstName}
                  &nbsp;
                  {account && account.lastName}
                </span>
              )}
            </h1>
          ) : (
            <h1>Création d&apos;un compte administrateur</h1>
          )}
        </div>
      </div>

      <form className="" onSubmit={handleSubmit} name="AdminEditView">
        <div className="grid">
          <div className="col-3_md-5_lg-4">
            <div className="grid">
              <div className="col-12">
                <div className="box">
                  <div className="grid">
                    <Field name="email" component={InputField} label="Email" className="col-12" />
                    <Field
                      name="firstName"
                      component={InputField}
                      label="Prénom"
                      className="col-12"
                    />
                    <Field name="lastName" component={InputField} label="Nom" className="col-12" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="btn-group right">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={event => (window.location.href = '/admin/administrators')}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseLayout>
  );
};

AdminEditView.propTypes = {
  onSubmit: PropTypes.func.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  }).isRequired,
  accounts: PropTypes.objectOf(PropTypes.shape()).isRequired
};

const selector = formValueSelector('AdminEditView');

AdminEditView = reduxForm({
  form: 'AdminEditView',
  validate: (values = {}) => {
    const errors = {};
    if (values.email && !validateEmail(values.email))
      errors.email = 'Merci de renseigner une adresse mail valide';
    if (!values.email) errors.email = "Merci de renseigner le mail de l'administrateur";
    if (!values.firstName) errors.firstName = "Merci de renseigner le prénom de l'administrateur";
    if (!values.lastName) errors.lastName = "Merci de renseigner le nom de l'administrateur";

    return errors;
  }
})(AdminEditView);

AdminEditView = connect(
  (state, props) => ({
    textContent:
      selector(state, 'content') || (props.initialValues && props.initialValues.content) || '',
    initialValues: state.data.entities.accounts[props.account_id] || { roles: ['ADMIN'] },
    account: state.data.entities.accounts[props.account_id]
  }),
  {}
)(AdminEditView);

export default AdminEditView;
