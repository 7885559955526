import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setConfig } from '../../store/modules/globals';
import { sendSocket } from '../../store/modules/socket';

import classNames from 'classnames';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { IconButton, upperFirst } from 'react-components-linaia';

import logoAric from '../../../assets/images/logo-aric-pwa.svg';
import logoBanque from '../../../assets/images/logo-banque_territoires.png';

const PageHeader = ({ title, className, /* history: { push },*/ menuIsTiny, setConf }) => {
  const [date, setDate] = useState(moment());

  useEffect(() => {
    const dateInterval = setInterval(() => {
      setDate(moment());
    }, 1000);

    return () => clearInterval(dateInterval);
  }, []);

  return (
    <div className={classNames('page-header', className)}>
      <div className="container">
        <div className="page-header-left">
          <a href="/" className="logo-block">
            <img src={logoAric} alt="Logo" className="logo" />
          </a>

          <div className="logo-banner">
            <span>en&nbsp;partenariat&nbsp;avec</span>
            <img
              src={logoBanque}
              width="185"
              alt="Logo Banque des Territoires et Caisse des dépôts "
              className=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  pageHeaderComponent: PropTypes.element,
  className: PropTypes.string,
  menuIsTiny: PropTypes.bool,
  setConf: PropTypes.func.isRequired
};

export default connect(
  state => ({
    menuIsTiny: state.data.config.menuIsTiny
  }),
  { setConf: setConfig, sendSocket }
)(withRouter(PageHeader));
