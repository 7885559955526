import { flow, set } from 'lodash/fp';

import { CALL_API, Schemas } from '../Schemas';

const CREATE_EVALUATION_REQUEST = 'arwa/CREATE_EVALUATION_REQUEST';
const CREATE_EVALUATION_SUCCESS = 'arwa/CREATE_EVALUATION_SUCCESS';
const CREATE_EVALUATION_FAILURE = 'arwa/CREATE_EVALUATION_FAILURE';

const GET_EVALUATIONS_REQUEST = 'arwa/GET_EVALUATIONS_REQUEST';
const GET_EVALUATIONS_SUCCESS = 'arwa/GET_EVALUATIONS_SUCCESS';
const GET_EVALUATIONS_FAILURE = 'arwa/GET_EVALUATIONS_FAILURE';

export const evaluationsActionsHandlers = {
  [GET_EVALUATIONS_SUCCESS]: (state, action) =>
    flow(
      set('entities.evaluations', action.response.entities.evaluations || {}),
      set('loaded.evaluations', true),
      set('loading.evaluations', false)
    )(state),

  [CREATE_EVALUATION_SUCCESS]: (state, action) => {
    return flow(
      set('entities.evaluations', {
        ...state.entities.evaluations,
        ...action.response.entities.evaluations
      }),
      set('loaded.evaluations', true),
      set('loading.evaluations', false)
    )(state);
  }
};

export function createEvaluation(data) {
  return {
    [CALL_API]: {
      types: [CREATE_EVALUATION_REQUEST, CREATE_EVALUATION_SUCCESS, CREATE_EVALUATION_FAILURE],
      method: 'POST',
      endpoint: '/evaluations',
      schema: Schemas.EVALUATION,
      body: data
    }
  };
}

export function getAllEvaluations() {
  return {
    [CALL_API]: {
      types: [GET_EVALUATIONS_REQUEST, GET_EVALUATIONS_SUCCESS, GET_EVALUATIONS_FAILURE],
      method: 'GET',
      endpoint: `/evaluations`,
      schema: Schemas.EVALUATION_ARRAY,
      successMessage: 'Evaluations chargés avec succès'
    }
  };
}

export function getEvaluationsByElu(eluId) {
  return {
    [CALL_API]: {
      types: [GET_EVALUATIONS_REQUEST, GET_EVALUATIONS_SUCCESS, GET_EVALUATIONS_FAILURE],
      method: 'GET',
      endpoint: `/evaluations/${eluId}`,
      schema: Schemas.EVALUATION_ARRAY,
      successMessage: 'Evaluations chargés avec succès'
    }
  };
}
