import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import BaseLayout from '../../containers/shared/BaseLayout';
import { filter, includes, map } from 'lodash';

import FolderListView from '../../components/folders/FolderListView';
import FormationsListView from '../../components/formations/FormationsListView';
import NewsListView from '../../components/actualities/NewsListView';

import { loadFavorite } from '../../store/modules/favorites';

const Favorites = ({ folders, formations, actualities, loadFavorite, account }) => {
  useEffect(() => {
    async function fetchData() {
      // You can await here
      await loadFavorite(account && account.account_id);
      // ...
    }
    fetchData();
  }, [account]);

  return (
    <BaseLayout title="Les favoris">
      <div className="box-list-header">
        <div className="box-list-title">Dossiers</div>
      </div>
      <FolderListView folders={folders} />
      <div className="box-list-header">
        <div className="box-list-title">Formations</div>
      </div>
      <FormationsListView formations={formations} />
      <div className="box-list-header">
        <div className="box-list-title">Actualités</div>
      </div>
      <NewsListView actualities={actualities} />
    </BaseLayout>
  );
};

const mapStateToProps = state => {
  const formationsIdsFavorites =
    state.data.entities.favorites[state.auth.user.account_id].formations_id;

  return {
    account: state.auth.user,
    folders:
      map(
        state.data.entities.favorites &&
          state.data.entities.favorites &&
          state.auth.user &&
          state.data.entities.favorites[state.auth.user.account_id].folders_id,
        folder_id => state.data.entities.folders[folder_id]
      ) || [],

    formations: filter(state.data.entities.sessions, s =>
      includes(formationsIdsFavorites, s.session_id)
    ),
    actualities:
      map(
        state.data.entities.favorites &&
          state.data.entities.favorites &&
          state.auth.user &&
          state.data.entities.favorites[state.auth.user.account_id].actualities_id,
        actuality_id => state.data.entities.actualities[actuality_id]
      ) || []
  };
};

export default connect(mapStateToProps, {
  loadFavorite
})(Favorites);
