import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
moment.locale('fr');
import { connect } from 'react-redux';
import { deleteDocument, loadDocuments } from '../store/modules/documents';

import TableView from '../components/shared/TableView';

import BaseLayout from './shared/BaseLayout';
import ModalFormDocument from '../components/modals/ModalFormDocument';
import ButtonConfirmModal from './shared/ButtonConfirmModal';

const DocumentsContainer = ({ documents, sessions, loadDocuments, deleteDocument }) => {
  useEffect(() => {
    loadDocuments();
  }, []);

  return (
    <BaseLayout>
      <div className="admin-header">
        <div className="admin-header-title">
          <h1>Documents</h1>
        </div>
      </div>

      <TableView
        className="table-wrapper"
        data={documents}
        buttons={() => <ModalFormDocument />}
        sortByDefault={'titre'}
        cols={() => ({
          idSessionWebservice: {
            label: 'Session de formation',
            value: idSessionWebservice => {
              const titre =
                sessions &&
                sessions[idSessionWebservice] &&
                sessions[idSessionWebservice].formation &&
                sessions[idSessionWebservice].formation.titre;
              const date = ` ${moment(sessions[idSessionWebservice].dateDebut, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              )}`;
              return titre + date;
            },
            render: idSessionWebservice => {
              const titre =
                sessions &&
                sessions[idSessionWebservice] &&
                sessions[idSessionWebservice].formation &&
                sessions[idSessionWebservice].formation.titre;
              const date =
                sessions[idSessionWebservice].dateDebut === sessions[idSessionWebservice].dateFin
                  ? ` (le ${moment(sessions[idSessionWebservice].dateDebut, 'YYYY-MM-DD').format(
                      'dddd DD/MM/YYYY'
                    )})`
                  : ` (du ${moment(sessions[idSessionWebservice].dateDebut, 'YYYY-MM-DD').format(
                      'dddd DD/MM/YYYY'
                    )}
                    au ${moment(sessions[idSessionWebservice].dateFin, 'YYYY-MM-DD').format(
                      'dddd DD/MM/YYYY'
                    )})`;
              return titre + date;
            }
          },
          titre: {
            label: 'Fichier'
          }
        })}
        rowButtons={doc => (
          <Fragment>
            {doc && (
              <div className="btn-group">
                <ModalFormDocument doc={doc} editMode />
                <ButtonConfirmModal
                  message="Voulez-vous vraiment supprimer ce DOCUMENT ?"
                  actionDelete={() => deleteDocument(doc.document_id)}
                />
              </div>
            )}
          </Fragment>
        )}
      />
    </BaseLayout>
  );
};

DocumentsContainer.propTypes = {
  deleteDocument: PropTypes.func.isRequired,
  loadDocuments: PropTypes.func.isRequired
};

export default connect(
  state => ({
    sessions: (state.data.entities && state.data.entities.sessions) || {},
    documents: (state.data.entities && state.data.entities.documents) || {}
  }),
  { deleteDocument, loadDocuments }
)(withRouter(DocumentsContainer));
