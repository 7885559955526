import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import CircleLoader from 'react-spinners/CircleLoader';
import { get } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { sendNotif } from '../../store/modules/notifications';

const NotificationEditForm = ({ sendNotif, loading }) => {
  const { addToast } = useToasts();
  const libelleRequired = value =>
    value ? undefined : 'Merci de renseigner le libellé de la notification';
  const urlRequired = value => (value ? undefined : `Merci de renseigner l'url de la notification`);

  const onSubmit = values => {
    Promise.resolve()
      .then(() => {
        return sendNotif(values.libelle, values.url);
      })
      .then(res => {
        const notifications = res.response;
        // ON GERE LE SUCCES D'ENVOI DES NOTIFICATIONS
        !loading &&
          notifications.status === 'success' &&
          addToast(notifications.message, { appearance: 'info', autoDismiss: true });
        // ON GERE L'ECHEC' D'ENVOI DES NOTIFICATIONS
        !loading &&
          notifications.status === 'failed' &&
          addToast(notifications.message, { appearance: 'error', autoDismiss: true });
        // ON GERE LE SUCCES D'ENVOI DES NOTIFICATIONS
        // MAIS ON NOTIFIE QUE L'ABONNEMENT EST EXPIRE
        !loading &&
          notifications.status === 'expired' &&
          addToast(notifications.message, { appearance: 'error', autoDismiss: true });
      })
      .catch(err => {
        !loading && addToast(err, { appearance: 'error', autoDismiss: true });
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="grid">
              <Field name="libelle" component="input" validate={libelleRequired}>
                {({ input, meta }) => {
                  return (
                    <div className="col-12">
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input type="text" {...input} />
                        <label htmlFor="libelle">Libellé</label>
                      </div>
                    </div>
                  );
                }}
              </Field>
              <Field name="url" component="input" validate={urlRequired}>
                {({ input, meta }) => {
                  return (
                    <div className="col-12">
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input type="url" {...input} />
                        <label htmlFor="url">Url</label>
                      </div>
                    </div>
                  );
                }}
              </Field>
            </div>
            <div className="col-12">
              <div className="btn-group right">
                <button className="btn btn-primary" type="submit" disabled={loading}>
                  {loading ? (
                    <CircleLoader size={20} color={'black'} loading={loading} />
                  ) : (
                    <span>Envoyer</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
};

const mapStateToProps = state => ({
  loading: get(state, 'data.loading.notifications'),
  notifications: get(state, 'data.entities.notifications', {})
});

export default connect(mapStateToProps, { sendNotif })(NotificationEditForm);
