import { flow, set } from 'lodash/fp';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_SESSIONS_REQUEST = 'arwa/sessions/FETCH_ALL_SESSIONS_REQUEST';
const FETCH_ALL_SESSIONS_SUCCESS = 'arwa/sessions/FETCH_ALL_SESSIONS_SUCCESS';
const FETCH_ALL_SESSIONS_FAILURE = 'arwa/sessions/FETCH_ALL_SESSIONS_FAILURE';

const UPDATE_SESSION_REQUEST = 'arwa/sessions/UPDATE_SESSION_REQUEST';
const UPDATE_SESSION_SUCCESS = 'arwa/sessions/UPDATE_SESSION_SUCCESS';
const UPDATE_SESSION_FAILURE = 'arwa/sessions/UPDATE_SESSION_FAILURE';

const GET_SESSIONS_USER_REQUEST = 'arwa/sessions/GET_SESSIONS_USER_REQUEST';
const GET_SESSIONS_USER_SUCCESS = 'arwa/sessions/GET_SESSIONS_USER_SUCCESS';
const GET_SESSIONS_USER_FAILURE = 'arwa/sessions/GET_SESSIONS_USER_FAILURE';

const INSCRIPTION_SESSION_REQUEST = 'arwa/sessions/INSCRIPTION_SESSION_REQUEST';
const INSCRIPTION_SESSION_SUCCESS = 'arwa/sessions/INSCRIPTION_SESSION_SUCCESS';
const INSCRIPTION_SESSION_FAILURE = 'arwa/sessions/INSCRIPTION_SESSION_FAILURE';

export const sessionsActionsHandlers = {
  [FETCH_ALL_SESSIONS_REQUEST]: state =>
    flow(set('loaded.sessions', false), set('loading.sessions', true))(state),
  [FETCH_ALL_SESSIONS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.sessions', action.response.entities.sessions || {}),
      set('entitiesSort.sessions', action.response.entitiesSort.sessions || {}),
      set('loaded.sessions', true),
      set('loading.sessions', false)
    )(state);
  },
  [GET_SESSIONS_USER_SUCCESS]: (state, action) => {
    return flow(
      set('entities.sessions', action.response.entities.sessions || {}),
      set('entitiesSort.sessions', action.response.entitiesSort.sessions || {}),
      set('loaded.sessions', true),
      set('loading.sessions', false)
    )(state);
  },
  [FETCH_ALL_SESSIONS_FAILURE]: state =>
    flow(set('loaded.sessions', false), set('loading.sessions', false))(state),

  [UPDATE_SESSION_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.sessions.${action.session.id_action_de_formation}`, {
        ...action.session,
        ...action.response
      }),
      set('loaded.sessions', true),
      set('loading.sessions', false)
    )(state);
  }
};

export function getSessionsUser(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [GET_SESSIONS_USER_REQUEST, GET_SESSIONS_USER_SUCCESS, GET_SESSIONS_USER_FAILURE],
      method: 'GET',
      endpoint: `/sessions/mySessions/${userId}`,
      schema: Schemas.SESSION_ARRAY,
      successMessage: 'Sessions chargées avec succès'
    }
  };
}
export function loadSessions() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_SESSIONS_REQUEST, FETCH_ALL_SESSIONS_SUCCESS, FETCH_ALL_SESSIONS_FAILURE],
      method: 'GET',
      endpoint: '/sessions',
      schema: Schemas.SESSION_ARRAY,
      successMessage: 'Sessions chargées avec succès'
    }
  };
}

export function inscriptionSession(session) {
  return {
    [CALL_API]: {
      types: [
        INSCRIPTION_SESSION_REQUEST,
        INSCRIPTION_SESSION_SUCCESS,
        INSCRIPTION_SESSION_FAILURE
      ],
      method: 'POST',
      endpoint: `/sessions/inscription`,
      body: session,
      successMessage: 'Inscription enregistrée avec succès'
    }
  };
}

export function updateSession(session) {
  return {
    session,
    [CALL_API]: {
      types: [UPDATE_SESSION_REQUEST, UPDATE_SESSION_SUCCESS, UPDATE_SESSION_FAILURE],
      method: 'PUT',
      endpoint: `/sessions/update/${session.id_action_de_formation}`,
      schema: Schemas.SESSION,
      body: session,
      successMessage: 'Votre session est enregistrée avec succès'
    }
  };
}
