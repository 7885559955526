/*****************************************************************************
 * Properties
 *****************************************************************************/

/*
 * ACCOUNTS_ROLES
 */
export const ACCOUNTS_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  ELU: 'ELU',
  FORMATEUR: 'FORMATEUR',
  REPORTER: 'REPORTER'
};

export const THEMES_FORMATIONS = {
  VIE_MUN:
    'La vie municipale (Etre élu(e) local(e), prise de fonction du maire et des adjoints, comment organiser la nouvelle équipe, animer une équipe de projet …)',
  URBANISME:
    "L'urbanisme (Initiation, l'adjoint aux travaux, ,le logement, les marchés publics, la voirie, aménager le bourg)",
  EFFICACITE: "L'efficacité personnelle (Conduire une réunion, prendre la parole en public…)",
  SOCIAL:
    "Le social (Le CCAS, les projets d'action sociale, l'action sociale intercommunale, les politiques enfance-jeunesse, les politiques gérontologiques …)",
  FIN_LOC: 'Les finances locales (Initiation, prévisions des investissements et leur financement…)',
  PJT_COM:
    'Réussir son projet communal (Relation avec les associations, réussir la concertation, intégrer le développement durable dans le projet communal…)',
  COMM_MUNI:
    'La communication municipale (Elaborer son plan de communication, communiquer sur un projet, les journaux municipaux…)'
};
export const TABLEAU_CORRES = {
  formations: {
    Visibilité: 'visibility',
    Date: 'dateDebut',
    Titre: 'titre',
    Ville: 'city',
    Durée: 'duree',
    Tags: 'tags'
  },
  actualities: {
    Visibilité: 'visibility',
    Date: 'date',
    Titre: 'title',
    Tags: 'tags'
  },
  folders: {
    Visibilité: 'visibility',
    Date: 'dateDebut',
    DateFin: 'dateFin',
    Titre: 'title',
    Tags: 'tags',
    Réservé: 'restricted'
  },
  articles: {
    Titre: 'title',
    "Nom de l'élu.e": 'authorName'
  },
  accounts: {
    Identifiant: 'email',
    Prénom: 'firstName',
    Nom: 'lastName',
    roles: 'roles'
  }
};
/*
 * POSTS_STATUS
 */
export const POSTS_STATUS = {
  created: 'Créé',
  archived: 'Archivé'
};

/*
 * OPT_COURTESY_TITLE
 */
export const OPT_COURTESY_TITLE = {
  mr: { short: 'M.', long: 'Monsieur' },
  mme: { short: 'Mme', long: 'Madame' }
};
