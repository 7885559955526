import React, { useState } from 'react';
import { map } from 'lodash';
import { TagInput } from 'reactjs-tag-input';

const CustomArea = ({ tagsParent, onChangeFormTags }) => {
  const [tags, setTags] = useState(
    map(tagsParent, (obj, indexObj) => ({
      index: indexObj,
      displayValue: obj
    }))
  );

  const onChangeTags = tagsToChange => {
    setTags(tagsToChange);
    onChangeFormTags(tagsToChange);
  };
  return (
    <TagInput
      placeholder={'Ajouter tag'}
      tags={tags}
      type="specific"
      addTagOnEnterKeyPress={false}
      onTagsChanged={t => {
        return onChangeTags(t);
      }}
    />
  );
};

export default CustomArea;
