import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

export const FETCH_ALL_QUESTIONS_SUCCESS = 'arwa/questions/FETCH_ALL_QUESTIONS_SUCCESS';

const SET_QUESTION_REQUEST = 'arwa/questions/SET_QUESTION_REQUEST';
const SET_QUESTION_SUCCESS = 'arwa/questions/SET_QUESTION_SUCCESS';
const SET_QUESTION_FAILURE = 'arwa/questions/SET_QUESTION_FAILURE';

const DELETE_QUESTION_SUCCESS = 'arwa/questions/DELETE_QUESTION_SUCCESS';

export const questionsActionsHandlers = {
  [SET_QUESTION_REQUEST]: state => {
    return flow(set('loaded.questions', false), set('loading.questions', true))(state);
  },
  [SET_QUESTION_FAILURE]: state => {
    return flow(set('loaded.questions', false), set('loading.questions', false))(state);
  },
  [SET_QUESTION_SUCCESS]: (state, action) =>
    flow(
      set('loaded.questions', true),
      set('loading.questions', false),
      set('entities.questions', {
        ...state.entities.questions,
        ...action.response.entities.questions
      })
    )(state),
  [DELETE_QUESTION_SUCCESS]: (state, action) =>
    flow(set('entities.questions', omit(state.entities.questions, action.id)))(state)
};

export function createQuestion(question) {
  return {
    [CALL_API]: {
      types: [SET_QUESTION_REQUEST, SET_QUESTION_SUCCESS, SET_QUESTION_FAILURE],
      method: 'POST',
      endpoint: '/questions',
      schema: Schemas.QUESTION,
      body: question,
      successMessage: 'Votre question a été envoyée avec succès'
    }
  };
}
